import React, {useEffect, useRef, useState} from 'react'
import { Card, Button, Row, Col, Form } from 'react-bootstrap'
import { FaLock, FaLockOpen, FaRocket, FaRunning } from 'react-icons/fa'
import {useUpdateModelMutation, useTrainModelMutation, useFetchModelsQuery, modelsApi} from '../services/modelsApi'
import { useUpdateDatasetMutation } from '../services/datasetApi'
import {InstanceType, Model, TrainParameters} from '../types/model'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectSelectedModel, setSelectedModel } from '../store/slices/modelSlice'
import HyperparameterSettingsModal from './modals/HyperparametersSettingsModal'
import { useClassDistribution } from '../store/hooks'
import { selectSelectedProject } from '../store/slices/projectSlice'
import {
  selectDatasetSources,
  selectSelectedTrainingSource,
  selectSelectedValidationSource
} from '../store/slices/datasetSlice'
import {useGetAnnotationStatisticsQuery} from '../services/annotationApi'
import SelectedTrainingSourceCard from './SelectedTrainingSourceCard'
import SelectedValidationSourceCard from './SelectedValidationSourceCard'
import DatasetSplitSlider from './DatasetSplitSlider'
import {arraysMatchUnordered} from '../utils'

1
const ModelGenerationView: React.FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const selectedTrainingSources = useAppSelector(selectSelectedTrainingSource)
  const validationSources = useAppSelector(selectSelectedValidationSource)
  const selectedModel = useAppSelector(selectSelectedModel)

  const datasetSources = useAppSelector(selectDatasetSources)

  const [updateModel] = useUpdateModelMutation()
  const [updateDataset] = useUpdateDatasetMutation()
  const [trainModel] = useTrainModelMutation()

  const [showHyperparametersModal, setShowHyperparametersModal] = useState(false)

  const [trainSplit, setTrainSplit] = useState(80) // Default to 80% train, 20% validation
  const [shouldSplit, setShouldSplit] = useState(false)

  const [isLocked, setIsLocked] = useState(true) // Lock state
  const [instanceType, setInstanceType] = useState<'NORMAL' | 'FAST'>('NORMAL') // Instance type selection

  const classDistribution = useClassDistribution(
    project?.id || '',
    project?.datasetid || '',
    false,
    datasetSources
  )

  const { data: annoStats } = useGetAnnotationStatisticsQuery({
    projectId: project?.id || '',
    datasetId: project?.datasetid || '',
    metadata_filter: datasetSources && datasetSources.length > 0
      ? { datasource: datasetSources}
      : undefined
  })

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const {
    data: modelsData,
    refetch: refetchModels,
  } = useFetchModelsQuery({ project_id: project?.id || '' })

  useEffect(() => {
    if (modelsData?.models) {
      pollingIntervalRef.current = setInterval(() => {
        refetchModels()
      }, 5000) // Poll every 5 seconds
    } else if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
        pollingIntervalRef.current = null
      }
    }
  }, [modelsData, refetchModels])


  useEffect(() => {
    setShouldSplit(arraysMatchUnordered(selectedTrainingSources, validationSources))
  }, [selectedTrainingSources, validationSources])

  const handleSplitChange = (value: number) => {
    setTrainSplit(value)
  }

  const handleSplitChangeComplete = async () => {
    if (project?.id && project?.datasetid) {
      const datasetSplit = {
        test: 0,
        train: trainSplit / 100,
        validation: (100 - trainSplit) / 100,
      }

      try {
        await updateDataset({
          datasetId: project.datasetid,
          datasetData: datasetSplit,
        }).unwrap()
      } catch (err) {
        console.error('Failed to update dataset split:', err)
      }
    }
  }

  const handleTrainModel = async () => {
    localStorage.setItem(selectedModel?.id || '', JSON.stringify(datasetSources || ''))
    if (selectedModel?.id) {
      try {
        const modelTrainParams = {
          id: selectedModel.id,
          metadata_filter: selectedTrainingSources && selectedTrainingSources.length > 0 ?  {datasource : selectedTrainingSources} : undefined,
          validation_metadata_filter: validationSources && validationSources.length > 0 ? {datasource : validationSources} : undefined,
        }
        const updatedModel = await trainModel(modelTrainParams).unwrap()
        dispatch(setSelectedModel(updatedModel))
        dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id: 'LIST' }]))
      } catch (err) {
        console.error('Failed to train model:', err)
      }
    }
  }

  const handleOpenHyperparametersModal = () => {
    if (!isLocked) {
      setShowHyperparametersModal(true)
    }
  }

  const handleCloseHyperparametersModal = () => {
    setShowHyperparametersModal(false)
  }

  const handleSaveTrainingParameters = async (newParameters: TrainParameters) => {
    if (selectedModel) {
      try {
        const updatedModel = await updateModel({
          id: selectedModel.id,
          modelData: {
            name: selectedModel.name,
            parameters: {
              ...selectedModel.parameters,
              hyperparameters: newParameters.hyperparameters ?? selectedModel.parameters.hyperparameters,
              resource: newParameters.resource ?? selectedModel.parameters.resource,
              runtime: newParameters.runtime ?? selectedModel.parameters.runtime,
            },
          },
        }).unwrap()
        dispatch(setSelectedModel(updatedModel))
      } catch (err) {
        console.error('Failed to update model name:', err)
      }
    }
  }

  const handleInstanceTypeSelect = async (type: 'NORMAL' | 'FAST') => {
    setInstanceType(type)
    let new_instance_type: InstanceType = 'ml.p3.8xlarge'
    if (type === 'NORMAL') {
      new_instance_type = 'ml.p3.2xlarge'
    } else if (type === 'FAST') {
      new_instance_type = 'ml.p3.8xlarge'
    }

    if (selectedModel) {
      try {
        const updatedParameters: TrainParameters = {
          ...selectedModel.parameters,
          resource: {
            ...selectedModel.parameters.resource,
            instance_type: new_instance_type, // Update the instance type in resource params
          },
        }

        const updatedModel = await updateModel({
          id: selectedModel.id,
          modelData: {
            name: selectedModel.name,
            parameters: updatedParameters,
          },
        }).unwrap()
        dispatch(setSelectedModel(updatedModel))
      } catch (err) {
        console.error('Failed to update model name:', err)
      }
    }
  }

  return (
    <div className="model-generation-content p-4">
      <Row className="mb-4 g-3" style={{ display: 'flex' }}>
        <Col xs={4} md={4}>
          <Card className="h-100 border border-dark rounded card-hover-effect p-2">
            <Card.Header>
              <Card.Title style={{ fontSize: '1em', fontWeight: 'bold', marginBottom: '8px' }}>
              Training Source
              </Card.Title>
            </Card.Header>
            <Row>
              <Col className='d-flex align-items-center justify-content-center'>
                <SelectedTrainingSourceCard />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={8} md={8}>
          <Card className="rounded card-hover-effect no-background">
            <Card.Body>
              <div className="d-flex justify-content-around">
                <div className="circle hover">
                  <div>
                    <div
                      style={{
                        fontSize: '2em',
                        fontWeight: 'bold',
                      }}
                    >
                      {classDistribution.filter((item) => item.name !== 'Unknown').length || 0}
                    </div>
                    <div style={{ fontSize: '0.5em' }}>Classes</div>
                  </div>
                </div>
                <div className="circle">
                  <div>
                    <div style={{ fontSize: '2em', fontWeight: 'bold' }}>{((annoStats?.statistics.total_unannotated_images || 0) + (annoStats?.statistics.total_annotated_images || 0)) || 0}</div>
                    <div style={{ fontSize: '0.5em' }}>Total Images</div>
                  </div>
                </div>
                <div className="circle">
                  <div>
                    <div style={{ fontSize: '2em', fontWeight: 'bold' }}>{annoStats?.statistics.total_annotations || 0}</div>
                    <div style={{ fontSize: '0.5em' }}>Annotations</div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="h-100 border border-dark rounded card-hover-effect p-2">
            <Card.Header>
              <Card.Title style={{ fontSize: '1em', fontWeight: 'bold', marginBottom: '8px' }}>
              Validation Source
              </Card.Title>
            </Card.Header>
            <Row>
              <Col className='d-flex align-items-center justify-content-center'>
                <SelectedValidationSourceCard />
              </Col>
            </Row>
            {shouldSplit && (
              <Row>
                <Card.Title  style={{ paddingTop: '3em',paddingBottom: '1em', fontSize: '1em', fontWeight: 'bold' }}>
                  % of Training Source to use for validation
                </Card.Title>
                <Form.Group style={{ padding: '10px 20px', fontSize: '1.05em' }}>
                  <DatasetSplitSlider
                    value={trainSplit}
                    onChange={handleSplitChange}
                    onChangeComplete={handleSplitChangeComplete}
                    disabled={modelsData?.models?.find((model) => model.id === selectedModel?.id)?.state === 'TRAINING'}
                  />
                </Form.Group>
              </Row>
            )}
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="h-100 border border-dark rounded card-hover-effect" style={{ padding: '20px' }}>
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <Card.Title style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Train Model</Card.Title>
              <Card.Text style={{ fontSize: '1.05em' }} />
              {/*<ButtonDeployModel model={undefined} />*/}
              <Button
                variant="primary"
                onClick={handleTrainModel}
                disabled={selectedModel?.state === 'TRAINING' || !selectedModel?.id}
                className={`start-button ${modelsData?.models?.find((model) => model.id === selectedModel?.id)?.state === 'TRAINING' ? 'training' : ''}`}
              >
                {modelsData?.models?.find((model) => model.id === selectedModel?.id)?.state === 'TRAINING' ? 'Training' : 'Start Training'}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Advanced Settings Card */}
        <Col xs={12} md={4}>
          <Card
            className="h-100 border border-dark rounded card-hover-effect position-relative"
            style={{ padding: '20px' }}
          >
            {isLocked ? (
              <FaLock
                size={28}
                className="position-absolute"
                style={{ top: '10px', right: '10px', cursor: 'pointer', color: 'red' }}
                onClick={() => setIsLocked((prevState) => !prevState)}
              />
            ) : (
              <FaLockOpen
                size={28}
                className="position-absolute"
                style={{ top: '10px', right: '10px', cursor: 'pointer', color: 'green' }}
                onClick={() => setIsLocked((prevState) => !prevState)}
              />
            )}

            <Card.Body>
              <Card.Title className="text-center" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                Advanced Settings
              </Card.Title>
              <Card.Title style={{ fontSize: '.7em', fontWeight: 'bold', marginTop: '10px' }}>
                Training Speed
              </Card.Title>
              <div className="d-flex justify-content-center mb-4">
                <div
                  className={`instance-type-icon ${instanceType === 'NORMAL' ? 'selected-instance' : ''}`}
                  onClick={() => !isLocked && handleInstanceTypeSelect('NORMAL')}
                  style={{
                    cursor: isLocked ? 'not-allowed' : 'pointer', // Disable cursor if locked
                    marginRight: '15px',
                    marginTop: '10px',
                    color: instanceType === 'NORMAL' ? 'green' : 'gray',
                    opacity: isLocked ? 0.5 : 1, // Dim icons if locked
                  }}
                >
                  <FaRunning size={28} title="Normal Instance" />
                  <div style={{ fontSize: '0.8rem', textAlign: 'center' }}>Normal</div>
                </div>

                <div
                  className={`instance-type-icon ${instanceType === 'FAST' ? 'selected-instance' : ''}`}
                  onClick={() => !isLocked && handleInstanceTypeSelect('FAST')}
                  style={{
                    cursor: isLocked ? 'not-allowed' : 'pointer', // Disable cursor if locked
                    marginTop: '10px',
                    color: instanceType === 'FAST' ? 'green' : 'gray',
                    opacity: isLocked ? 0.5 : 1, // Dim icons if locked
                  }}
                >
                  <FaRocket size={28} title="Fast Instance" />
                  <div style={{ fontSize: '0.8rem', textAlign: 'center' }}>Fast</div>
                </div>
              </div>
              <Card.Title style={{ fontSize: '.7em', fontWeight: 'bold', marginTop: '10px' }}>Tuning</Card.Title>
              <Button
                variant="secondary"
                onClick={handleOpenHyperparametersModal}
                className="hyperparameter-button"
                disabled={isLocked}
              >
                Hyperparameter Settings
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <HyperparameterSettingsModal
        show={showHyperparametersModal}
        handleClose={handleCloseHyperparametersModal}
        projectType={project?.annotation_type}
        onSave={handleSaveTrainingParameters}
        initialHyperparameters={selectedModel?.parameters?.hyperparameters}
      />
    </div>
  )
}

export default ModelGenerationView
