import React, { useState } from 'react'
import { Card, Dropdown, DropdownButton } from 'react-bootstrap'
import { Doughnut, Bar } from 'react-chartjs-2'
import { useAppSelector, useClassDistribution } from '../store/hooks'
import 'chart.js/auto'
import { selectSelectedProject } from '../store/slices/projectSlice'
import {selectDatasetSources} from '../store/slices/datasetSlice'
import {TooltipItem} from 'chart.js'
import { Model } from '../types/model'

interface ClassDistributionCardProps {
  title?: string
  showButtons?: boolean
  model?: Model
}

const ClassDistributionCard: React.FC<ClassDistributionCardProps> = ({
  title = 'Class Distribution',
  showButtons = true,
  model,
}) => {
  const project = useAppSelector(selectSelectedProject)
  const datasetSources = useAppSelector(selectDatasetSources)
  const modelSources = Array.from(new Set([...model?.parameters?.train_metadata?.datasource || [], ...model?.parameters?.validation_metadata?.datasource || []]))

  const classDistribution = useClassDistribution(
    project?.id || '',
    project?.datasetid || '',
    false,
    datasetSources ? modelSources: datasetSources ? datasetSources : []
  )
  const [viewMode, setViewMode] = useState<'bar' | 'doughnut' | 'radar'>('bar')

  const maxValue = Math.max(...classDistribution.map((item) => item.value))
  const maxRange = Math.ceil(maxValue / 100) * 100 + 100

  const doughnutData = {
    labels: classDistribution.map((item) => item.name),
    datasets: [
      {
        data: classDistribution.map((item) => item.value),
        backgroundColor: classDistribution.map((item) => item.color),
      },
    ],
  }

  const barData = {
    labels: classDistribution.map((item) => item.name), // Array of class names
    datasets: [
      {
        label: '', // This can remain, but we will hide the legend entirely
        data: classDistribution.map((item) => item.value),
        backgroundColor: classDistribution.map((item) => item.color),
      },
    ],
  }

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        barPercentage: 0.5,
        maxBarThreshold: 100,
      },
      y: {
        beginAtZero: true,
        max: maxRange,
      },
    },
    plugins: {
      legend: {
        display: viewMode !== 'bar', // Disable legend only for the bar chart
      },
      tooltip: {
        callbacks: {
          label: function (context: TooltipItem<never>) {
            const label = context.label
            const value = context.raw
            return `${label}: ${value}`
          },
        },
      },
    },
  }

  return (
    <Card className="class-distribution-card mt-3 shadow-sm">
      <Card.Header className="d-flex justify-content-between align-items-center text-white p-3">
        <h5 className="mb-0 card-title">{title}</h5>
        {showButtons && (
          <DropdownButton
            className="mt-0"
            id="view-mode-dropdown"
            title={`View: ${viewMode.charAt(0).toUpperCase() + viewMode.slice(1)}`}
            size="sm"
            variant="outline-light"
            drop="down" // Ensures the dropdown opens downward
          >
            <Dropdown.Item
              onClick={() => setViewMode('bar')}>
              Bar Chart
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setViewMode('doughnut')}>
              Doughnut Chart
            </Dropdown.Item>
          </DropdownButton>
        )}
      </Card.Header>
      <Card.Body>
        {viewMode === 'bar' && (
          <div className="chart-container">
            <Bar data={barData} options={chartOptions} />
          </div>
        )}
        {viewMode === 'doughnut' && (
          <div className="chart-container">
            <Doughnut data={doughnutData} options={chartOptions} />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default ClassDistributionCard
