// Path: /home/jason/src/AgileView/autovision-ui/src/components/wizards/UploadDatasetNameStep.tsx

import React, {useState} from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useProject } from './NewProjectContext'

const CreateDatasetNameStep: React.FC = () => {
  const {
    setDatasetName,
    datasetName,
    setDatasetSources
  } = useProject()

  const [name, setName] = useState<string | undefined>(datasetName ? datasetName.split(':')[0] : undefined)

  const handleInputChange = (newName: string) => {
    setName(newName)
    if (newName) {  // Use newName parameter instead of name state
      setDatasetName(newName + ':t')
      setDatasetSources([newName + ':t'])
    } else {
      setDatasetName(undefined)
      setDatasetSources([])
    }
  }

  return (
    <Row>
      <Col xs={3} />
      <Col>
        <Form.Group controlId="datasetName">
          <Form.Label>Name your first training dataset</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter dataset name"
            value={name}
            onChange={(e) => handleInputChange(e.target.value)}
            onBlur={(e) => handleInputChange(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col xs={3} />
    </Row>
  )
}

export default CreateDatasetNameStep