// src/components/views/ProjectView.tsx
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Col, Row, Spinner, Button, Form } from 'react-bootstrap'
import { FaBoxes, FaTags, FaImages, FaDatabase, FaPencilAlt, FaExclamationTriangle } from 'react-icons/fa'
import {
  useGetProjectQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  projectsApi,
} from '../../services/projectsApi'
import { useFetchDatasetQuery } from '../../services/datasetApi'
import OverviewCard from '../OverviewCard'

import { useAppDispatch, useAppSelector, useClassDistribution } from '../../store/hooks'
import { selectSelectedProject, setSelectedProject } from '../../store/slices/projectSlice'
import { useNavigate } from 'react-router-dom'
import { useGetContentMetadataQuery } from '../../services/contentApi'
import { selectDatasetSources } from '../../store/slices/datasetSlice'
import ConfirmDialog from '../modals/ConfirmDialog'
import { useGetAnnotationStatisticsQuery } from '../../services/annotationApi'

const ProjectView: React.FC = () => {
  const { projectId } = useParams() // Get project ID from URL
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const navigate = useNavigate()
  const datasetSources = useAppSelector(selectDatasetSources)

  // Use projectId from URL if available, fallback to selected project
  const effectiveProjectId = projectId || project?.id || ''

  // Fetch project data using the effective project ID
  const {
    data: projectData,
    isLoading: isProjectLoading,
    error: projectError,
  } = useGetProjectQuery(effectiveProjectId, { skip: !effectiveProjectId })

  // Update selected project when project data is loaded
  useEffect(() => {
    if (projectData && (!project || project.id !== projectData.id)) {
      dispatch(setSelectedProject(projectData))
    }
  }, [projectData, project, dispatch])

  const classDistribution = useClassDistribution(
    effectiveProjectId,
    projectData?.datasetid || '',
    false,
    datasetSources
  )

  const totalImages = classDistribution.reduce((sum, item) => sum + item.value, 0)
  const classWithoutUnknown = classDistribution.filter((item) => item.name !== 'Unknown')

  const { data: annoStats } = useGetAnnotationStatisticsQuery({
    projectId: effectiveProjectId,
    datasetId: projectData?.datasetid || '',
    metadata_filter: datasetSources && datasetSources.length > 0
      ? { datasource: datasetSources }
      : undefined
  })

  const {
    data: metadata,
  } = useGetContentMetadataQuery({
    project_id: effectiveProjectId,
  })

  const {
    data: datasetData,
    isLoading: isDatasetLoading,
    error: datasetError,
  } = useFetchDatasetQuery(projectData?.datasetid || '', { skip: !projectData?.datasetid })

  const [deleteProject] = useDeleteProjectMutation()
  const [updateProject] = useUpdateProjectMutation()

  const [projectName, setProjectName] = useState(projectData?.name || '')
  const [isEditingName, setIsEditingName] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  useEffect(() => {
    if (projectData?.name) {
      setProjectName(projectData.name)
    }
  }, [metadata, metadata?.metadata?.datasource, projectData])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value)
  }

  const handleNameBlur = async () => {
    if (projectData?.id && projectName !== projectData.name) {
      try {
        await updateProject({ id: projectData.id, data: { name: projectName } }).unwrap()
      } catch (err) {
        console.error('Failed to update project name:', err)
      }
    }
    setIsEditingName(false)
  }

  const handleDeleteProject = async () => {
    try {
      await deleteProject(project?.id || '').unwrap()
      dispatch(projectsApi.util.invalidateTags([{ type: 'Project' }]))
      navigate('/projects')
    } catch (err) {
      console.error('Failed to delete project:', err)
    }
  }


  if (isProjectLoading || isDatasetLoading) {
    return (
      <Card className="project-view">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    )
  }

  if (projectError || datasetError) {
    return (
      <Card className="project-view">
        <Card.Body>
          <p className="text-danger">
            {projectError ? 'Error loading project data.' : ''}
            {datasetError ? 'Error loading dataset data.' : ''}
          </p>
        </Card.Body>
      </Card>
    )
  }


  return (
    <>
      <Row className="project-view-header">
        <Col xs={8}>
          {isEditingName ? (
            <Form.Control
              type="text"
              value={projectName}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              autoFocus
              style={{ maxWidth: '300px' }}
            />
          ) : (
            <div className="position-relative" style={{ display: 'inline-block' }}>
              <h2 className="project-name" style={{ marginRight: '25px', display: 'inline-block' }}>
                {projectData?.name}
              </h2>
              <FaPencilAlt
                style={{
                  cursor: 'pointer',
                  fontSize: '0.8em',
                  position: 'absolute',
                  top: '0',
                  right: '-20px',
                  color: 'blue',
                }}
                onClick={() => setIsEditingName(true)}
              />
            </div>
          )}
          <div className="project-details-metadata mt-1">
            Created: {new Date(projectData?.created_at || '').toLocaleString()}
          </div>
        </Col>
        <Col xs={4} className="d-flex justify-content-end">
          <Button variant="danger" onClick={() => setShowConfirmDelete(true)}>
            Delete Project
          </Button>
          <ConfirmDialog
            show={showConfirmDelete}
            onHide={() => setShowConfirmDelete(false)}
            onConfirm={handleDeleteProject}
            title="Delete Project"
            message="Are you sure you want to delete this project? This action cannot be undone."
            confirmText="Delete"
            cancelText="Cancel"
            variant="danger"
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className="d-flex justify-content-end">
          {/* Classes Card */}
          <OverviewCard
            title={'Classes'}
            value={classWithoutUnknown.length}
            icon={<FaTags size={35} className="text-primary" style={{ marginRight: '5px' }} />}
          />

          {/* Images Card */}
          <OverviewCard
            title={'Images'}
            value={((annoStats?.statistics?.total_unannotated_images || 0) + (annoStats?.statistics?.total_annotated_images || 0))}
            icon={<FaImages size={35} className="text-info" style={{ marginRight: '5px' }} />}
          />

          {/*Datasets Card*/}
          <OverviewCard
            title={'Datasets'}
            value={(metadata?.metadata?.datasource as Array<string>)?.length || 0}
            icon={<FaDatabase size={35} className="text-success" style={{ marginRight: '10px' }} />}
          />

          {/* Annotations Card */}
          <OverviewCard
            title={'Annotations'}
            value={totalImages}
            icon={<FaBoxes size={35} className="text-success" style={{ marginRight: '10px' }} />}
          />
        </Col>
      </Row>

      <Card className="detail-card">
        <Card.Body>
          <Card.Header>
            <h3 className="section-header">Project Details</h3>
          </Card.Header>
          <Row className="ps-5">
            <div className="detail-item">Project ID: {projectData?.id}</div>
          </Row>
          <Row className="ps-5">
            <div className="detail-item">Description: {projectData?.description}</div>
          </Row>
          <Row className="ps-5">
            <div className="detail-item">Annotation Type: {projectData?.annotation_type?.replace('_', ' ')}</div>
          </Row>
          <Row className="ps-5">
            <div className="detail-item">Total Items: {projectData?.count}</div>
          </Row>
          <Row className="ps-5">
            <div className="detail-item">Last
                Updated: {new Date(projectData?.updated_at || '').toLocaleString()}</div>
          </Row>
        </Card.Body>

        {datasetData ? (
          <Card.Body>
            <Card.Header>
              <h3 className="section-header">Dataset Details</h3>
            </Card.Header>
            <Row className="ps-5">
              <div className="detail-item">Dataset ID: {datasetData.dataset.id}</div>
            </Row>
            <Row className="ps-5">
              <div className="detail-item">Version: {datasetData.dataset.version}</div>
            </Row>
          </Card.Body>
        ) : (
          <Card className="no-dataset-card">
            <Card.Body>
              <FaExclamationTriangle size={60} className="warning-icon" />
              <Card.Title>No Dataset Available</Card.Title>
              <Card.Text>This project does not have an associated dataset. Create one to start training
                  models.</Card.Text>
            </Card.Body>
          </Card>
        )}
        {/*</Card.Body>*/}
      </Card>
    </>
  )
}

export default ProjectView
