// Path: /home/jason/src/AgileView/autovision-ui/src/components/wizards/CPNameStep.tsx

import React, {useRef, useState} from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import {useProject} from './NewProjectContext'
const CPNameStep: React.FC = () => {
  const projectNameRef = useRef<HTMLInputElement>(null)
  const [localName, setLocalName] = useState('')

  const {
    setName,
    description,
    setDescription
  } = useProject()

  const handleSetName = (name: string) => {
    setLocalName(name)
    setName(name)
  }

  return (
    <>
      <Row>
        <Col xs={2} />
        <Col>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label className="ms-3">Name</Form.Label>
              <Form.Control
                type="text"
                className="small"
                placeholder="Enter project name"
                value={localName}
                onChange={(e) => {
                  handleSetName(e.target.value)
                }}
                required
                ref={projectNameRef}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter project description"
                value={description} // Use local variable instead of optional chaining
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Col>
        <Col xs={2} />
      </Row>
    </>
  )
}

export default CPNameStep
