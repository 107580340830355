// src/layout/MainContent.tsx

import React, {useEffect, useState} from 'react'
import DatasetView from '../views/DatasetView'
import ModelView from '../views/ModelView'
import ProjectView from '../views/ProjectView'
import { useAppSelector } from '../../store/hooks'
import {selectActiveView, selectSelectedProject} from '../../store/slices/projectSlice'
import InferenceView from '../views/InferenceView'
import ConfirmDialog from '../modals/ConfirmDialog'
import UploadWizard from '../wizards/UploadWizard'
import {useGetContentMetadataQuery} from '../../services/contentApi'

const MainContent: React.FC = () => {
  const activeView = useAppSelector(selectActiveView)
  const project = useAppSelector(selectSelectedProject)

  const [showUpload, setShowUpload] = useState(false)
  const [showConfirmUpload, setShowConfirmUpload] = useState(false)

  const {
    data: metadata,
  } = useGetContentMetadataQuery({
    project_id: project?.id || '',
  }, {skip: !project?.id})

  useEffect(() => {
    if (metadata !== undefined && !metadata.metadata?.datasource) {
      setShowConfirmUpload(true)
    }
  }, [metadata, metadata?.metadata?.datasource])

  switch (activeView) {
    case 'project':
      return (
        <div style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <ProjectView />

          <ConfirmDialog
            show={showConfirmUpload}
            onHide={() => setShowConfirmUpload(false)}
            onConfirm={() => setShowUpload(true )}
            title="Need data"
            message="You need to add a dataset to do anything useful."
            confirmText="Add Data"
            cancelText="Cancel"
            variant="primary"
          />
          <UploadWizard show={showUpload} onHide={() => setShowUpload(false)} singleDataset={true} />

        </div>
      )
    case 'dataset':
      return (
        <div className="dataset-component" style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <DatasetView />

          <ConfirmDialog
            show={showConfirmUpload}
            onHide={() => setShowConfirmUpload(false)}
            onConfirm={() => setShowUpload(true )}
            title="Need data"
            message="You need to add a dataset to do anything useful."
            confirmText="Add Data"
            cancelText="Cancel"
            variant="primary"
          />
          <UploadWizard show={showUpload} onHide={() => setShowUpload(false)} singleDataset={true} />

        </div>
      )
    case 'models':
      return (
        <div className="model-view-container" style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <ModelView />

          <ConfirmDialog
            show={showConfirmUpload}
            onHide={() => setShowConfirmUpload(false)}
            onConfirm={() => setShowUpload(true )}
            title="Need data"
            message="You need to add a dataset to do anything useful."
            confirmText="Add Data"
            cancelText="Cancel"
            variant="primary"
          />
          <UploadWizard show={showUpload} onHide={() => setShowUpload(false)} singleDataset={true} />

        </div>
      )
    case 'inference':
      return (
        <div className="model-view-container" style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <InferenceView />

          <ConfirmDialog
            show={showConfirmUpload}
            onHide={() => setShowConfirmUpload(false)}
            onConfirm={() => setShowUpload(true )}
            title="Need data"
            message="You need to add a dataset to do anything useful."
            confirmText="Add Data"
            cancelText="Cancel"
            variant="primary"
          />
          <UploadWizard show={showUpload} onHide={() => setShowUpload(false)} singleDataset={true} />

        </div>
      )
    default:
      return null
  }
}

export default MainContent
