import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { ErrorMessage, Field, Form as FormikForm, Formik } from 'formik'
import * as Yup from 'yup'
import logoIconBig from '../assets/images/logo-transparent-blue-big.png'
import iconEye from '../assets/icons/icon-eye.svg'
import iconEyeSlash from '../assets/icons/icon-eye-slash.svg'
import { LoginPayload } from '../types/auth'
import { setCredentials } from '../store/slices/authSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useNavigate } from 'react-router-dom'
import { selectIsAuthenticated } from '../store/slices/authSlice'
import { useLoginMutation } from '../services/authApi'
import { LoginResponse } from '../types/responses'
// Assuming you have a selector for login status

// Validation schema
const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
})

const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [login] = useLoginMutation()

  const handleSubmit = async (credentials: LoginPayload) => {
    try {
      const userData: LoginResponse = await login(credentials).unwrap()
      dispatch(setCredentials(userData))
    } catch (err) {
      // Handle error
    }
  }

  const isAuthenticated = useAppSelector(selectIsAuthenticated) // Use selector to get login status from the Redux store

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/projects')
    }
  }, [isAuthenticated, navigate])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  //
  // const handleSubmit = async (userPass: LoginPayload) => {
  //   try {
  //     await dispatch(login(userPass)).unwrap()
  //     navigate('/projects')
  //   } catch (err) {
  //     console.error('Failed to log in:', err)
  //   }
  // }

  return (
    <Container fluid className="login-container">
      <Row className="login-row">
        <Col sm={6} className="logo-col">
          <img src={logoIconBig} alt="AutoVision Logo" className="logo-image" />
        </Col>
        <Col sm={6} className="form-col">
          <div className="login-form-container">
            <h1>Sign In</h1>
            <p>Enter your credentials to sign in and proceed further.</p>
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ touched, errors }) => (
                <FormikForm>
                  <Form.Group controlId="username">
                    <Field
                      name="username"
                      type="text"
                      placeholder="Username"
                      className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`}
                    />
                    <ErrorMessage component="div" name="username" className="invalid-feedback" />
                  </Form.Group>

                  <Form.Group controlId="password" className="password-field">
                    <div className="password-input-container">
                      <Field
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className={`form-control password-input ${
                          touched.password && errors.password ? 'is-invalid' : ''
                        }`}
                      />
                      <img
                        src={showPassword ? iconEyeSlash : iconEye}
                        alt="Toggle Password Visibility"
                        className="toggle-password-icon"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <ErrorMessage component="div" name="password" className="invalid-feedback" />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="sign-in-btn">
                    Sign In
                  </Button>
                  <p className="terms-text">
                    By signing in, you agree to our <a href="/termsConditions">Terms of Use</a> &{' '}
                    <a href="/privacyPolicy">Privacy Policy</a>
                  </p>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
