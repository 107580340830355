
import React, { useCallback, useState } from 'react'
import { Badge, Card, Col, Dropdown, Row } from 'react-bootstrap'
import { FaEllipsisV } from 'react-icons/fa'
import { Project } from '../types/project'
import { useNavigate } from 'react-router-dom'
import {
  useFetchAnnotatedContentQuery, useGetContentImageQuery, useGetContentMetadataQuery,
} from '../services/contentApi'

import { projectsApi, useDeleteProjectMutation } from '../services/projectsApi'
import { useFetchModelsQuery } from '../services/modelsApi'
import { Model } from '../types/model'
import { toTitleCase } from '../utils'
import { useAppDispatch, useAppSelector, useClassDistribution } from '../store/hooks'
import { setSelectedProject } from '../store/slices/projectSlice'
import {
  selectDatasetSources,
  setDatasetSources,
  setTrainingSource,
  setValidationSource
} from '../store/slices/datasetSlice'
import ConfirmDialog from './modals/ConfirmDialog'
import {setSelectedModel} from '../store/slices/modelSlice'

interface ProjectCardProps {
  project: Project
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const datasetSources = useAppSelector(selectDatasetSources)

  const [deleteProject] = useDeleteProjectMutation()
  const { data: modelsData } = useFetchModelsQuery({ project_id: project?.id || '' })

  const [showConfirm, setShowConfirm] = useState(false)

  const classDistribution = useClassDistribution(
    project?.id || '',
    project?.datasetid || '',
    true,
    datasetSources
  )

  const totalAnnotations = classDistribution.reduce((sum, item) => sum + item.value, 0)

  const { data: contentResp } = useFetchAnnotatedContentQuery({
    project_id: project?.id || '',
    limit: 1,
    page: 1,
    sort_key: 'created_at',
    sort_val: -1,
    metadata_filter: undefined
  })

  const { data: imageUrl } = useGetContentImageQuery(
    {
      ContentID: contentResp?.content[0]?.id || '',
      project_id: project?.id || '',
      dataset_id: project?.datasetid || '',
    },
    { skip: !contentResp },
  )

  const { data: metadata } = useGetContentMetadataQuery({
    project_id: project.id,
  })

  const countTrainedAndDeployedModels = (models: Model[]): { trained: number; deployed: number } => {
    return models.reduce(
      (acc, model) => {
        if (model.deployment?.status === 'IN_SERVICE') {
          acc.deployed++
        } else if (model.state === 'TRAINED') {
          acc.trained++
        }
        return acc
      },
      { trained: 0, deployed: 0 },
    )
  }

  const modelCounts = modelsData?.models
    ? countTrainedAndDeployedModels(modelsData.models)
    : { trained: 0, deployed: 0 }

  const handleCardClick = () => {
    dispatch(setTrainingSource([]))
    dispatch(setValidationSource([]))
    dispatch(setDatasetSources([]))
    dispatch(setSelectedProject(project))
    dispatch(setSelectedModel(modelsData?.models?.[0]))
    navigate(`/ProjectDetails/${project.id}`)
  }

  const handleDelete = useCallback(async () => {
    try {
      await deleteProject(project.id).unwrap()
      setTimeout(() => {
        dispatch(projectsApi.util.invalidateTags([{ type: 'Project', id: 'LIST' }]))
      }, 300)
    } catch (error) {
      console.error('Failed to delete project:', error)
      // Optionally, you can show an error message to the user here
    }
  }, [deleteProject, dispatch, project.id])

  return (
    <>
      <Card
        className="project-card"
        style={{ width: '450px', margin: '10px', position: 'relative' }}
        onClick={handleCardClick}
      >
        <Card.Header style={{ position: 'relative', padding: 0 }}>
          <Card.Img variant="top" src={imageUrl || '/fa18.jpeg'} style={{ height: '200px', borderRadius: '8px', objectFit: 'cover' }} />
          <Badge
            className="project-type-overlay"
            bg={project.annotation_type === 'classification' ? 'primary' : 'success'}
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '10px',
              padding: '5px 10px',
              fontSize: '12px',
              borderRadius: '8px',
              width: '25%',
            }}
          >
            {toTitleCase(project.annotation_type)}
          </Badge>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col xs={10}>
              <Card.Title style={{ fontSize: '24px', marginBottom: '20px', textOverflow: 'ellipsis' }}>
                {project.name}
              </Card.Title>
            </Col>
            <Col xs={2} className="text-end">
              <Dropdown onClick={(e) => e.stopPropagation()} className="card-menu">
                <Dropdown.Toggle as="div" style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                  <FaEllipsisV size={24} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" style={{ zIndex: 20 }}>
                  <Dropdown.Item className="dropdown-item" href="#/action-1">
                  Edit
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item" onClick={() => setShowConfirm(true)}>
                  Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Card.Text style={{ minHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          Description: {project.description}
          </Card.Text>

          <Row className="d-flex align-items-center project-stats">
            <Col>
              <Card.Subtitle>Models</Card.Subtitle>
              <Card.Text>{modelsData?.models?.length || 0}</Card.Text>
            </Col>
            <Col>
              <Card.Subtitle>Trained</Card.Subtitle>
              <Card.Text>{modelCounts.trained}</Card.Text>
            </Col>
            <Col>
              <Card.Subtitle>Deployed</Card.Subtitle>
              <Card.Text>{modelCounts.deployed}</Card.Text>
            </Col>
          </Row>

          <Row className="d-flex align-items-center project-stats pb-0">
            <Col>
              <Card.Subtitle>Images</Card.Subtitle>
              <Card.Text>{project.count || 0}</Card.Text>
            </Col>
            <Col>
              <Card.Subtitle>Classes</Card.Subtitle>
              <Card.Text>{classDistribution.filter((item) => item.name !== 'Unknown').length || 0}</Card.Text>
            </Col>
            <Col>
              <Card.Subtitle>Datasets</Card.Subtitle>
              <Card.Text>{metadata?.metadata?.datasource?.length || 0}</Card.Text>
            </Col>
            <Col className="text-center">
              <Card.Subtitle>Annotations</Card.Subtitle>
              <Card.Text>{totalAnnotations || 0}</Card.Text>
            </Col>
          </Row>

        </Card.Body>
      </Card>

      <ConfirmDialog
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={handleDelete}
        title="Delete Project"
        message="Are you sure you want to delete this project? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        variant="danger"
      />
    </>
  )
}

export default ProjectCard

