// src/components/layout/TopBar.tsx

import React from 'react'
import { Badge, Col, Nav, Row } from 'react-bootstrap'
import { CloudUpload, PencilSquare, Database, Diagram3, GearWideConnected } from 'react-bootstrap-icons'
import { Project } from '../../types/project'
import { toTitleCase } from '../../utils'
import {useAppSelector} from '../../store/hooks'
import {ActiveView, selectActiveView} from '../../store/slices/projectSlice'

interface TopBarProps {
  onViewChange: (tab: ActiveView) => void
  project: Project
}

const TopBar: React.FC<TopBarProps> = ({ onViewChange, project }) => {
  const activeView = useAppSelector(selectActiveView)
  return (
    <>
      <Row>
        <Col className="d-flex justify-content-start align-items-center" xs={3}>
          <div className="pe-4">
            <h2>{project.name}</h2>
          </div>
          <Badge
            className="project-type-overlay px-2"
            bg={project.annotation_type === 'classification' ? 'primary' : 'success'}
          >
            {toTitleCase(project.annotation_type.replace('_', ' '))}
          </Badge>
        </Col>
        {/*</Row>*/}
        <Col>
          <Nav className="top-bar" variant="pills">
            <Nav.Item>
              <Nav.Link
                active={activeView === 'project'}
                onClick={() => onViewChange('project')}
                className={activeView === 'project' ? 'active' : ''}
              >
                Project
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                active={activeView === 'dataset'}
                onClick={() => onViewChange('dataset')}
                className={activeView === 'dataset' ? 'active' : ''}
              >
                <Database /> Datasets
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                active={activeView === 'models'}
                onClick={() => onViewChange('models')}
                className={activeView === 'models' ? 'active' : ''}
              >
                <Diagram3 /> Models
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                active={activeView === 'inference'}
                onClick={() => onViewChange('inference')}
                className={activeView === 'inference' ? 'active' : ''}
              >
                <GearWideConnected /> Inference
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </>
  )
}

export default TopBar
