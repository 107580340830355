// src/components/views/AnnotatedTab.tsx

import React, { useEffect, useMemo, useState } from 'react'
import {Badge, Col, Row, Tab, Tabs} from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { contentApi, useFetchAnnotatedContentQuery } from '../../../services/contentApi'
import {
  useGetAnnotationStatisticsQuery,
} from '../../../services/annotationApi'
import {
  clearAllBoundingBoxes,
  selectAnnotatedPage,
  selectUnannotatedPage,
  setAnnotatedPage,
  setUnannotatedPage,
} from '../../../store/slices/annotationSlice'

import {
  clearSelectedModelTab,
  selectActiveTab,
  selectFilterTags,
  selectSelectedProject,
  setActiveTab, setSelectedModelTab
} from '../../../store/slices/projectSlice'



import { selectDatasetSources } from '../../../store/slices/datasetSlice'
import ImageGalleryHeader from './ImageGalleryHeader'
import AnnotationsGalleryView from '../AnnotationsGalleryView'

interface AnnotatedTabsProps {
  itemsPerPage: number
  selectable?: boolean
  allowAnnotate?: boolean
}

const AnnotationsSubView: React.FC<AnnotatedTabsProps> = ({ itemsPerPage = 12 }) => {
  const project = useAppSelector(selectSelectedProject)
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectActiveTab)
  const selectedFilterTags = useAppSelector(selectFilterTags)
  const annotatedPage = useAppSelector(selectAnnotatedPage)
  const unannotatedPage = useAppSelector(selectUnannotatedPage)

  const [cardSize, setCardSize] = useState(295)
  const datasetSources = useAppSelector(selectDatasetSources)
  const [queryDatasets, setQueryDatasets] = useState<string[] | undefined>(undefined)
  
  
  useEffect(() => {
    if (datasetSources && datasetSources.length > 0) {
      setQueryDatasets(datasetSources)
    } else {
      setQueryDatasets(undefined)
    }
    dispatch(
      contentApi.util.invalidateTags([
        { type: 'Content', id: 'LIST' },
        { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
        { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
      ]),
    )
  }, [dispatch, datasetSources])

  const annotatedContentArgs = useMemo(() => ({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
    limit: itemsPerPage,
    page: annotatedPage - 1,
    metadata_filter: { datasource: queryDatasets },
    sort_key: 'updated_at',
    sort_val: -1
  }), [project?.id, project?.datasetid, itemsPerPage, annotatedPage, queryDatasets])

  const unannotatedContentArgs = useMemo(() => ({
    project_id: project?.id || '',
    dataset_id: undefined,
    limit: itemsPerPage,
    page: unannotatedPage - 1,
    metadata_filter: { datasource: queryDatasets },
    sort_key: 'updated_at',
    sort_val: -1
  }), [project?.id, itemsPerPage, unannotatedPage, queryDatasets])

  const annotatedContentQuery = useMemo(() => ({
    skip: activeTab !== 'annotated',
  }), [activeTab])

  const unannotatedContentQuery = useMemo(() => ({
    skip: activeTab !== 'unannotated',
  }), [activeTab])

  const { data: annotatedContent, isLoading: isAnnotatedLoading } = useFetchAnnotatedContentQuery(annotatedContentArgs, annotatedContentQuery)

  const { data: unannotatedContent, isLoading: isUnannotatedLoading } = useFetchAnnotatedContentQuery(unannotatedContentArgs, unannotatedContentQuery)

  const { data: annoStats } = useGetAnnotationStatisticsQuery({
    projectId: project?.id || '',
    datasetId: project?.datasetid || '',
    metadata_filter: queryDatasets && queryDatasets.length > 0 ? { datasource: queryDatasets} : undefined
  })

  // Memoized Values
  const filteredContent = useMemo(() => {
    const contentToProcess = activeTab === 'annotated' ? annotatedContent?.content || []: unannotatedContent?.content || []

    // const sortedContent = [...contentToProcess].sort((a,b) => a.id.localeCompare(b.id))
    if (selectedFilterTags?.length > 0) {
      return contentToProcess?.filter((content) => {
        const contentTagIds = content.annotation?.[0]?.tagids || []
        return contentTagIds?.some(tagId => selectedFilterTags?.map(t => t.id).includes(tagId))
      })
    }
    return contentToProcess

  }, [activeTab, annotatedContent?.content, unannotatedContent?.content, selectedFilterTags])

  
  // Event Handlers
  const handlePageChange = (pageNumber: number) => {
    if (activeTab === 'annotated') {
      const numAnnotated = annotatedContent?.count || 0
      const newPage = pageNumber >= 0 && pageNumber < Math.trunc(numAnnotated / itemsPerPage) + 1
        ? pageNumber
        : Math.trunc(numAnnotated / itemsPerPage) + 1

      // TODO: fix this when the total count is fixed for annotated
      dispatch(setAnnotatedPage(pageNumber))
      // dispatch(setAnnotatedPage(newPage))
    } else {
      const numUnannotated = unannotatedContent?.count || 0
      const newPage = pageNumber >= 0 && pageNumber < Math.trunc(numUnannotated / itemsPerPage) + 1
        ? pageNumber
        : Math.trunc(numUnannotated / itemsPerPage) + 1
      dispatch(setUnannotatedPage(newPage))
    }
  }

  const handleTabSelect = (tab: string | null) => {
    dispatch(clearAllBoundingBoxes())
    dispatch(setActiveTab(tab as 'unannotated' | 'annotated'))
    if (tab === 'annotated' || tab === 'unannotated' || tab === null) {
      dispatch(clearSelectedModelTab())
    } else {
      dispatch(setSelectedModelTab(tab))
    }
  }
  
  // Render Functions
  const renderTabTitle = (title: string, count: number | null = null) => (
    <>
      {title}
      {count !== null && (
        <Badge bg="primary" className="ms-2">
          {count}
        </Badge>
      )}
    </>
  )

  
  return (
    <div className="image-view-container">
      <>
        <Row>
          <Col xs={4}>
            <div className="annotation-tabs">
              <Tabs activeKey={activeTab} onSelect={handleTabSelect} >

                <Tab
                  eventKey="unannotated"
                  title={renderTabTitle('Unannotated', annoStats?.statistics.total_unannotated_images || 0)}
                  tabClassName="unannotated-tab"
                  mountOnEnter={true}
                  unmountOnExit={true}
                >
                </Tab>

                <Tab
                  eventKey="annotated"
                  title={renderTabTitle('Annotated', annoStats?.statistics.total_annotated_images || 0)}
                  tabClassName="annotated-tab"
                  mountOnEnter={true}
                  unmountOnExit={true}
                >
                </Tab>
              </Tabs>
            </div>
          </Col>

          <Col xs={8}>
            <ImageGalleryHeader
              currentPage={activeTab === 'annotated' ? annotatedPage : unannotatedPage}
              totalImages={annoStats?.statistics.total_annotated_images || 0}
              itemsPerPage={itemsPerPage}
              cardSize={cardSize}
              onPageChange={handlePageChange}
              onCardSizeChange={setCardSize}
            />
          </Col>
        </Row>

        <Row>
          {/*{renderContent(filteredContent)}*/}
          <AnnotationsGalleryView
            content={filteredContent || []}
            isContentLoading={activeTab === 'annotated' ? isAnnotatedLoading : isUnannotatedLoading}
            cardSize={cardSize}
          />
        </Row>
      </>
    </div>
  )
}

export default AnnotationsSubView
