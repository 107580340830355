// DatasetSelector.tsx
import React, { useEffect, useState } from 'react'
import { Table, Form, Button, Row, Col, Alert } from 'react-bootstrap'
import { useGetContentMetadataQuery } from '../../services/contentApi'
import { ContentMetadataResponse } from '../../types/content'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'
import DatasetRow from '../tools/DatasetStatsRow'
import {useProject} from './NewProjectContext'
import {setNewDatasetSource, setDatasetSources as setDatasetSourcesSlice } from '../../store/slices/datasetSlice'

interface DatasetSelectorProps {
  singleSelect?: boolean
  canCreate: boolean
  showAcceptButton?: boolean
}

type DatasetPurpose = 'Training' | 'Validation' | 'All'

const DatasetSelector: React.FC<DatasetSelectorProps> = ({
  singleSelect = false,
  canCreate = true,
}) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)

  const { datasetSources, datasetName, setDatasetSources, setDatasetName } = useProject()

  const [searchTerm, setSearchTerm] = useState('')
  const [allChecked, setAllChecked] = useState(false)
  const [purposeFilter, setPurposeFilter] = useState<DatasetPurpose>('All')
  const [filteredDatasets, setFilteredDatasets] = useState<string[]>([])

  const [showPurposeAlert, setShowPurposeAlert] = useState<boolean>(false)

  const { data: metadata } = useGetContentMetadataQuery({
    project_id: project?.id || '',
  }) as { data: ContentMetadataResponse | undefined }

  useEffect(() => {
    const allDatasets = Array.from(
      datasetName
        ? new Set([...(metadata?.metadata?.datasource || []), datasetName,])
        : new Set([...(metadata?.metadata?.datasource || [])])
    )


    const filteredForName = searchTerm ?
      allDatasets.filter((ds) => {
        return ds.toLowerCase().includes(searchTerm.toLowerCase())
      }) :
      allDatasets

    const filteredForPurpose = purposeFilter === 'All' ?
      filteredForName :
      filteredForName?.filter((source) => {
        return purposeFilter.toLowerCase().startsWith(source.split(':')[1])
      })

    setFilteredDatasets(filteredForPurpose || [])

    // if (selectedDatasetSources.length !== allDatasets?.length) {
    //   setAllChecked(false)
    // } else if (selectedDatasetSources.length === allDatasets?.length) {
    //   setAllChecked(true)
    // }
  }, [metadata?.metadata?.datasource, purposeFilter, searchTerm, datasetName])

  
  const handleCreateNew = () => {
    if (canCreate && searchTerm.trim() && purposeFilter !== 'Validation') {
      const term = searchTerm.trim() + ':t'
      setDatasetSources([term])
      setDatasetName(term)
      dispatch(setDatasetSourcesSlice([term]))
      dispatch(setNewDatasetSource(term))

      setSearchTerm('')
    } else if (canCreate && searchTerm.trim() && purposeFilter === 'Validation') {
      const term = searchTerm.trim() + ':v'
      setDatasetSources([term])
      setDatasetName(searchTerm.trim() + ':v')
      setSearchTerm('')
    } else {
      setShowPurposeAlert(true)
    }
  }

  
  const handleToggleSelection = async (datasetSource: string) => {
    if (!datasetSources.includes(datasetSource)) {
      if (singleSelect) {
        setDatasetSources([datasetSource])
        setDatasetName(datasetSource)
        dispatch(setDatasetSourcesSlice([datasetSource]))
      } else {
        setDatasetSources([...datasetSources, datasetSource])
        setDatasetName(datasetSource)
        dispatch(setDatasetSourcesSlice([...datasetSources, datasetSource]))
      }
    } else if (datasetSources.includes(datasetSource)) {
      if (singleSelect) {
        setDatasetSources([])
        dispatch(setDatasetSourcesSlice([]))
        // handleAcceptSelections([])
      } else {
        setDatasetSources(datasetSources.filter((s) => s !== datasetSource))
        dispatch(setDatasetSourcesSlice(datasetSources.filter((s) => s !== datasetSource)))
      }
    } else {
      setDatasetSources([datasetSource])
      dispatch(setDatasetSourcesSlice([datasetSource]))
    }
    // handleAcceptSelections([datasetSource])
  }

  // const handleAccept = () => {
  //   handleAcceptSelections(datasetSources)
  // }


  const toggleAll = () => {
    const allChecked = metadata?.metadata?.datasource &&
            metadata?.metadata?.datasource.length === datasetSources.length

    if (singleSelect) {
      setDatasetSources([])
    }
    else if (!allChecked) {
      setDatasetSources(metadata?.metadata?.datasource || [])
    } else {
      setDatasetSources([])
    }
  }


  return (
    <>
      <Row className="mb-3">
        <Col xs={8}>
          <Form.Control
            type="text"
            placeholder="Search datasets..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                handleCreateNew()
              }
            }}
            className="bg-dark text-white"
          />
        </Col>
        <Col xs={4}>
          <Form.Select
            value={purposeFilter}
            onChange={(e) => setPurposeFilter(e.target.value as DatasetPurpose)}
            onClick={(e) => e.stopPropagation()}
            className="bg-dark text-white"
          >
            <option value="All">All</option>
            <option value="Training">Training</option>
            <option value="Validation">Validation</option>
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col>
          {filteredDatasets.length > 0 ? (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      disabled={singleSelect}
                      type="checkbox"
                      checked={allChecked}
                      onChange={toggleAll}
                    />
                  </th>
                  <th>Dataset Name</th>
                  <th>Classes</th>
                  <th>Images</th>
                  <th>Annotations</th>
                  <th>Type</th>
                </tr>
              </thead>
              {filteredDatasets.map((dataset) => (
                <tbody key={dataset}>
                  <DatasetRow
                    onClick={() => handleToggleSelection(dataset)}
                    datasetSource={dataset}
                    checked={datasetSources.includes(dataset)}
                  />
                </tbody>
              ))}
            </Table>
          ) : (
            searchTerm.trim() && canCreate && (
              <div className="text-center mt-4">
                <p>No matching datasets found.</p>
                <Button variant="primary"
                  onClick={handleCreateNew}
                >
                  Create &quot;{searchTerm.trim()}&quot;
                </Button>
              </div>
            )
          )}
          {showPurposeAlert && (
            <Alert variant="error" onClose={() => setShowPurposeAlert(false)} dismissible>
              <Alert.Heading>Error!</Alert.Heading>
              <p>Must select Training or Validation when creating a new dataset</p>
            </Alert>
          )}
        </Col>
      </Row>

      {/*{showAcceptButton && (*/}
      {/*  <Row>*/}
      {/*    <Col className="text-end mt-3">*/}
      {/*      <Button onClick={handleAccept}>*/}
      {/*          Accept Selections*/}
      {/*      </Button>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}
    </>
  )
}

export default DatasetSelector// Path: /home/jason/src/AgileView/autovision-ui/src/components/DatasetSelector.tsx