// src/store/slices/predictionSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {Prediction, PredictionMetadata} from '../../types/prediction'
import { RootState } from '../index'
import { Tag } from '../../types/tag'

export interface PredictionState {
  selectedPredictions: Prediction[]
  selectedPredictionTags: Tag[]
  editingPrediction: Prediction | undefined
}

const initialState: PredictionState = {
  selectedPredictions: [],
  selectedPredictionTags: [],
  editingPrediction: undefined,
}

const predictionSlice = createSlice({
  name: 'predictions',
  initialState,

  reducers: {

    setEditingPredictionMetadata: (state, action: PayloadAction<PredictionMetadata[]>) => {
      if (state.editingPrediction) {
        state.editingPrediction = {...state.editingPrediction, predictions: action.payload }
      }
    },

    clearEditingPredictionMetadata: (state) => {
      if (state.editingPrediction) {
        state.editingPrediction = {...state.editingPrediction, predictions: [] }
      }
    },

    addEditingPredictionMetadata: (state, action: PayloadAction<PredictionMetadata>) => {
      if (state.editingPrediction) {
        const newPredictionMetadata = Array.from(new Set([...state.editingPrediction.predictions, action.payload]))
        state.editingPrediction = {...state.editingPrediction, predictions: newPredictionMetadata }
      }
    },

    removeEditingPredictionMetadata: (state, action: PayloadAction<number>) => {
      if (state.editingPrediction) {
        const newPredictionMetadata = state.editingPrediction.predictions.filter((p: PredictionMetadata) => {
          console.log(p)
          p.class_index !== action.payload
        })
        state.editingPrediction = {...state.editingPrediction, predictions: newPredictionMetadata }
      }
    },

    setEditingPrediction: (state, action: PayloadAction<Prediction>) => {
      state.editingPrediction = action.payload
    },

    clearEditingPrediction: (state) => {
      state.editingPrediction = undefined
    },

    setSelectedPredictionTags: (state, action: PayloadAction<Tag[]>) => {
      state.selectedPredictionTags = action.payload
    },

    addSelectedPredictionTag: (state, action: PayloadAction<Tag>) => {
      state.selectedPredictionTags = Array.from(new Set([...state.selectedPredictionTags, action.payload]))
    },

    removeSelectedPredictionTag: (state, action: PayloadAction<Tag>) => {
      state.selectedPredictionTags = [...state.selectedPredictionTags.filter((tag) => tag.id !== action.payload.id)]
    },

    toggleSelectedPredictionTag: (state, action: PayloadAction<Tag>) => {
      if (state.selectedPredictionTags.find((t: Tag) => t.id === action.payload.id)) {
        state.selectedPredictionTags = [...state.selectedPredictionTags.filter((tag) => tag.id !== action.payload.id)]
      } else {
        state.selectedPredictionTags = [...Array.from(new Set([...state.selectedPredictionTags, action.payload]))]
      }
    },

    clearSelectedPredictionTags: (state) => {
      state.selectedPredictionTags = []
    },

    // setCurrentPredictions: (state, action: PayloadAction<Prediction[]>) => {
    //   state.currentPredictions = action.payload.map((prediction: Prediction) => {
    //     const { b64_image: omitted, ...rest } = prediction
    //     return rest
    //   })
    // },

    // clearCurrentPredictions: (state) => {
    //   state.currentPredictions = []
    // },

    setSelectedPredictions: (state, action: PayloadAction<Prediction[]>) => {
      state.selectedPredictions = action.payload
    },

    addSelectedPrediction: (state, action: PayloadAction<Prediction>) => {
      state.selectedPredictions.push(action.payload)
    },

    removeSelectedPrediction: (state, action: PayloadAction<string>) => {
      state.selectedPredictions = state.selectedPredictions.filter((prediction) => prediction.id !== action.payload)
    },

    clearSelectedPredictions: (state) => {
      state.selectedPredictions = []
    },
  },
})

export const {
  addSelectedPrediction,
  removeSelectedPrediction,
  setSelectedPredictions,
  clearSelectedPredictions,
  setSelectedPredictionTags,
  addSelectedPredictionTag,
  removeSelectedPredictionTag,
  clearSelectedPredictionTags,
  toggleSelectedPredictionTag,
  // setCurrentPredictions,
  // clearCurrentPredictions,
  setEditingPrediction,
  clearEditingPrediction,
  setEditingPredictionMetadata,
  clearEditingPredictionMetadata,
  addEditingPredictionMetadata,
  removeEditingPredictionMetadata,
} = predictionSlice.actions

export default predictionSlice.reducer

// export const selectCurrentPredictions = (state: RootState) => state.prediction.currentPredictions
export const selectSelectedPredictions = (state: RootState) => state.prediction.selectedPredictions
export const selectSelectedPredictionTags = (state: RootState) => state.prediction.selectedPredictionTags
export const selectEditingPrediction = (state: RootState) => state.prediction.editingPrediction
