// src/hooks/useAuth.ts
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../store/hooks'
import { logout as logoutAction } from '../store/slices/authSlice'
import { resetState } from '../store/actions/resetState'
import { authApi } from '../services/authApi'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const logout = () => {
    // First dispatch individual slice resets if needed
    dispatch(logoutAction())
    // Reset the entire Redux store
    dispatch(resetState())
    // Clear RTK Query cache
    dispatch(authApi.util.resetApiState())
  }

  const logoutAndNavigate = () => {
    logout()
    navigate('/login')
  }

  return { logout, logoutAndNavigate }
}