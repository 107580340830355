// Path: /home/jason/src/AgileView/autovision-ui/src/components/views/InferenceRealTimeSubView.tsx

import React, { useEffect, useRef} from 'react'
import { Row, Col } from 'react-bootstrap'
import {useAppDispatch, useAppSelector} from '../../../store/hooks'
import {selectSelectedModel, setSelectedModel} from '../../../store/slices/modelSlice'
import {modelsApi, useGetModelQuery} from '../../../services/modelsApi'
import {Model} from '../../../types/model'
import MapComponent from './MapComponent'

const InferenceMapSubView: React.FC = () => {
  const dispatch = useAppDispatch()
  const selectedModel = useAppSelector(selectSelectedModel)

  const { data: modelData, refetch: refetchModel } = useGetModelQuery(selectedModel?.id || '', { skip: !selectedModel })

  useEffect(() => {
    dispatch(setSelectedModel(modelData || undefined))
    dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id: 'LIST' }]))
  }, [dispatch, modelData])
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (modelData) {
      pollingIntervalRef.current = setInterval(() => {
        refetchModel().then((res) => {
          dispatch(setSelectedModel(res.data as Model))
        }).catch((err) => {
          console.log(err)
        })
      }, 5000) // Poll every 5 seconds
    } else if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
        pollingIntervalRef.current = null
      }
    }
  }, [dispatch, modelData, refetchModel])


  // const [isDeployed, setIsDeployed] = useState<boolean>(false)


  // useEffect(() => {
  //   setIsDeployed(modelData?.deployment.status === 'IN_SERVICE')
  // }, [modelData])

  // Helper function to type-check FetchBaseQueryError
  if (!modelData) {
    return <div>No model data available.</div>
  }

  return (
    <div className="model-details-content">
      <div className="model-details-metadata mb-4">
        <div className="model-metrics-card">
          {/* Deployment Button and Class Distribution */}
          <Row className="mb-3">
            <Col>
              <MapComponent />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default InferenceMapSubView
