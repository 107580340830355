import React, {useCallback, useState} from 'react'
import { Button, Card } from 'react-bootstrap'
import { useContentImage } from '../../hooks/useContentImage'
import PillsBBox from '../PillsBBox'
import { Content } from '../../types/content'
import { useAppSelector } from '../../store/hooks'
import { selectSelectedAnnotationIds, selectSelectedProject} from '../../store/slices/projectSlice'
import PillsClassification from '../PillsClassification'
import SuggestiveLabelImageCard from '../annotation/SuggestiveLabelImageCard'

interface ContentImageProps {
  content: Content
  cardSize: number
  onImageClick: (image: Content) => void
  selectable: boolean
}

const ContentImage: React.FC<ContentImageProps> = ({ content, cardSize, onImageClick, selectable = false }) => {
  const project = useAppSelector(selectSelectedProject)

  const selectedAnnotationIds = useAppSelector(selectSelectedAnnotationIds)

  const { base64Image, imageRef, contentData } = useContentImage({
    contentId: content.id,
    projectId: project?.id || '',
    datasetId: project?.datasetid || ''
  })

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleClicked = () => {
    console.log('image clicked: ', content)
  }

  return (
    <div
      style={{ padding: '5px' }}
      ref={imageRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Card
        bg="bg-light"
        text="light"
        style={{ height: `${cardSize}px`, width: `${cardSize}px`, cursor: 'pointer', position: 'relative' }}
      >
        {base64Image ? (
          <SuggestiveLabelImageCard
            // contentId={content.id}
            base64Data={base64Image || ''}
            bounding_boxes={content.annotation?.[0].metadata?.bounding_boxes || []}
            cardWidth={cardSize}
            cardHeight={cardSize}
            contentWidth={contentData?.width}
            contentHeight={contentData?.height}
          />
        ) : (
          <div
            style={{
              height: cardSize,
              width: cardSize,
              backgroundColor: '#2c2c2c',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading...
          </div>
        )}
        {selectable && (
          <input
            type="checkbox"
            className="checkbox-top-right"
            checked={selectedAnnotationIds.includes(content.id)}
            onChange={handleClicked}
          />
        )}
        { project?.annotation_type === 'bounding_box' && content?.annotation?.length && (
          <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
            <PillsBBox bounding_boxes={content.annotation?.[0].metadata?.bounding_boxes || []} />
          </div>
        )}
        { project?.annotation_type === 'classification' && content?.annotation?.length && (
          <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
            <PillsClassification tagIds={content.annotation?.[0].tagids} />
          </div>
        )}

        {isHovered && (
          <div className="hover-overlay">
            <Button
              variant="primary"
              size="sm"
              onClick={() => onImageClick(content)}
              className="edit-button"
              style={{
                borderRadius: `${cardSize / 4}px`,
                width: `${cardSize / 2}px`,
                height: `${cardSize / 2}px`,
                fontSize: `${cardSize / 10}px`,
              }}
            >
                Edit
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default ContentImage
