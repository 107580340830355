import React from 'react'
import { Form } from 'react-bootstrap'

interface DatasetSplitSliderProps {
  value: number
  onChange: (value: number) => void
  onChangeComplete: () => void
  disabled?: boolean
}

const DatasetSplitSlider: React.FC<DatasetSplitSliderProps> = ({ value, onChange, onChangeComplete, disabled=false }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value))
  }

  const handleMouseUp = () => {
    onChangeComplete()
  }

  // Function to handle click on tick marks
  const handleTickClick = (newValue: number) => {
    onChange(100-newValue)
    onChangeComplete() // Trigger change complete immediately
  }

  return (
    <div className="custom-slider-container">

      {/* Tick Marks */}
      <div className="slider-ticks">
        {[10, 20, 30, 50].map((tick) => (
          <div
            key={tick}
            className="slider-tick"
            style={{ right: `${tick}%` }}
            onClick={() => handleTickClick(tick)}
          >
            {tick}%
          </div>
        ))}
      </div>

      <Form.Range
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        min={0}
        max={100}
        step={5}
      />

      <div className="slider-label training" style={{ left: '0%' }}>
        Train {value}%
      </div>
      <div className="slider-label validation" style={{ right: '0%' }}>
        Validate {100 - value}%
      </div>
    </div>
  )
}

export default DatasetSplitSlider
