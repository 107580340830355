import React from 'react'
import { Tag } from '../types/tag'
import { getContrastYIQ } from '../utils'
import { useGetTagsQuery } from '../services/tagApi'
import { useAppSelector } from '../store/hooks'
import { selectSelectedProject } from '../store/slices/projectSlice'

//
interface ClassPillsProps {
  handleTagRemove?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  tagIds?: string[] | undefined
  tagNames?: string[] | undefined
  tagConfidence?: (string | number)[] | undefined
  className?: string // Add this line
}

const PillsClassification: React.FC<ClassPillsProps> = ({ tagIds, tagNames, tagConfidence, className, handleTagRemove }) => {
  const project = useAppSelector(selectSelectedProject)
  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  return (
    <div className={`class-pills ${className || ''}`} style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
      {tagNames &&
        tagNames.map((tagName, index) => {
          const fullTag = tags?.tags?.find((tag) => tag.name === tagName)
          const backgroundColor = `#${fullTag?.color.toString(16).padStart(6, '0').slice(0, 6) || '#ffffff'}`
          const textColor = getContrastYIQ(backgroundColor)

          return (
            <div
              key={index}
              onClick={handleTagRemove}
              style={{
                backgroundColor,
                color: textColor,
                padding: '0.25rem 0.5rem',
                height: '1.2rem',
                borderRadius: '9999px',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {tagConfidence && <span>{tagName + ' - ' + tagConfidence?.[index]}</span>}
              {!tagConfidence && <span>{tagName}</span>}
            </div>
          )
        })}
      {tagIds &&
        tagIds?.map((tagId, index) => {
          const tag = tags?.tags?.find((t: Tag) => t.id === tagId)
          if (!tag) return null

          const backgroundColor = `#${tag.color.toString(16).padStart(6, '0').slice(0, 6)}`
          const textColor = getContrastYIQ(backgroundColor)

          return (
            <div
              key={`${tag.id}-${index}`}
              onClick={handleTagRemove}
              style={{
                backgroundColor,
                color: textColor,
                padding: '0.25rem 0.5rem',
                height: '1.2rem',
                borderRadius: '9999px',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {tagConfidence && <span>{tag.name + ' - ' + tagConfidence?.[index]}</span>}
              {!tagConfidence && <span>{tag.name}</span>}
            </div>
          )
        })}
    </div>
  )
}

export default PillsClassification
