import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import Wizard from './Wizard'
import CPNameStep from './CPNameStep'
import CPTypeStep from './CPTypeStep'
import {ProjectProvider} from './NewProjectContext'
import CPCreateProjectStep from './CPCreateProjectStep'

interface CreateProjectModalProps {
  show: boolean
  onHide: () => void
}

const CPWizard: React.FC<CreateProjectModalProps> = ({ show, onHide }) => {
  const handleCancel = () => {
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} centered size="xl" className="create-project-modal">
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <Modal.Title>Create New Project</Modal.Title>
        <Button variant="danger" onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Header>
      <Modal.Body>
        <ProjectProvider>
          <Wizard
            steps={[{
              name: 'Project Type',
              component: CPTypeStep,
              title: 'Select your project type'
            }, {
              name: 'Name and Description',
              component: CPNameStep,
              title: 'Name and describe your project'
            }, {
              name: 'Create Project',
              component: CPCreateProjectStep,
              title: 'Create your project'
            }
            ]}
            stepProps={{ onHide }}
          />
        </ProjectProvider>
      </Modal.Body>
    </Modal>
  )
}

export default CPWizard
