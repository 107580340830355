import React from 'react'
import { Modal, Button } from 'react-bootstrap'

interface ConfirmDialogProps {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  title: string
  message: string
  confirmText?: string
  cancelText?: string
  variant?: 'primary' | 'danger' | 'warning' | 'success'
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  show,
  onHide,
  onConfirm,
  title,
  message,
  confirmText = 'Continue',
  cancelText,
  variant = 'primary'
}) => {
  const handleConfirm = () => {
    onConfirm()
    onHide()
  }

  return (
    <Modal className="create-project-modal"
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className={`bg-${variant} text-white`}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        {cancelText && (
          <Button variant="secondary" onClick={onHide}>
            {cancelText}
          </Button>
        )}
        {confirmText && (
          <Button variant={variant} onClick={handleConfirm}>
            {confirmText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDialog