// Path: /src/components/tools/DatasetRow.tsx

import React, {useEffect, useState} from 'react'
import {selectSelectedProject} from '../../store/slices/projectSlice'
import {useAppSelector} from '../../store/hooks'
import {useGetAnnotationStatisticsQuery} from '../../services/annotationApi'
import {Form} from 'react-bootstrap'

interface DatasetRowProps {
  datasetSource?: string;
  validationSource?: string;
  onClick: (datasource: string) => void;
  checked: boolean | undefined;
}

const DatasetRow: React.FC<DatasetRowProps> = ({ datasetSource, validationSource, onClick, checked }) => {
  const project = useAppSelector(selectSelectedProject)

  const [datasetType, setDatasetType] = useState<string|undefined>(undefined)


  const { data: annoTrainStats } = useGetAnnotationStatisticsQuery({
    projectId: project?.id || '',
    datasetId: project?.datasetid || '',
    metadata_filter: datasetSource && datasetSource.length > 0 ? { datasource: [datasetSource]} : undefined
  })

  useEffect(() => {
    const purpose = datasetSource?.split(':')[1]
    if (purpose === 't') {
      setDatasetType('Training')
    } else if (purpose === 'v'){
      setDatasetType('Validation')
    }  else {
      setDatasetType('Unkown')
    }
  }, [datasetSource])

  return (
    <>
      {datasetSource && (
        <tr onClick={() => onClick(datasetSource)}>
          <td>
            <Form.Check
              type="checkbox"
              checked={checked}
              onChange={() => onClick(datasetSource)}
            />
          </td>
          <td>{datasetSource?.split(':')[0]}</td>
          <td>
            {Object.keys(annoTrainStats?.statistics?.annotations_per_class || {}).length}
          </td>
          <td>
            {(annoTrainStats?.statistics.total_unannotated_images || 0) + (annoTrainStats?.statistics.total_annotated_images || 0)}
          </td>
          <td>
            {annoTrainStats?.statistics.total_annotations || 0}
          </td>
          <td>
            {datasetType}
          </td>
        </tr>
      )}
    </>
  )
}

export default DatasetRow