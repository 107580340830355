import React, { useState} from 'react'
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import {
  selectDatasetSources,
  setDatasetSources,
} from '../store/slices/datasetSlice'
import { useAppDispatch, useAppSelector, useClassDistribution } from '../store/hooks'
import PillsDataset from './PillsDataset'
import SelectDatasetModal from './modals/SelectDatasetModal'
import {useGetAnnotationStatisticsQuery} from '../services/annotationApi'
import {selectSelectedProject} from '../store/slices/projectSlice'
import {useGetTagsQuery} from '../services/tagApi'

import { FaBoxes, FaTags, FaImages } from 'react-icons/fa'
import { selectSelectedModel } from '../store/slices/modelSlice'

interface SelectedDatasetsCardProps {
  singleSelect?: boolean
}

const SelectedDatasetsCard: React.FC<SelectedDatasetsCardProps> = ({ singleSelect = false }) => {
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectSelectedProject)
  const datasetSources = useAppSelector(selectDatasetSources)
  const selectedModel = useAppSelector(selectSelectedModel)
  const modelSources = Array.from(new Set([...selectedModel?.parameters?.train_metadata?.datasource || [], ...selectedModel?.parameters?.validation_metadata?.datasource || []]))

  const [showModal, setShowModal] = useState(false)

  const {data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const { data: annoStats } = useGetAnnotationStatisticsQuery({
    projectId: project?.id || '',
    datasetId: project?.datasetid || '',
    metadata_filter: datasetSources && datasetSources.length > 0 ? { datasource: datasetSources} : undefined
  })

  const classDistribution = useClassDistribution(
    project?.id || '',
    project?.datasetid || '',
    false,
    datasetSources
  )
  const totalImages = classDistribution.reduce((sum, item) => sum + item.value, 0)


  const handleAcceptSelections = (sources: string[]) => {
    dispatch(setDatasetSources(sources))
    setShowModal(false)
  }

  const handleClose = () => setShowModal(false)

  return (
    <>
      <Card
        className="gallery-tools mb-2"
        style={{ borderRadius: '10px', cursor: 'pointer' }}
        onClick={() => setShowModal(true)}
      >
        <Card.Title className="mb-0">
          Selected Dataset{datasetSources && datasetSources.length > 1 ? 's' : ''}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Annotation Statistics</Card.Subtitle>
        {annoStats && (
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <OverlayTrigger
                placement="top-end"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Tags</Tooltip>}
              >
                <Card.Text>
                  <FaTags size={16} className="text-info" style={{ marginRight: '5px' }} />
                  {tags?.tags?.length || 0}
                </Card.Text>
              </OverlayTrigger>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <OverlayTrigger
                placement="top-end"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Images</Tooltip>}
              >
                <Card.Text>
                  <FaImages size={16} className="text-info" style={{ marginRight: '5px' }} />
                  {annoStats.statistics.total_unannotated_images + annoStats.statistics.total_annotated_images}
                </Card.Text>
              </OverlayTrigger>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <OverlayTrigger
                placement="top-end"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Annotations</Tooltip>}
              >
                <Card.Text>
                  <FaBoxes size={16} className="text-info" style={{ marginRight: '5px' }} />
                  {totalImages}
                </Card.Text>
              </OverlayTrigger>
            </Col>
          </Row>
        )}
        <Card.Body>
          {datasetSources && datasetSources.length > 0 && (
            <PillsDataset
              datasetNames={
                datasetSources && datasetSources.length > 0 ? datasetSources : ['All']
              }
              disabled={true}
              tiny={false}
            />
          )}
          {!datasetSources?.length && (
            <PillsDataset datasetNames={['All']} disabled={true} tiny={false} />
          )}
        </Card.Body>
      </Card>
      <SelectDatasetModal canCreate={true} singleSelect={singleSelect} handleAcceptSelections={handleAcceptSelections} show={showModal} onHide={handleClose} />
    </>
  )
}

export default SelectedDatasetsCard

