import React from 'react'
import { Badge } from 'react-bootstrap'

interface PillsDatasetProps {
  datasetNames: string[]
  onToggleDataset?: (datasetName: string) => void
  className?: string
  tiny?: boolean
  disabled?: boolean
}

const PillsDataset: React.FC<PillsDatasetProps> = ({
  datasetNames,
  onToggleDataset,
  className,
  tiny = false,
  disabled = false,
}) => {
  return (
    <div className={`dataset-pills ${className || ''}`} style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
      {datasetNames && datasetNames?.map((datasetName, index) => (
        <div
          key={`${datasetName}-${index}`}
          onClick={() => (disabled ? {} : onToggleDataset ? onToggleDataset(datasetName) : {})}
          style={{
            backgroundColor: '#800085', // Purple color as requested
            color: 'white', // White text for contrast
            padding: tiny ? '.1rem 1rem' : '0.5rem 1rem',
            marginTop: tiny ? '.1rem' : '.5rem',
            height: tiny ? '1rem' : '1.75rem',
            borderRadius: '9999px',
            fontSize: tiny ? '.6rem' : '1rem',
            fontWeight: tiny ? '' : '',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            position: 'relative', // Add position relative to the pill
          }}
        >
          <span>{datasetName.split(':')?.[0]}</span>
          {datasetName !== 'All' && (
            <>
              {/* Badge for indicating training dataset */}
              <Badge
                bg="success"
                style={{
                  position: 'absolute',
                  top: '-0.3rem',
                  right: '-0.3rem',
                  padding: tiny ? '.15rem .25rem' : '.25rem .5rem',
                  fontSize: tiny ? '.5rem' : '.7rem',
                  borderRadius: '100px',
                }}
              >
                {datasetName.split(':')[1] || 'Unk'}
              </Badge>
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default PillsDataset
