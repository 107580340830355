// src/components/annotation/utils/drawing.ts

import { AnnotationDataBoundingBox } from '../../../types/annotation'
import { Tag } from '../../../types/tag'
import { MousePosition } from '../hooks/useImageAnnotation'
import {getContrastYIQ} from '../../../utils'

const PADDING_FACTOR = 0.3 // 10% padding around the bbox when zooming

export const calculateZoomForBBox = (
  bbox: AnnotationDataBoundingBox,
  canvas: HTMLCanvasElement,
): { zoomLevel: number; panOffset: MousePosition } => {
  // Calculate bbox dimensions
  const bboxWidth = bbox.xmax - bbox.xmin
  const bboxHeight = bbox.ymax - bbox.ymin

  // Calculate zoom levels needed to fit bbox in both dimensions
  const horizontalZoom = canvas.width / (bboxWidth * (1 + 2 * PADDING_FACTOR))
  const verticalZoom = canvas.height / (bboxHeight * (1 + 2 * PADDING_FACTOR))

  // Use the smaller zoom level to ensure the entire bbox is visible
  const zoomLevel = Math.min(horizontalZoom, verticalZoom)

  // Calculate the center of the bbox
  const bboxCenterX = (bbox.xmin + bbox.xmax) / 2
  const bboxCenterY = (bbox.ymin + bbox.ymax) / 2

  // Calculate required pan offset to center the bbox
  const panOffset = {
    x: (canvas.width / 2 - bboxCenterX ),
    y: (canvas.height / 2 - bboxCenterY)
  }

  return { zoomLevel, panOffset }
}


export const drawAnnotations = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  zoomLevel: number,
  panOffset: MousePosition,
  allBoundingBoxes: AnnotationDataBoundingBox[],
  filteredBoundingBoxes: AnnotationDataBoundingBox[],
  showConfidence: boolean,
  tags: Tag[],
  useBBoxes: boolean,
  showBBoxes: boolean,
  newBoundingBox?: AnnotationDataBoundingBox | null,
  selectedBBox?: AnnotationDataBoundingBox | null,
  mousePosition?: MousePosition,
  contrast?: number,
  showHeatmap?: boolean,
  bBoxIndex?: number
) => {
  // Prevent unnecessary redraws during resize
  if (ctx.canvas !== canvas) return

  // If predictionIndex is provided, calculate zoom and pan for that bbox
  if (bBoxIndex !== undefined && filteredBoundingBoxes[bBoxIndex]) {
    console.log('bboxIndex', bBoxIndex)
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height)

  // Apply zoom and pan transformation
  ctx.save()
  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.scale(zoomLevel, zoomLevel)
  ctx.translate(-canvas.width / 2 + panOffset.x, -canvas.height / 2 + panOffset.y)

  // Draw the image
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

  // Apply contrast
  if (contrast && contrast !== 0) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const factor = (259 * (contrast + 255)) / (255 * (259 - contrast))
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      data[i] = factor * (data[i] - 128) + 128 // Red
      data[i + 1] = factor * (data[i + 1] - 128) + 128 // Green
      data[i + 2] = factor * (data[i + 2] - 128) + 128 // Blue
    }
    ctx.putImageData(imageData, 0, 0)
  }

  if (showHeatmap) {
    drawHeatmap(ctx, canvas, allBoundingBoxes)
  }

  if (showBBoxes) {
    // Draw existing bounding boxes
    const boxIndex = filteredBoundingBoxes.findIndex((b) => {
      return b.ymin === selectedBBox?.ymin
          && b.xmin === selectedBBox?.xmin
          && b.ymax === selectedBBox?.ymax
      && b.ymin === selectedBBox?.ymin
    })
    filteredBoundingBoxes.forEach((box, index) => {
      if (!selectedBBox || box.class_index === selectedBBox?.class_index) {

        if (bBoxIndex === undefined || index === bBoxIndex && selectedBBox?.class_index === box.class_index) {
          const selectedTag = tags?.find((tag) => tag.id === box.name)

          const color = `#${selectedTag?.color.toString(16).padStart(6, '0').slice(0, 6) || '#80085'}`
          if (selectedTag) {
            ctx.strokeStyle = color
            ctx.lineWidth = 2 / zoomLevel
            ctx.strokeRect(box.xmin, box.ymin, box.xmax - box.xmin, box.ymax - box.ymin)

            // Highlight selected box
            if (selectedBBox && boxIndex === index) {
              ctx.fillStyle = `#${selectedTag.color.toString(16).padStart(6, '0').slice(0, 6)}44`
              ctx.fillRect(box.xmin, box.ymin, box.xmax - box.xmin, box.ymax - box.ymin)

              // Draw resize handles
              const handleSize = 18 / zoomLevel
              ctx.fillStyle = `#${selectedTag.color.toString(16).padStart(6, '0').slice(0, 6)}`
              ctx.strokeStyle = `#${selectedTag.color.toString(16).padStart(6, '0').slice(0, 6)}`
              ctx.lineWidth = 1 / zoomLevel

              const handles = [
                {x: box.xmin, y: box.ymin},
                {x: box.xmax - handleSize, y: box.ymin},
                {x: box.xmin, y: box.ymax - handleSize},
                {x: box.xmax - handleSize, y: box.ymax - handleSize},
              ]

              handles.forEach((handle) => {
                ctx.beginPath()
                ctx.rect(handle.x, handle.y, handleSize, handleSize) // Draw rectangle
                ctx.fill()
                ctx.stroke()
              })
            }
          }


          if (showConfidence) {
            // Draw confidence percentage
            const confidence = box.confidence || 0
            const confidenceText = `${(confidence * 100).toFixed(1)}%`

            // Setup text style
            ctx.font = `${12 / zoomLevel}px Arial`
            ctx.textBaseline = 'top'

            // Create background for better readability
            const textMetrics = ctx.measureText(confidenceText)
            const padding = 4 / zoomLevel
            const textHeight = 14 / zoomLevel

            ctx.fillStyle = color
            ctx.fillRect(
              box.xmin - 1 / zoomLevel,
              box.ymin - textHeight - padding,
              textMetrics.width + (padding * 2),
              textHeight + padding
            )

            // Draw text
            ctx.fillStyle = getContrastYIQ(color)
            ctx.fillText(
              confidenceText,
              box.xmin + padding / 2,
              box.ymin - textHeight - padding / 2
            )
          }
        }
      }
    })

    // Draw new bounding box if it exists
    if (newBoundingBox) {
      ctx.strokeStyle = 'blue'
      ctx.lineWidth = 2 / zoomLevel
      ctx.strokeRect(
        newBoundingBox.xmin,
        newBoundingBox.ymin,
        newBoundingBox.xmax - newBoundingBox.xmin,
        newBoundingBox.ymax - newBoundingBox.ymin,
      )
    }
  }

  // Draw crosshair
  if (mousePosition && (bBoxIndex === undefined)) {
    ctx.beginPath()
    const xPos = mousePosition.x
    const yPos = mousePosition.y
    ctx.moveTo(xPos, 0)
    ctx.lineTo(xPos, canvas.height)

    ctx.moveTo(0, yPos)
    ctx.lineTo(canvas.width, yPos)

    ctx.strokeStyle = 'rgba(0, 0, 255, 0.75)'
    ctx.lineWidth = 1 / zoomLevel
    ctx.stroke()
  }

  ctx.restore()
}

// src/components/annotation/utils/drawing.ts

function drawHeatmap(
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  boundingBoxes: AnnotationDataBoundingBox[],
) {
  // Create an off-screen canvas for performance
  const heatmapCanvas = document.createElement('canvas')
  heatmapCanvas.width = canvas.width
  heatmapCanvas.height = canvas.height
  const heatmapCtx = heatmapCanvas.getContext('2d')

  if (!heatmapCtx) return

  // Initialize an array to hold overlap counts
  const overlapCounts = new Uint32Array(canvas.width * canvas.height)

  // Accumulate overlap counts
  boundingBoxes.forEach((box) => {
    const xmin = Math.max(0, Math.floor(box.xmin))
    const ymin = Math.max(0, Math.floor(box.ymin))
    const xmax = Math.min(canvas.width - 1, Math.floor(box.xmax))
    const ymax = Math.min(canvas.height - 1, Math.floor(box.ymax))

    for (let y = ymin; y <= ymax; y++) {
      for (let x = xmin; x <= xmax; x++) {
        const index = y * canvas.width + x
        overlapCounts[index]++
      }
    }
  })

  // Find maximum overlap to normalize
  let maxOverlap = 0
  for (let i = 0; i < overlapCounts.length; i++) {
    if (overlapCounts[i] > maxOverlap) {
      maxOverlap = overlapCounts[i]
    }
  }
  if (maxOverlap === 0) return // Avoid division by zero

  // Create ImageData for heatmap
  const imageData = heatmapCtx.createImageData(canvas.width, canvas.height)
  const data = imageData.data

  for (let i = 0; i < overlapCounts.length; i++) {
    const count = overlapCounts[i]
    const intensity = count / maxOverlap // Normalize to [0, 1]

    // Map intensity to color
    const [r, g, b] = intensityToHeatmapColor(intensity)

    const dataIndex = i * 4
    data[dataIndex] = r // Red
    data[dataIndex + 1] = g // Green
    data[dataIndex + 2] = b // Blue
    data[dataIndex + 3] = Math.floor(intensity * 180) // Alpha
  }

  heatmapCtx.putImageData(imageData, 0, 0)

  // Draw the heatmap onto the main canvas
  ctx.drawImage(heatmapCanvas, 0, 0)
}

// src/components/annotation/utils/drawing.ts

function intensityToHeatmapColor(intensity: number): [number, number, number] {
  // Clamp intensity to [0, 1]
  intensity = Math.max(0, Math.min(1, intensity))

  let r = 0,
    g = 0,
    b = 0

  if (intensity <= 0.5) {
    // Green to Yellow
    r = Math.floor(510 * intensity)
    g = 255
    b = 0
  } else {
    // Yellow to Red
    r = 255
    g = Math.floor(255 - 510 * (intensity - 0.5))
    b = 0
  }

  return [r, g, b]
}
