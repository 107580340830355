import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Spinner, Form, Button } from 'react-bootstrap'
import { FaPencilAlt, FaExclamationTriangle } from 'react-icons/fa'
import {
  selectModelIsLoading,
  selectModelError,
  setSelectedModel,
  clearSelectedModel,
  selectSelectedModel,
} from '../../store/slices/modelSlice'
import {
  useGetModelQuery,
  useUpdateModelMutation,
  useDeleteModelMutation,
  modelsApi, useFetchModelsQuery,
} from '../../services/modelsApi'
import ModelGenerationView from '../ModelGenerationView'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import ModelMetricsCard from '../ModelMetricsCard'
import {selectSelectedProject} from '../../store/slices/projectSlice'

const ModelView: React.FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)

  const selectedModel = useAppSelector(selectSelectedModel)
  const isLoading = useAppSelector(selectModelIsLoading)
  const error = useAppSelector(selectModelError)

  const { data: modelData } = useGetModelQuery(selectedModel?.id || '', { skip: !selectedModel?.id })
  const { data: modelsData } = useFetchModelsQuery({ project_id: project?.id || '' })
  const [updateModel] = useUpdateModelMutation()
  const [deleteModel] = useDeleteModelMutation()

  const [isEditingName, setIsEditingName] = useState(false)
  const [modelName, setModelName] = useState(modelData?.name || '')

  const showModelGenerationView = ['UNKNOWN', 'INITIALIZED', 'TRAINING'].includes(modelData?.state || '')

  useEffect(() => {
    if (!selectedModel && modelsData?.models?.length && modelsData.models.length > 0) {
      dispatch(setSelectedModel(modelsData.models[0]))
    }
  }, [dispatch, modelsData?.models, selectedModel])

  useEffect(() => {
    if (modelData?.name) {
      setModelName(modelData.name)
    }
    // dispatch(setSelectedModel(modelData || null))
    dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id: 'LIST' }]))
  }, [dispatch, modelData])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModelName(e.target.value)
  }

  const handleNameBlur = async () => {
    if (modelData?.id && modelName !== modelData.name) {
      try {
        const updatedModel = await updateModel({
          id: modelData.id,
          modelData: { name: modelName },
        }).unwrap()
        dispatch(setSelectedModel(updatedModel))
      } catch (err) {
        console.error('Failed to update model name:', err)
      }
    }
    setIsEditingName(false)
  }

  const handleDeleteModel = async (modelId: string | undefined) => {
    if (modelId) {
      await deleteModel({ id: modelId })
      dispatch(clearSelectedModel())
      dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id: modelId }]))
    }
  }

  if (isLoading) {
    return (
      <Card className="model-view">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    )
  }

  if (error) {
    return (
      <Card className="model-view">
        <Card.Body>
          <p className="text-danger">{error}</p>
        </Card.Body>
      </Card>
    )
  }

  return (
    <>
      {selectedModel ? (
        <>
          <Row className="project-view-header">
            <Col xs={5}>

              {isEditingName ? (
                <Form.Control
                  type="text"
                  value={modelName}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                  autoFocus
                  style={{ maxWidth: '300px' }}
                />
              ) : (
                <div className="position-relative" style={{ display: 'inline-block' }}>
                  <h2 className="model-name" style={{ marginRight: '25px', display: 'inline-block' }}>
                    {selectedModel?.name || 'Unknown Model'}
                  </h2>
                  <FaPencilAlt
                    style={{
                      cursor: 'pointer',
                      fontSize: '0.8em',
                      position: 'absolute',
                      top: '0',
                      right: '-20px',
                      color: 'blue',
                    }}
                    onClick={() => setIsEditingName(true)}
                  />
                </div>
              )}

              <div className="model-details-metadata mt-1">
                Created: {modelData?.created_at ? new Date(selectedModel.created_at).toLocaleString() : 'N/A'}
              </div>
            </Col>
            <Col xs={5}>
              {/*<p className="model-details-metadata mb-4">*/}
              {/*  View and manage your trained model. Monitor its performance and make adjustments as needed.*/}
              {/*</p>*/}
            </Col>
            <Col xs={2}>
              <Button
                disabled={['IN_SERVICE', 'CREATING'].includes(modelData?.deployment?.status || '')}
                onClick={() => handleDeleteModel(modelData?.id || undefined)}
              >
                Delete Model
              </Button>
            </Col>
          </Row>

          <div className="model-view-container">
            <Card className="model-view" style={{ border: 'none' }}>
              <Card.Body>
                {showModelGenerationView && selectedModel ? (
                  <ModelGenerationView />
                ) : selectedModel !== undefined ? (
                  <ModelMetricsCard />
                ) : selectedModel}
              </Card.Body>
            </Card>
          </div>
        </>
      ) : (
        <Card
          className="text-center shadow"
          style={{
            // width: '50%',
            margin: '100px auto',
            padding: '20px',
            border: 'none',
            borderRadius: '10px',
            backgroundColor: '#2a2a2a',
          }}
        >
          <Card.Body>
            <FaExclamationTriangle size={60} color="red" className="mb-4" />
            <Card.Title style={{ fontSize: '24px', fontWeight: 'bold', color: '#ffffff' }}>
              No Model Selected
            </Card.Title>
            <Card.Text style={{ fontSize: '16px', color: '#6c757d' }}>
              Select a model, or you can generate a new model by selecting &quot;New Model&quot; on the left hand side.
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default ModelView
