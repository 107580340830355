import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ChevronLeft, ChevronRight, Check } from 'lucide-react'

export type WizardStep = {
  name: string
  component: React.ComponentType<any>
  title: string
}

interface WizardProps {
  steps: WizardStep[]
  stepProps?: Record<string, any>
}

const Wizard: React.FC<WizardProps> = ({ steps, stepProps }) => {
  const [currentStep, setCurrentStep] = useState(0)

  const goToNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const goToPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  interface ContentWrapperProps {
    disabled?: boolean;
    children: React.ReactNode;
  }

  const ContentWrapper: React.FC<ContentWrapperProps> = ({ disabled = false, children }) => {
    const handleInteraction = (e: React.SyntheticEvent) => {
      if (disabled) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
        {...(disabled ? {
          onClick: handleInteraction,
          onKeyDown: handleInteraction,
          onMouseDown: handleInteraction,
          onPointerDown: handleInteraction,
          onTouchStart: handleInteraction,
        } : {})}
      >
        {children}
        {disabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: 'not-allowed',
              zIndex: 1000,
            }}
          />
        )}
      </div>
    )
  }
  // Render the current component
  const CurrentStepComponent = steps[currentStep].component || undefined

  return (
    <Container className="upload-modal py-4">
      {/* Breadcrumb navigation */}
      <Row className="mb-3">
        <Col>
          <div className="d-flex align-items-center justify-content-between" >
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <div
                  onClick={() => setCurrentStep(index)}
                  className={`d-flex align-items-center ${
                    index <= currentStep ? 'text-white' : 'text-medium'
                  }`}
                >
                  {/* Step circle */}
                  <div
                    className={`
                      d-flex align-items-center justify-content-center
                      rounded-circle
                      border border-2
                      ${index < currentStep
                ? 'bg-success border-success text-white'
                : index === currentStep
                  ? 'border-primary text-primary'
                  : 'border-secondary text-medium'
              }`
                    }
                    style={{ width: '32px', height: '32px' }}
                  >
                    { index >= currentStep
                      ? index + 1
                      : <Check />
                    }
                  </div>

                  {/* Step name */}
                  <span className="ms-2" style={{ fontSize: '$f-14' }}>
                    {step.name}
                  </span>
                </div>

                {/* Connector line */}
                {index < steps.length - 1 && (
                  <div
                    className={`flex-grow-1 mx-3 ${
                      index < currentStep ? 'bg-primary' : 'bg-secondary'
                    }`}
                    style={{ height: '1px' }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </Col>
      </Row>

      {/* Title area */}
      <Row className="mt-4">
        <Col>
          <h2 className="text-center fw-bold">
            {steps[currentStep].title}
          </h2>
        </Col>
      </Row>

      {/* Main content area with navigation arrows */}
      <Row className="g-0" style={{ minHeight: '384px' }}>
        {/* Left arrow */}
        <Col xs="auto" className="d-flex align-items-center pe-4">
          <Button
            variant="link"
            onClick={goToPrevious}
            disabled={currentStep === 0 }
            className="d-flex align-items-center justify-content-center"
            style={{ width: '40px', height: '40px', padding: 0 }}
          >
            <ChevronLeft
              size={40}
            />
          </Button>
        </Col>

        <Col style={{ alignContent:'center', justifyContent:'center', minHeight: '384px' }}>
          <ContentWrapper >
            <CurrentStepComponent {...stepProps} />
          </ContentWrapper>
        </Col>

        {/* Right arrow */}
        <Col xs="auto" className="d-flex align-items-center ps-4">
          <Button
            variant="link"
            onClick={goToNext}
            disabled={currentStep === steps.length - 1 }
            className="d-flex align-items-center justify-content-center"
            style={{ width: '40px', height: '40px', padding: 0 }}
          >
            <ChevronRight size={40} />
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default Wizard