// Path: /home/jason/src/AgileView/autovision-ui/src/components/ButtonDeployModel.tsx

import React, {useEffect, useState} from 'react'
import { Button } from 'react-bootstrap'
import {Model} from '../types/model'
import {modelsApi, useDeleteModelDeploymentMutation, useDeployModelMutation} from '../services/modelsApi'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {selectSelectedModel} from '../store/slices/modelSlice'
import ConfirmDialog from './modals/ConfirmDialog'

interface ButtonDeployModelProps {
  model: Model | undefined
}

const ButtonDeployModel: React.FC<ButtonDeployModelProps> = ({
  model,
}) => {
  const dispatch = useAppDispatch()
  const selectedModel = useAppSelector(selectSelectedModel)
  const [deleteDeployment] = useDeleteModelDeploymentMutation()
  const [deployModel] = useDeployModelMutation()
  const [showConfirmDeleteDeployment, setShowConfirmDeleteDeployment] = useState<boolean>(false)

  const [localModel, setLocalModel] = useState<Model | undefined>(undefined)

  useEffect(() => {
    setLocalModel(model ? model : selectedModel || undefined)
  }, [model, selectedModel])

  const handleDeploy = async (modelId: string) => {
    try {
      await deployModel({ id: modelId }).unwrap()
      dispatch(modelsApi.util.invalidateTags([{type: 'Model', id: modelId}]))
    } catch (error) {
      console.error('Failed to deploy selectedModel:', error)
    }
  }

  const handleDeleteDeployment = async (modelId: string | undefined) => {
    if (modelId) {
      const deletedDeployment = await deleteDeployment({ id: modelId })
      console.log('deletedDeployment', { deletedDeployment })
    }
  }


  if (localModel?.state === 'TRAINED' && !(localModel?.deployment?.status === 'IN_SERVICE')) {
    return (
      <div className="deployment-btn-wrapper">
        <Button
          size="lg"
          className={`deploy-btn ${localModel?.deployment?.status === 'CREATING' ? 'pulse deploying' : ''}`}
          style={{ marginTop: '10px' }}
          onClick={() => handleDeploy(localModel?.id || '')}
          disabled={localModel?.deployment?.status === 'CREATING'}
        >
          {localModel?.deployment?.status === 'CREATING' ? 'Deploying...' : 'Deploy Model'}
        </Button>
      </div>
    )
  }

  if (localModel?.state === 'TRAINED') {
    return (
      <div className="deployment-btn-wrapper">
        <Button
          size="lg"
          className="delete-btn"
          style={{ marginTop: '10px' }}
          onClick={() => setShowConfirmDeleteDeployment(true)}
        >
          Delete Deploy
        </Button>

        <ConfirmDialog
          show={showConfirmDeleteDeployment}
          onHide={() => setShowConfirmDeleteDeployment(false)}
          onConfirm={() => handleDeleteDeployment(localModel?.id)}
          title="Delete Deployment"
          message="Are you sure you want to delete your model deployment? You will still be able to re-deploy after.."
          confirmText="Delete Deployment"
          cancelText="Cancel"
          variant="danger"
        />
      </div>
    )
  }

  return null
}

export default ButtonDeployModel
