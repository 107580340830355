import React from 'react'
import { Col, Pagination, Row, Button } from 'react-bootstrap'
import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react'

interface ImageGalleryHeaderProps {
  currentPage: number
  totalImages: number
  itemsPerPage: number
  cardSize: number
  onPageChange: (page: number) => void
  onCardSizeChange: (size: number) => void
}

const ImageGalleryHeader: React.FC<ImageGalleryHeaderProps> = ({
  currentPage,
  totalImages,
  itemsPerPage,
  cardSize,
  onPageChange,
  onCardSizeChange,
}) => {
  return (
    <>
      <Row>
        <Col xs={6} className="pt-3 pagination-controls-container">
          <Pagination className="pagination-wrapper">
            <Pagination.First
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            <Pagination.Item active>{currentPage}</Pagination.Item>
            <Pagination.Next
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalImages / itemsPerPage)}
            />
            <Pagination.Last
              onClick={() => onPageChange(Math.ceil(totalImages / itemsPerPage))}
              disabled={currentPage === Math.ceil(totalImages / itemsPerPage)}
            />
          </Pagination>
        </Col>
        <Col xs={6} className="d-flex justify-content-end pagination-controls-container">
          <label htmlFor="sizeSlider" className="size-slider me-2 text-white">
            Image Size:
          </label>
          <input
            id="sizeSlider"
            type="range"
            min="100"
            max="600"
            value={cardSize}
            onChange={(e) => onCardSizeChange(Number(e.target.value))}
          />
        </Col>
      </Row>
    </>
  )
}

export default ImageGalleryHeader