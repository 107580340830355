import React, {useEffect, useMemo, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Row, Col, Button, Card, Tabs, Tab } from 'react-bootstrap'
import ImageAnnotationComponentV2 from '../components/annotation/ImageAnnotationComponentV2'
import { annotationsApi, useCreateAnnotationMutation } from '../services/annotationApi'
import { contentApi } from '../services/contentApi'
import {
  selectFilteredBoundingBoxesNew,
  clearAllBoundingBoxes,
  setAllBoundingBoxes,
  clearInitialBoundingBoxes,
  setInitialBoundingBoxes,
  setRejectedBoundingBoxes,
  setAcceptedBoundingBoxes,
  selectRejectedBoundingBoxes,
  selectAcceptedBoundingBoxes, selectPredictionsPage, selectAllUntouchedBoundingBoxes
} from '../store/slices/annotationSlice'
import { CreateBBoxAnnotationReq } from '../types/requests'
import {selectActiveView, selectFilterTags, selectSelectedProject} from '../store/slices/projectSlice'
import {Prediction, PredictionMetadata, QueryPredictionRequest} from '../types/prediction'
import { RootState } from '../store'
import { selectSelectedModel } from '../store/slices/modelSlice'
import { AnnotationDataBoundingBox } from '../types/annotation'
import {
  selectEditingPrediction, setEditingPrediction
} from '../store/slices/predictionSlice'
import { useGetTagsQuery } from '../services/tagApi'

import {predictionApi, useQueryPredictionsQuery} from '../services/predictionApi'
import SnippetWithOverlay from '../components/SnippetWithOverlay'
import {selectDatasetSources} from '../store/slices/datasetSlice'

const PredictionReview: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const filteredBoundingBoxes = useAppSelector(selectFilteredBoundingBoxesNew)

  const [createAnnotation] = useCreateAnnotationMutation()

  const project = useAppSelector(selectSelectedProject)
  const selectedModel = useAppSelector(selectSelectedModel)
  const editingPrediction = useAppSelector(selectEditingPrediction)

  const selectedPredictions = useAppSelector((state: RootState) => state.prediction.selectedPredictions)

  const allUntouchedBBoxes = useAppSelector(selectAllUntouchedBoundingBoxes)
  const rejectedBBoxes = useAppSelector(selectRejectedBoundingBoxes)
  const acceptedBBoxes = useAppSelector(selectAcceptedBoundingBoxes)

  const activeView = useAppSelector(selectActiveView)

  const datasetSources = useAppSelector(selectDatasetSources)
  const selectedFilterTags = useAppSelector(selectFilterTags)
  const page = useAppSelector(selectPredictionsPage)

  const [snippetGlowIndex, setSnippetGlowIndex] = useState<number | undefined>(0)

  const queryParams: QueryPredictionRequest = useMemo(() => ({
    query: {
      filters: [
        { key: 'modelid', value: selectedModel?.id || '' },
        ...(datasetSources?.[0]
          ? [{ key: 'datasource', value: datasetSources[0], metadata_filter: {enable: true} }]
          : []
        )
      ],
      sort_key: 'contentid',
      sort_val: -1,
      page: page - 1,
      operator: 'and',
      limit: 100,
    },
    filter_annotated_content: {
      enable: true,
      projectid: project?.id || '',
      datasetid: project?.datasetid || ''
    }
  }), [datasetSources, page, project?.datasetid, project?.id, selectedModel?.id])

  const {data: allPredictions, isLoading: isPredictionsLoading } = useQueryPredictionsQuery(queryParams)

  // Memoized Values
  const filteredPredictitons = useMemo(() => {
    const contentToProcess = allPredictions?.predictions || []

    let filteredForTagIds: Prediction[] | undefined
    if (selectedFilterTags?.length > 0) {
      filteredForTagIds = contentToProcess?.filter((prediction) => {
        const contentTagIds = prediction.predictions?.map((p) => p.tagid) || []
        return contentTagIds?.some(tagId => selectedFilterTags?.map(t => t.id).includes(tagId))
      })
    } else {
      filteredForTagIds = contentToProcess
    }

    return filteredForTagIds
  }, [allPredictions, selectedFilterTags])

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const [selectedSnippetIndex, setSelectedSnippetIndex] = useState<number | undefined>(undefined)

  useEffect(() => {
    const predBoundingBoxes = editingPrediction?.predictions.map((bbox: PredictionMetadata, index) => {
      if (project?.annotation_type === 'bounding_box') {
        return {
          name: tags?.tags?.find((t) => t.name === bbox.class_name)?.id,
          class_name: tags?.tags?.find((t) => t.name === bbox.class_name)?.id,
          xmin: bbox.bounding_boxes?.xmin,
          ymin: bbox.bounding_boxes?.ymin,
          xmax: bbox.bounding_boxes?.xmax,
          ymax: bbox.bounding_boxes?.ymax,
          confidence: bbox.confidence,
          class_index: index,
        } as AnnotationDataBoundingBox
      } else {
        return {
          name: tags?.tags?.find((t) => t.name === bbox.class_name)?.id,
          class_name: tags?.tags?.find((t) => t.name === bbox.class_name)?.id,
          xmin: 0,
          ymin: 0,
          xmax: 0,
          ymax: 0,
          confidence: bbox.confidence,
          class_index: index,
        } as AnnotationDataBoundingBox
      }
    })

    dispatch(setAllBoundingBoxes(predBoundingBoxes ? [...predBoundingBoxes] : []))
    dispatch(setInitialBoundingBoxes(predBoundingBoxes ? [...predBoundingBoxes] : []))
    dispatch(setRejectedBoundingBoxes([]))
    dispatch(setAcceptedBoundingBoxes([]))
  }, [editingPrediction, tags, project?.annotation_type, dispatch])


  const handleNextImage = () => {
    const predictionIdx = filteredPredictitons?.findIndex((p) => p.id === editingPrediction?.id)
    if (predictionIdx !== undefined && predictionIdx > -1 && filteredPredictitons?.[predictionIdx + 1]) {
      dispatch(clearInitialBoundingBoxes())
      dispatch(clearAllBoundingBoxes())
      dispatch(setEditingPrediction(filteredPredictitons?.[predictionIdx + 1]))
    }
  }

  const handlePrevImage = () => {
    const predictionIdx = filteredPredictitons?.findIndex((p) => p.id === editingPrediction?.id)
    if (predictionIdx && predictionIdx > -1 && filteredPredictitons?.[predictionIdx - 1]) {
      dispatch(clearInitialBoundingBoxes())
      dispatch(setEditingPrediction(filteredPredictitons?.[predictionIdx - 1]))
      dispatch(clearAllBoundingBoxes())
    }
  }

  const handleAcceptPredictions = async () => {
    if (editingPrediction) {
      try {
        const annotationTagIds: string[] = Array.from(new Set(filteredBoundingBoxes.map((a) => a.name)))
        // Create annotation request CreateAnnotationReq
        const annoToCreate: CreateBBoxAnnotationReq = {
          content_id: editingPrediction.contentid || '',
          dataset_id: project?.datasetid || '',
          metadata: project?.annotation_type === 'bounding_box' ? { bounding_boxes: filteredBoundingBoxes } : undefined,
          project_id: project?.id || '',
          tag_id: annotationTagIds,
          // thumbnailSize: 640,
        }

        const createdAnno = await createAnnotation(annoToCreate)
        // Invalidate the relevant cache to refresh the content
        dispatch(
          contentApi.util.invalidateTags([
            { type: 'AnnotatedContent', id: createdAnno?.data?.contentid },
            { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
            { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
            { type: 'Content', id: createdAnno?.data?.contentid || '' },
          ]),
        )
        dispatch(annotationsApi.util.invalidateTags([{ type: 'annotation', id: 'LIST'}]))
        dispatch(predictionApi.util.invalidateTags([{ type: 'Prediction', id: 'LIST' }]))
        dispatch(contentApi.util.invalidateTags([{ type: 'AnnotatedContent', id: 'ANNOTATED_LIST' }]))
      } catch (error) {
        console.error('Failed to create annotation:', error)
      }
      handleNextImage()
    } else {
      for (const prediction of selectedPredictions) {
        try {
          await createAnnotation({
            content_id: prediction.contentid,
            dataset_id: selectedModel?.datasetid || '',
            project_id: selectedModel?.projectid || '',
            tag_id: prediction.predictions.map((p) => p.tagid),
            metadata:
              project?.annotation_type === 'bounding_box'
                ? {
                  bounding_boxes: prediction.predictions.flatMap((p) =>
                    p.bounding_boxes ? Object.values(p.bounding_boxes) : [],
                  ),
                }
                : undefined,
          }).unwrap()
        } catch (error) {
          console.error('Failed to create annotation:', error)
        }
      }
      dispatch(
        contentApi.util.invalidateTags([
          { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
          { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
        ]),
      )
    }
  }


  return (
    <div className="predictions-review">
      <div className="predictions-content">
        {/* Add your predictions review content here */}

        <div className="annotation-tab-content">
          <div className="annotorious-container">
            <Row>
              <div className="annotation-controls">
                <Row className="justify-content-between">
                  <Col xs={3}>
                    <Button variant="primary" onClick={() => navigate(-1)}>
                      ← Back to {activeView}
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={handlePrevImage} className="annotation-nav-btn" aria-label="Previous image">
                      <ChevronLeft size={24} />
                    </Button>
                  </Col>
                  <Col className="text-center">
                    <Button variant="success" className="mx-2 mt-1" onClick={handleAcceptPredictions}>
                      Submit
                    </Button>
                  </Col>
                  <Col className="text-end">
                    <Button onClick={handleNextImage} className="annotation-nav-btn" aria-label="Next image">
                      <ChevronRight size={24} />
                    </Button>
                  </Col>
                  <Col xs={2} />
                </Row>
              </div>
            </Row>
            <Row>
              <Col xs={9}>
                <Card className="bg-dark d-flex align-items-center justify-content-between" >
                  {editingPrediction && (
                    <div>
                      <ImageAnnotationComponentV2
                        contentId={editingPrediction.contentid}
                        useBBoxes={project?.annotation_type === 'bounding_box'}
                        focusedBBoxIndex={selectedSnippetIndex}
                        setFocusedBBoxIndex={setSelectedSnippetIndex}
                        setSnippetGlowIndex={setSnippetGlowIndex}
                      />
                    </div>
                  )}
                </Card>
              </Col>

              {/* Prediction Result snippets */}
              <Col xs={3} style={{ height: '90vh', overflow: 'auto' }}>
                {editingPrediction && (
                  <div className="h-full flex flex-col">
                    <Card className="bg-dark" style={{ height: '100%', overflow: 'hidden', border: 'none' }}>
                      <Card.Body
                        className="h-[calc(100vh-300px)] overflow-y-auto overflow-x-hidden bg-dark"
                        onWheel={(e) => e.stopPropagation()}
                      >
                        <Tabs defaultActiveKey="inspect" id="prediction-tabs" className="mb-3">
                          <Tab eventKey="inspect" title={`Inspect (${allUntouchedBBoxes.length})`} className="bg-dark">

                            <Row style={{maxHeight: '75vh', overflow: 'scroll'}}>
                              {allUntouchedBBoxes.map((box, index) => (
                                <Col key={index}
                                  style={{ backgroundColor: '#1a1a1a'}}
                                  className="my-3 px-2 py-5 justify-content-center align-items-center d-flex"
                                >
                                  <SnippetWithOverlay
                                    contentId={editingPrediction.contentid}
                                    index={box.class_index}
                                    selectedSnippetIndex={selectedSnippetIndex}
                                    setSelectedSnippetIndex={() => setSelectedSnippetIndex(box.class_index)}
                                    glow={snippetGlowIndex === index}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Tab>

                          <Tab eventKey="accepted" title={`Accepted (${acceptedBBoxes.length})`} className="bg-dark">
                            <Row style={{maxHeight: '75vh', overflow: 'scroll'}}>
                              {acceptedBBoxes.map((box, index) => (
                                <Col key={index}
                                  style={{ backgroundColor: '#1a1a1a'}}
                                  className="my-3 px-2 py-5 justify-content-center align-items-center d-flex"
                                >
                                  <SnippetWithOverlay
                                    showAccept={false}
                                    contentId={editingPrediction.contentid}
                                    index={box.class_index}
                                    selectedSnippetIndex={selectedSnippetIndex}
                                    setSelectedSnippetIndex={() => setSelectedSnippetIndex(box.class_index)}
                                    glow={snippetGlowIndex === index}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Tab>

                          <Tab eventKey="rejected" title={`Rejected (${rejectedBBoxes.length})`} className="bg-dark">
                            <Row style={{maxHeight: '75vh', overflow: 'scroll'}}>
                              {rejectedBBoxes.map((box, index) => (
                                <Col key={index}
                                  style={{ backgroundColor: '#1a1a1a'}}
                                  className="my-3 px-2 py-5 justify-content-center align-items-center d-flex"
                                >
                                  <SnippetWithOverlay
                                    showDelete={false}
                                    contentId={editingPrediction.contentid}
                                    index={box.class_index}
                                    selectedSnippetIndex={selectedSnippetIndex}
                                    setSelectedSnippetIndex={() => setSelectedSnippetIndex(box.class_index)}
                                    glow={snippetGlowIndex === index}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Tab>
                        </Tabs>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Col>

            </Row>
            <Row>
              <Col></Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PredictionReview
