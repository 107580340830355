// src/components/layout.tsx

import React from 'react'
import { useAppSelector } from '../store/hooks'
import { selectIsAuthenticated } from '../store/slices/authSlice'
import AvNavbar from '../components/AvNavBar' // Update this path

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated) // Use selector to get login status from the Redux store

  return (
    <>
      {isAuthenticated && <AvNavbar />}
      <div style={{ padding: '.5rem', paddingTop: '10px', height: '92vh', overflow: 'hidden' }}>
        {children}
      </div>
    </>
  )
}

export default Layout
