import React, { useState } from 'react'
import { Modal, Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  Hyperparameters,
  Hyperparameter,
  StaticHyperparameter,
  ContinuousHyperparameter,
  CategoricalHyperparameter,
  HyperparameterType,
  Runtime,
  TrainParameters,
  // InstanceType,
} from '../../types/model'

const allowedNumLayers = [18, 34, 50, 101, 152, 200]
const allowedBoundingBoxOptimizers = ['sgd', 'adam', 'rmsprop', 'adadelta']
const allowedClassificationOptimizers = ['sgd', 'adam', 'rmsprop', 'nag']
const allowedAugmentationTypes = ['crop', 'crop_color', 'crop_color_transform']
const allowedPrecisionDtypes = ['float32', 'float16']
// const instanceTypes: InstanceType[] = ['ml.p3.2xlarge', 'ml.p3.8xlarge', 'ml.p3.16xlarge']

interface HyperparameterSettingsModalProps {
  show: boolean
  handleClose: () => void
  projectType: string | undefined
  onSave: (parameters: TrainParameters) => void
  initialHyperparameters?: Partial<Hyperparameters>
}

const HyperparameterSettingsModal: React.FC<HyperparameterSettingsModalProps> = ({
  show,
  handleClose,
  projectType,
  onSave,
  initialHyperparameters = {},
}) => {
  // New state for storing temporary input values
  const [tempInputs, setTempInputs] = useState<{ [key: string]: any }>({})

  // Helper function to update temp inputs
  const updateTempInput = (name: string, value: any) => {
    setTempInputs((prev) => ({ ...prev, [name]: value }))
  }

  // Helper function to validate and update main state
  const validateAndUpdateState = (name: string, value: any, type: string) => {
    let validatedValue = value

    if (type === 'float' || type === 'integer') {
      validatedValue = parseFloat(value)
      if (isNaN(validatedValue)) {
        validatedValue = 0
      }
    }

    // Add more validation logic here if needed

    setHyperparameters((prev) => ({
      ...prev,
      [name]: {
        static: { value: validatedValue },
      },
    }))
  }

  // Define default hyperparameters for bounding_box
  const boundingBoxDefaults = {
    early_stopping: { static: { value: true } },
    early_stopping_min_epochs: { static: { value: 10 } },
    early_stopping_patience: { static: { value: 5 } },
    early_stopping_tolerance: { static: { value: 0.0 } },
    image_shape: { static: { value: 1024 } },
    epochs: { static: { value: 100 } },
    learning_rate: { continuous: { min_value: 0.0001, max_value: 0.01 } },
    lr_scheduler_step: { static: { value: '10' } },
    lr_scheduler_factor: { static: { value: 0.8 } },
    momentum: { continuous: { min_value: 0.0001, max_value: 0.1 } },
    weight_decay: { continuous: { min_value: 0.0001, max_value: 0.1 } },
    nms_threshold: { static: { value: 0.45 } },
    optimizer: { categorical: { values: ['sgd', 'adam', 'rmsprop'] } },
    overlap_threshold: { static: { value: 0.5 } },
  }

  // Define default hyperparameters for classification
  const classificationDefaults = {
    augmentation_type: { static: { value: 'crop_color_transform' } },
    early_stopping: { static: { value: true } },
    early_stopping_min_epochs: { static: { value: 10 } },
    early_stopping_patience: { static: { value: 5 } },
    early_stopping_tolerance: { static: { value: 0.0 } },
    epochs: { static: { value: 50 } },
    learning_rate: { continuous: { min_value: 0.0001, max_value: 0.01 } },
    lr_scheduler_step: { static: { value: '10' } },
    lr_scheduler_factor: { static: { value: 0.8 } },
    momentum: { continuous: { min_value: 0.0001, max_value: 0.1 } },
    multi_label: { static: { value: true } },
    num_layers: { static: { value: 50 } },
    optimizer: { categorical: { values: ['sgd', 'adam', 'rmsprop'] } },
    precision_dtype: { static: { value: 'float32' } },
    resize: { static: { value: 224 } },
    weight_decay: { continuous: { min_value: 0.0001, max_value: 0.1 } },
  }

  // Define default runtime parameters
  const runtimeDefaults = {
    max_runtime_seconds: 9600,
    maximum_retry_attempts: 3,
    max_number_training_jobs: 1,
    max_batch_size: 64,
  }

  // Select the default based on projectType
  const defaultHyperparameters = projectType === 'bounding_box' ? boundingBoxDefaults : classificationDefaults

  // Merge the selected defaults with the initial hyperparameters
  const [hyperparameters, setHyperparameters] = useState<Hyperparameters>({
    ...defaultHyperparameters,
    ...initialHyperparameters, // Override defaults with any provided initial hyperparameters
  })

  const [runtimeParams, setRuntimeParams] = useState<Runtime>({ ...runtimeDefaults })

  const handleHyperparameterTypeSelection = (name: string, type: 'static' | 'continuous' | 'categorical') => {
    setHyperparameters((prev) => ({
      ...prev,
      [name]:
        type === 'static'
          ? { static: { value: '' } }
          : type === 'continuous'
            ? { continuous: { min_value: 0, max_value: 1 } }
            : { categorical: { values: [] } },
    }))
  }

  function isStaticHyperparameter<T>(hyperparam: Hyperparameter<T>): hyperparam is StaticHyperparameter<T> {
    return 'static' in hyperparam
  }

  function isContinuousHyperparameter(hyperparam: Hyperparameter<any>): hyperparam is ContinuousHyperparameter {
    return 'continuous' in hyperparam
  }

  function isCategoricalHyperparameter<T>(hyperparam: Hyperparameter<any>): hyperparam is CategoricalHyperparameter {
    return 'categorical' in hyperparam
  }

  // For handling static hyperparameters
  const handleStaticChange = <K extends keyof Hyperparameters>(
    e: React.ChangeEvent<any>,
    name: K,
    allowedMin?: number,
    allowedMax?: number,
  ) => {
    let value: any

    if (e.target.type === 'checkbox') {
      value = (e.target as HTMLInputElement).checked
    } else if (e.target.type === 'number') {
      const inputValue = parseFloat(e.target.value)
      value = e.target.step === '1' ? Math.round(inputValue) : inputValue
      if (allowedMin !== undefined && value < allowedMin) {
        value = allowedMin
      }
      if (allowedMax !== undefined && value > allowedMax) {
        value = allowedMax
      }
    } else {
      value = e.target.value
    }

    setHyperparameters((prev) => {
      const hyperparam = prev[name]
      if (hyperparam === undefined) {
        return prev
      }
      return {
        ...prev,
        [name]: {
          static: {
            value,
          },
        },
      }
    })
  }

  const handleContinuousChange = <K extends keyof Hyperparameters>(
    e: React.ChangeEvent<HTMLInputElement>,
    name: K,
    field: 'min_value' | 'max_value',
    allowedMin?: number,
    allowedMax?: number,
  ) => {
    let value = parseFloat(e.target.value)
    if (allowedMin !== undefined && value < allowedMin) {
      value = allowedMin
    }
    if (allowedMax !== undefined && value > allowedMax) {
      value = allowedMax
    }

    setHyperparameters((prev) => {
      const hyperparam = prev[name]
      if (hyperparam === undefined) {
        return prev
      }

      if (isContinuousHyperparameter(hyperparam)) {
        let newMin = field === 'min_value' ? value : hyperparam.continuous.min_value
        let newMax = field === 'max_value' ? value : hyperparam.continuous.max_value

        // Ensure that min_value is not greater than max_value
        if (newMin > newMax) {
          if (field === 'min_value') {
            newMax = newMin // Adjust max_value to match min_value
          } else {
            newMin = newMax // Adjust min_value to match max_value
          }
        }

        return {
          ...prev,
          [name]: {
            continuous: {
              min_value: newMin,
              max_value: newMax,
            },
          },
        }
      }

      return prev
    })
  }

  const handleCategoricalChange = <K extends keyof Hyperparameters>(
    e: React.ChangeEvent<HTMLInputElement>,
    name: K,
    allowedValues?: (string | number)[],
  ) => {
    let value: string | number = e.target.value
    const checked = e.target.checked

    // If allowedValues is passed and contains numbers, try to convert the value
    if (allowedValues && allowedValues.some((v) => typeof v === 'number')) {
      value = Number(value) // Convert to number if applicable
    }

    setHyperparameters((prev) => {
      const hyperparam = prev[name]

      if (hyperparam && isCategoricalHyperparameter(hyperparam)) {
        let updatedValues = [...hyperparam.categorical.values]

        if (checked) {
          updatedValues.push(value)
        } else {
          updatedValues = updatedValues.filter((v) => v !== value)
        }

        return {
          ...prev,
          [name]: {
            categorical: {
              values: updatedValues,
            },
          } as Hyperparameter<any>,
        }
      }

      return prev
    })
  }

  const handleRuntimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: keyof Runtime,
    allowedMin?: number,
    allowedMax?: number,
  ) => {
    let value = parseInt(e.target.value, 10)

    if (allowedMin !== undefined && value < allowedMin) {
      value = allowedMin
    }
    if (allowedMax !== undefined && value > allowedMax) {
      value = allowedMax
    }

    // Update the runtimeParams state
    setRuntimeParams((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const renderRuntimeControl = (runtime: Runtime, name: keyof Runtime, allowedMin?: number, allowedMax?: number) => {
    return (
      <div className="d-flex align-items-center">
        <div>
          <Form.Control
            as="input"
            type="number"
            step={1}
            value={runtime[name]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRuntimeChange(e, name, allowedMin, allowedMax)}
          />
        </div>
      </div>
    )
  }

  const renderHyperparameterControl = (
    hyperparameter: Hyperparameter<any> | undefined,
    name: string,
    type: 'boolean' | 'integer' | 'float' | 'string' | 'regex' | 'choice',
    allowedMin?: number,
    allowedMax?: number,
    allowedValues?: any[],
    buttonOptions: HyperparameterType[] = ['static'],
  ) => {
    return (
      <div className="d-flex align-items-center">
        {(hyperparameter === undefined || buttonOptions.length > 1) && (
          <div className="me-3">
            {buttonOptions.map((option) => (
              <Button key={option} onClick={() => handleHyperparameterTypeSelection(name, option)} className="me-2">
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </Button>
            ))}
          </div>
        )}
        <div>
          {hyperparameter && isStaticHyperparameter(hyperparameter) && (
            <>
              {type === 'boolean' ? (
                <Form.Check
                  type="checkbox"
                  name={name}
                  label={name}
                  checked={hyperparameter.static.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleStaticChange(e, name as keyof Hyperparameters)
                  }
                />
              ) : type === 'integer' || type === 'float' ? (
                <Form.Control
                  as="input"
                  type="number"
                  step={type === 'integer' ? 1 : 0.001}
                  name={name}
                  value={tempInputs[name] ?? hyperparameter.static.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTempInput(name, e.target.value)}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => validateAndUpdateState(name, e.target.value, type)}
                />
              ) : type === 'string' || type === 'regex' ? (
                <Form.Control
                  as="input"
                  type="text"
                  name={name}
                  value={tempInputs[name] ?? hyperparameter.static.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTempInput(name, e.target.value)}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => validateAndUpdateState(name, e.target.value, type)}
                />
              ) : type === 'choice' && allowedValues ? (
                <Form.Control
                  as="select"
                  name={name}
                  value={hyperparameter.static.value}
                  onChange={(e) => handleStaticChange(e, name as keyof Hyperparameters)}
                >
                  {allowedValues.map((option: any) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              ) : null}
            </>
          )}

          {hyperparameter && isContinuousHyperparameter(hyperparameter) && (
            <>
              <Form.Control
                as="input"
                type="number"
                name={`${name}_min`}
                placeholder="Min Value"
                step={0.01}
                value={hyperparameter.continuous.min_value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleContinuousChange(e, name as keyof Hyperparameters, 'min_value', allowedMin, allowedMax)
                }
              />
              <Form.Control
                as="input"
                type="number"
                name={`${name}_max`}
                placeholder="Max Value"
                step={0.01}
                value={hyperparameter.continuous.max_value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleContinuousChange(e, name as keyof Hyperparameters, 'max_value', allowedMin, allowedMax)
                }
              />
            </>
          )}

          {hyperparameter && 'categorical' in hyperparameter && allowedValues && (
            <div className="categorical-checkbox-group">
              <label>{name}</label>
              {allowedValues.map((option) => (
                <Form.Check
                  type="checkbox"
                  key={option}
                  label={option}
                  value={option}
                  checked={hyperparameter.categorical.values.includes(option)} // Check if the option is already selected
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleCategoricalChange(e, name as keyof Hyperparameters, allowedValues)
                  }
                />
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  const handleSave = () => {
    const params: TrainParameters = {
      hyperparameters: hyperparameters,
      runtime: runtimeParams,
    }
    onSave(params)
    handleClose()
  }

  const renderTooltip = (content: string) => <Tooltip id="button-tooltip">{content}</Tooltip>

  return (
    <Modal show={show} onHide={handleClose} size="xl" className="hyperparameter-modal">
      <Modal.Header closeButton>
        <Modal.Title>Tune Model Training Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Runtime Settings */}
          <fieldset className="border p-3 mb-4">
            <legend className="w-auto">Runtime Settings</legend>
            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip('Set max training job runtimes')}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Max Runtimes Seconds
                </Form.Label>
                <Col sm={8}>
                  {renderRuntimeControl(runtimeParams, 'max_runtime_seconds', 1800, 9600)}
                  <Form.Text>Typical range: 1800 to 9600.</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip('Set maximum training job retry attempts.')}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Max Retry Attempts
                </Form.Label>
                <Col sm={8}>
                  {renderRuntimeControl(runtimeParams, 'maximum_retry_attempts', 0, 5)}
                  <Form.Text>Typical range: 0 to 5.</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip('Set maximum number of training jobs.')}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Max Number Training Jobs
                </Form.Label>
                <Col sm={8}>
                  {renderRuntimeControl(runtimeParams, 'max_number_training_jobs', 1, 20)}
                  <Form.Text>Typical range: 1 to 20.</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip('Set maximum training batch size.')}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Max Batch Size
                </Form.Label>
                <Col sm={8}>
                  {renderRuntimeControl(runtimeParams, 'max_batch_size', 12, 128)}
                  <Form.Text>Typical range: 32 to 128.</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>
          </fieldset>
          {/* Hyperparameters Section */}
          <fieldset className="border p-3 mb-4">
            <legend className="w-auto">Hyperparameters</legend>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip('Use early stopping logic during training.')}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Early Stopping
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(hyperparameters.early_stopping, 'early_stopping', 'boolean')}
                  <Form.Text>Flag to use early stopping</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'The minimum number of epochs that must be run before the early stopping logic can be invoked.' +
                  'It is used only when early_stopping = True.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Early Stopping Min Epochs
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(
                    hyperparameters.early_stopping_min_epochs,
                    'early_stopping_min_epochs',
                    'integer',
                    1,
                    100,
                  )}
                  <Form.Text>Typical range: 1 to 20</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'The number of epochs to wait before ending training if no improvement,' +
                  'as defined by the early_stopping_tolerance hyperparameter, is made in the relevant metric.' +
                  'It is used only when early_stopping = True.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Early Stopping Patience
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(
                    hyperparameters.early_stopping_patience,
                    'early_stopping_patience',
                    'integer',
                    1,
                    100,
                  )}
                  <Form.Text>Typical range: 1 to 20</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'The tolerance value that the relative improvement in accuracy (classification) or validation:mAP (object detection)' +
                  'is required to exceed to avoid early stopping.' +
                  'It is used only when early_stopping = True.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Early Stopping Tolerance
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(
                    hyperparameters.early_stopping_tolerance,
                    'early_stopping_tolerance',
                    'float',
                    0.0,
                    1.0,
                  )}
                  <Form.Text>Typical range: 0.0 to 1.0</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'An epoch is one complete pass through the entire training dataset.' +
                  ' More epochs allow the algorithm to learn more patterns from the data, but too many can lead to' +
                  ' overfitting.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Epochs
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(hyperparameters.epochs, 'epochs', 'integer', 10, 1000)}
                  <Form.Text>Typical range: 10 to 1000</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'The epochs at which to reduce the learning rate. The learning rate is' +
                  ' reduced by lr_scheduler_factor at epochs listed in a comma-delimited string: "epoch1, epoch2, ...".' +
                  ' For example, if the value is set to "10, 20" and the lr_scheduler_factor is set to 1/2, then the' +
                  ' learning rate is halved after 10th epoch and then halved again after 20th epoch.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  LR Scheduler Step
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(hyperparameters.lr_scheduler_step, 'lr_scheduler_step', 'string')}
                  <Form.Text>Comma-separated list of epochs to reduce learning rate</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'The ratio to reduce learning rate. Used in conjunction with the' +
                  ' LR Scheduler Step parameter defined as lr_new = lr_old * lr_scheduler_factor.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  LR Scheduler Factor
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(
                    hyperparameters.lr_scheduler_factor,
                    'lr_scheduler_factor',
                    'float',
                    0.0,
                    1.0,
                    undefined,
                    ['static', 'continuous'],
                  )}
                  <Form.Text>Typical range: 0.1 to 0.5</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'Controls how much to change the model in response to the estimated error' +
                  ' each time the model weights are updated. If too low, training will progress very slowly. If too high,' +
                  ' training might not converge or even diverge.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Learning Rate
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(
                    hyperparameters.learning_rate,
                    'learning_rate',
                    'float',
                    0.0,
                    1.0,
                    undefined,
                    ['static', 'continuous'],
                  )}
                  <Form.Text>Typical range: 1e-6 to 0.1</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip('The momentum for sgd. Ignored for other optimizers.')}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Momentum
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(hyperparameters.momentum, 'momentum', 'float', 0.0, 1.0, undefined, [
                    'static',
                    'continuous',
                  ])}
                  <Form.Text>Typical range: 0.0 to 1.0</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top-end"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'A regularization technique to prevent overfitting. It adds a small' +
                  ' penalty for having large weights in the network, encouraging the network to use all of its inputs' +
                  ' a little rather than some of its inputs a lot.',
              )}
            >
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Weight Decay
                </Form.Label>
                <Col sm={8}>
                  {renderHyperparameterControl(
                    hyperparameters.weight_decay,
                    'weight_decay',
                    'float',
                    0.0,
                    1.0,
                    undefined,
                    ['static', 'continuous'],
                  )}
                  <Form.Text>Typical range: 1e-6 to 1e-4</Form.Text>
                </Col>
              </Form.Group>
            </OverlayTrigger>

            {projectType === 'classification' && (
              <>
                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Image augmentation option.' +
                      '"crop": Randomly crop the image and flip the image horizontally.' +
                      '"crop_color": In addition to ‘crop’, three random values in the range [-36, 36], [-50, 50], and [-50, 50] are added to the corresponding Hue-Saturation-Lightness channels respectively.' +
                      '"crop_color_transform": In addition to crop_color, random transformations, including rotation, shear, and aspect ratio variations are applied to the image.' +
                      'The maximum angle of rotation is 10 degrees, the maximum shear ratio is 0.1, and the maximum aspect changing ratio is 0.25.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Augmentation Type
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(
                        hyperparameters.augmentation_type,
                        'augmentation_type',
                        'choice',
                        undefined,
                        undefined,
                        allowedAugmentationTypes,
                      )}
                      <Form.Text>
                        Augmentation Types: &quot;crop&quot;, &quot;crop_color&quot;, &quot;crop_color_transform&quot;
                      </Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The beta1 for adam, that is the exponential decay rate for the first moment estimates.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Beta 1
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.beta_1, 'beta_1', 'float', 0.0, 1.0, undefined, [
                        'static',
                        'continuous',
                      ])}
                      <Form.Text>Typical range: 0.0 to 1.0</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The beta2 for adam, that is the exponential decay rate for the second moment estimates.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Beta 2
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.beta_2, 'beta_2', 'float', 0.0, 1.0, undefined, [
                        'static',
                        'continuous',
                      ])}
                      <Form.Text>Typical range: 0.0 to 1.0</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The epsilon for adam and rmsprop.' + 'It is usually set to a small value to avoid division by 0.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Epsilon
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.eps, 'eps', 'float', 0.0, 1.0, undefined, [
                        'static',
                        'continuous',
                      ])}
                      <Form.Text>Typical range: 0.0 to 1.0</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The gamma for rmsprop, the decay factor for the moving average of the squared gradient.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Gamma
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.gamma, 'gamma', 'float', 0.0, 1.0, undefined, [
                        'static',
                        'continuous',
                      ])}
                      <Form.Text>Typical range: 0.0 to 1.0</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Flag to use for multi-label classification where each sample can be assigned multiple labels.' +
                      'Average accuracy across all classes is logged.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Multi-Label
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.multi_label, 'multi_label', 'boolean')}
                      <Form.Text>Flag to use multi-labeling</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Number of layers for the network. For data with large image sizes (for' +
                      ' example, 224x224 - like ImageNet), we suggest selecting the number of layers from the set [18, 34,' +
                      ' 50, 101, 152, 200]. For data with small image size (for example, 28x28 - like CIFAR), we suggest' +
                      ' selecting the number of layers from the set [20, 32, 44, 56, 110].',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Number of Layers
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(
                        hyperparameters.num_layers,
                        'num_layers',
                        'choice',
                        undefined,
                        undefined,
                        allowedNumLayers,
                        ['static', 'categorical'],
                      )}
                      <Form.Text>Common values: 18, 34, 50, 101, 152, 200</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Used in object detection models to determine when two bounding boxes' +
                      ' are considered to be detecting the same object. It\'s based on the Intersection over Union (IoU)' +
                      ' metric.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Optimizer
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(
                        hyperparameters.optimizer,
                        'optimizer',
                        'choice',
                        undefined,
                        undefined,
                        allowedClassificationOptimizers,
                        ['static', 'categorical'],
                      )}
                      <Form.Text>
                        Optimizers: &quot;sgd&quot;, &quot;adam&quot;, &quot;rmsprop&quot;, &quot;nag&quot;
                      </Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The precision of the weights used for training.' +
                      'The algorithm can use either single precision (float32) or half precision (float16) for the weights.' +
                      'Using half-precision for weights results in reduced memory consumption.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Precision Decimal Type
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(
                        hyperparameters.precision_dtype,
                        'precision_dtype',
                        'choice',
                        undefined,
                        undefined,
                        allowedPrecisionDtypes,
                      )}
                      <Form.Text>Precision Decimal Types: &quot;float16&quot; or &quot;float32&quot;</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The number of pixels in the shortest side of an image after resizing it for training.' +
                      'If the parameter is not set, then the training data is used without resizing.' +
                      'The parameter should be larger than both the width and height components of image_shape to prevent training failure.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Resize
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.resize, 'resize', 'integer', 224, 1024)}
                      <Form.Text>Resize images to specified size.</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Reports the top-k accuracy during training.' +
                      'This parameter has to be greater than 1, since the top-1 training accuracy' +
                      'is the same as the regular training accuracy that has already been reported.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Top_K
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.top_k, 'top_k', 'integer', 2)}
                      <Form.Text>Positive integer greater than 1.</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Reports the top-k accuracy during training.' +
                      'This parameter has to be greater than 1, since the top-1 training accuracy' +
                      'is the same as the regular training accuracy that has already been reported.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Use Weighted Loss
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.use_weighted_loss, 'use_weighted_loss', 'boolean')}
                      <Form.Text>Flag to use weighted loss.</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>
              </>
            )}
            {projectType === 'bounding_box' && (
              <>
                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Used in object detection models to determine when two bounding boxes' +
                      ' are considered to be detecting the same object. It\'s based on the Intersection over Union (IoU)' +
                      ' metric.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Optimizer
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(
                        hyperparameters.optimizer,
                        'optimizer',
                        'choice',
                        undefined,
                        undefined,
                        allowedBoundingBoxOptimizers,
                      )}
                      <Form.Text>
                        Optimizers: &quot;sgd&quot;, &quot;adam&quot;, &quot;rmsprop&quot;, &quot;adadelta&quot;
                      </Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'Used in object detection models to determine when two bounding boxes' +
                      ' are considered to be detecting the same object. It\'s based on the Intersection over Union (IoU)' +
                      ' metric.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Overlap Threshold
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(
                        hyperparameters.overlap_threshold,
                        'overlap_threshold',
                        'float',
                        0.0,
                        1.0,
                        undefined,
                        ['static', 'continuous'],
                      )}
                      <Form.Text>Typical range: 0.3 to 0.7</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The image size for input images. We rescale the input image to a' +
                      ' square image with this size. We recommend using 300 and 512 for better performance.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Image Shape
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.image_shape, 'image_shape', 'integer', 300, 1024)}
                      <Form.Text>Typical range: 300 - 512</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'NMS (Non-Maximum Suppression) threshold is used to decide whether to' +
                      ' keep or discard boxes that significantly overlap. It helps in reducing multiple detections of the' +
                      ' same object.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      NMS Threshold
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(hyperparameters.nms_threshold, 'nms_threshold', 'float', 0.0, 1.0)}
                      <Form.Text>Typical range: 0.3 to 0.7</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top-end"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    'The regular expression (regex) for freezing layers in the base network.' +
                      'Freezing a layer means that its weights can not be modified further.' +
                      'This can reduce training time significantly in exchange for modest losses in accuracy.' +
                      'This technique is commonly used in transfer learning where the lower layers in the base network do not need to be retrained.',
                  )}
                >
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      Freeze Layer Pattern
                    </Form.Label>
                    <Col sm={8}>
                      {renderHyperparameterControl(
                        hyperparameters.freeze_layer_pattern,
                        'freeze_layer_pattern',
                        'regex',
                      )}
                      <Form.Text>Regex for columns (ex. &quot;^(col1|col2_).*&quot;)</Form.Text>
                    </Col>
                  </Form.Group>
                </OverlayTrigger>
              </>
            )}
          </fieldset>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default HyperparameterSettingsModal
