import React, { useEffect, useRef } from 'react'
import { useGetContentImageQuery } from '../../services/contentApi'
import { useAppSelector } from '../../store/hooks'
import {selectAllBoundingBoxes} from '../../store/slices/annotationSlice'
import { selectSelectedProject } from '../../store/slices/projectSlice'
import {useGetTagsQuery} from '../../services/tagApi'

interface AnnotationSnippetProps {
  contentId: string;
  bBoxIndex: number;
}

const AnnotationSnippet: React.FC<AnnotationSnippetProps> = ({
  contentId,
  bBoxIndex,
}) => {
  const project = useAppSelector(selectSelectedProject)
  const allBoundingBoxes = useAppSelector(selectAllBoundingBoxes)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const { data: imageUrl } = useGetContentImageQuery(
    {
      ContentID: contentId,
      project_id: project?.id || '',
      dataset_id: project?.datasetid || '',
    },
    { skip: !contentId }
  )

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })
  
  useEffect(() => {
    if (!imageUrl || !allBoundingBoxes || bBoxIndex >= allBoundingBoxes.length) {
      return
    }

    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = imageUrl

    img.onload = () => {
      const canvas = canvasRef.current
      if (!canvas) return
      const ctx = canvas.getContext('2d')
      if (!ctx) return

      const bbox = allBoundingBoxes[bBoxIndex]
      if (!bbox) return

      const tag = tags?.tags.find((t) => t.id === bbox.name)
      const tagColor = tag ? `#${tag?.color.toString(16).padStart(6, '0').slice(0, 6)}`
        : 'rgba(255, 0, 0, 0.8)'
      // Calculate the center and dimensions of the bounding box
      const bboxCenterX = (bbox.xmin + bbox.xmax) / 2
      const bboxCenterY = (bbox.ymin + bbox.ymax) / 2
      const bboxWidth = bbox.xmax - bbox.xmin
      const bboxHeight = bbox.ymax - bbox.ymin

      // Calculate the zoom factor needed to make the bounding box content fit the canvas
      // Add some padding (20%) around the bounding box
      const padding = 1.2
      const zoomX = canvas.width / (bboxWidth * padding)
      const zoomY = canvas.height / (bboxHeight * padding)
      const zoom = Math.min(zoomX, zoomY)

      // Calculate the translation needed to center the bounding box
      const translateX = (canvas.width / 2) - (bboxCenterX * zoom)
      const translateY = (canvas.height / 2) - (bboxCenterY * zoom)

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Apply transformations
      ctx.save()
      ctx.translate(translateX, translateY)
      ctx.scale(zoom, zoom)

      // Draw the full image
      ctx.drawImage(img, 0, 0)

      // Draw bounding box
      ctx.strokeStyle =tagColor
      ctx.lineWidth = 2 / zoom // Adjust line width for zoom
      ctx.strokeRect(
        bbox.xmin,
        bbox.ymin,
        bboxWidth,
        bboxHeight
      )

      // Draw label with class name and confidence
      if (tag || bbox.confidence !== undefined) {
        const confidence = bbox.confidence !== undefined ? `${(bbox.confidence * 100).toFixed(1)}%` : ''
        const className = tag?.name || ''
        const label = [className, confidence].filter(Boolean).join(' - ')

        const fontSize = 12 / zoom
        ctx.font = `${fontSize}px Arial`
        ctx.fillStyle = tagColor
        
        const textWidth = ctx.measureText(label).width
        const labelHeight = fontSize + 8 / zoom

        // Draw label background
        ctx.fillRect(
          bbox.xmin,
          bbox.ymin - labelHeight,
          textWidth + 8 / zoom,
          labelHeight
        )

        // Draw label text
        ctx.fillStyle = 'white'
        ctx.fillText(
          label,
          bbox.xmin + 4 / zoom,
          bbox.ymin - 4 / zoom
        )
      }

      ctx.restore()
    }
  }, [imageUrl, allBoundingBoxes, bBoxIndex, tags?.tags])

  return (
    <div className="relative w-64 h-64">
      <canvas
        ref={canvasRef}
        width={256}
        height={256}
        className="w-full h-full"
      />
    </div>
  )
}

export default AnnotationSnippet