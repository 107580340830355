import React from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import { useAppSelector } from '../store/hooks'
import 'chart.js/auto'
import PillsDataset from './PillsDataset'
import {selectSelectedModel} from '../store/slices/modelSlice'

interface TrainingValidationSetsCardProps {
  title?: string
  showButtons?: boolean
}

const TrainingValidationSetsCard: React.FC<TrainingValidationSetsCardProps> = ({
  title = 'Training and Validation Datasources'
}) => {

  const model = useAppSelector(selectSelectedModel)

  return (
    <Card className="class-distribution-card mt-3 shadow-sm">
      <Card.Header className="d-flex justify-content-between align-items-center text-white p-3">
        <h5 className="mb-0 card-title">{title}</h5>
      </Card.Header>
      <Card.Body>

        {/* Training Datasets */}
        <Row>
          <Col>
            <strong>Training:</strong>
          </Col>
          <Col>
            <PillsDataset datasetNames={model?.parameters?.train_metadata?.datasource || []} tiny={false}/>
          </Col>
        </Row>

        {/* Validation Datasets */}
        <Row>
          <Col>
            <strong>Validation:</strong>
          </Col>
          <Col>
            <PillsDataset datasetNames={model?.parameters?.validation_metadata?.datasource || []} tiny={false}/>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  )
}

export default TrainingValidationSetsCard
