import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import AnnotationSnippet from '../components/annotation/AnnotationSnippet'
import {Check, X} from 'lucide-react'
import {
  addAcceptedBoundingBoxByIndex,
  addRejectedBoundingBoxByIndex,
  pushUndoStackItem,
  selectAcceptedBoundingBoxIndexes,
  selectAllBoundingBoxes,
  selectRejectedBoundingBoxIndexes
} from '../store/slices/annotationSlice'
import {useAppDispatch, useAppSelector} from '../store/hooks'

interface SnippetWithOverlayProps {
  contentId: string;
  index: number;
  selectedSnippetIndex: number | undefined;
  setSelectedSnippetIndex: (index: number | undefined) => void;
  showDelete?: boolean
  showAccept?: boolean
  glow?: boolean
}

interface StyleProps {
  width: string;
  height: string;
  border: string;
  cursor: string;
}

const SnippetWithOverlay: React.FC<SnippetWithOverlayProps> = ({
  contentId,
  index,
  selectedSnippetIndex,
  setSelectedSnippetIndex,
  showDelete = true,
  showAccept = true,
  glow = false,
}) => {
  const dispatch = useAppDispatch()
  const acceptedBBoxes = useAppSelector(selectAcceptedBoundingBoxIndexes)
  const rejectedBBoxes = useAppSelector(selectRejectedBoundingBoxIndexes)
  const allBBoxes = useAppSelector(selectAllBoundingBoxes)

  const handleClick = (): void => {
    index === selectedSnippetIndex
      ? setSelectedSnippetIndex(undefined)
      : setSelectedSnippetIndex(index)
  }

  const handleAccept = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    dispatch(pushUndoStackItem({all: allBBoxes, rejected: [...rejectedBBoxes], accepted: [...acceptedBBoxes]}))
    dispatch(addAcceptedBoundingBoxByIndex(index))
  }

  const handleReject = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    dispatch(pushUndoStackItem({all: allBBoxes, rejected: [...rejectedBBoxes], accepted: [...acceptedBBoxes]}))
    dispatch(addRejectedBoundingBoxByIndex(index))
  }

  return (
    <Card
      className="relative w-64"
      style={{
        backgroundColor: '#1a1a1a',
        border: 'none',
        cursor: 'pointer',
        boxShadow: glow ? '0 0px 50px #800085' : 'none',

      }}
      onClick={handleClick}
    >
      <Row className="align-items-center justify-content-center d-flex pb-2">
        <Col xs={6}>
          {showDelete && (
            <Button style={{ width: '100%' }} variant="danger" onClick={handleReject} aria-label="Reject">
              <X size={10} />
            </Button>
          )}
        </Col>
        <Col xs={6}>
          {showAccept && (
            <Button style={{ width: '100%' }} variant="success" onClick={handleAccept} aria-label="Accept">
              <Check size={10} />
            </Button>
          )}
        </Col>
      </Row>
      <AnnotationSnippet contentId={contentId} bBoxIndex={index} />
    </Card>
  )
}

export default SnippetWithOverlay
