// src/services/ollamaApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import {setAIResponse} from '../store/slices/projectSlice'
import {store} from '../store'
import baseQueryWithReauth from './baseQuery'
import {OLLAMA_URL} from '../constants'

export enum Model {
  LLAMA = 'llama',
  LLAVA = 'llava',
}

export interface OllamaRequest {
  prompt: string;
  model_type: Model;
  files?: File[];
}

export const ollamaApi = createApi({
  reducerPath: 'ollamaApi',
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    generateResponse: builder.mutation<string, OllamaRequest>({
      query: (params) => {
        // For llama model (text-only)
        if (params.model_type === Model.LLAMA) {
          return {
            url: OLLAMA_URL,
            method: 'POST',
            body: {
              prompt: params.prompt,
              model_type: Model.LLAMA,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        }

        // For llava model (with images)
        const formData = new FormData()
        formData.append('prompt', params.prompt)
        formData.append('model_type', Model.LLAVA)
        if (params.files) {
          params.files.forEach((file) => {
            formData.append('files', file)
          })
        }

        return {
          url: OLLAMA_URL,
          method: 'POST',
          body: formData,
          formData: true, // Signal that we're sending FormData
        }
      },
      transformResponse: (response: string) => {
        // store.dispatch(clearAIResponses())
        store.dispatch(setAIResponse([response]))
        return response
      },
    }),
  }),
})

export const { useGenerateResponseMutation } = ollamaApi
