// Path: src/components/RealTimeImageCard.tsx
import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { InferenceResult, PredictionMetadata } from '../types/prediction'
import { useGetTagsQuery } from '../services/tagApi'
import { useAppSelector } from '../store/hooks'
import { selectSelectedProject } from '../store/slices/projectSlice'
import { AnnotationDataBoundingBox } from '../types/annotation'
import SuggestiveLabelImageCard from './annotation/SuggestiveLabelImageCard'
import { normalizeBoundingBox } from '../utils'
import PillsBBox from './PillsBBox'
import PillsConfidence from './PillsConfidence'

interface RealTimeImageProps {
  result: InferenceResult
  imageData: {
    url: string
    width: number
    height: number
  }
  cardSize?: number
}

const RealTimeImage: React.FC<RealTimeImageProps> = ({
  result,
  imageData,
  cardSize = 512
}) => {
  const project = useAppSelector(selectSelectedProject)
  const [imageBoundingBoxes, setImageBoundingBoxes] = useState<AnnotationDataBoundingBox[]>([])

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || ''
  })

  useEffect(() => {
    const bboxes = result.predictions.map((prediction: PredictionMetadata) => {
      return normalizeBoundingBox({
        class_name: prediction.class_name,
        xmin: prediction.bounding_boxes?.xmin || 0,
        xmax: prediction.bounding_boxes?.xmax || 0,
        ymin: prediction.bounding_boxes?.ymin || 0,
        ymax: prediction.bounding_boxes?.ymax || 0,
        name: prediction.tagid
      } as AnnotationDataBoundingBox)
    })
    setImageBoundingBoxes(bboxes)
  }, [result])

  return (
    <div
      className="image-container"
    >
      <div style={{padding: '5px'}}>
        <Card
          bg="bg-light"
          text="light"
          style={{
            height: `${cardSize}px`,
            width: `${cardSize}px`,
            position: 'relative'
          }}
        >
          <SuggestiveLabelImageCard
            base64Data={imageData.url}
            bounding_boxes={imageBoundingBoxes}
            cardWidth={cardSize}
            cardHeight={cardSize}
            contentWidth={imageData.width}
            contentHeight={imageData.height}
          />

          {project?.annotation_type === 'bounding_box' && (
            <div style={{position: 'absolute', bottom: '10px', left: '10px', zIndex: 1}}>
              <PillsBBox
                bounding_boxes={result.predictions?.map((p, index) => ({
                  ...(p.bounding_boxes || {}),
                  name: p.class_name,
                  confidence: p.confidence,
                  class_index: index
                } as AnnotationDataBoundingBox))}
              />
            </div>
          )}

          <div style={{position: 'absolute', top: '10px', left: '10px', zIndex: 1}}>
            <PillsConfidence
              confidences={result.predictions?.map(p => p.confidence)}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default RealTimeImage
