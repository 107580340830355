import React, {useEffect, useMemo, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Row, Col, Button, Card, Tabs, Tab } from 'react-bootstrap'
import ImageAnnotationComponentV2 from '../components/annotation/ImageAnnotationComponentV2'

import { useCreateAnnotationMutation } from '../services/annotationApi'
import {contentApi, useFetchAnnotatedContentQuery} from '../services/contentApi'
import { projectsApi } from '../services/projectsApi'
import {
  selectFilteredBoundingBoxesNew,
  clearAllBoundingBoxes,
  setAllBoundingBoxes,
  selectAllBoundingBoxes,
  selectSelectedContentId,
  selectUnannotatedPage,
  selectAnnotatedPage,
  selectEditingContent,
  setEditingContent,
  setSelectedContentId,
  clearInitialBoundingBoxes,
  setInitialBoundingBoxes,
  setRejectedBoundingBoxes,
  setAcceptedBoundingBoxes,
  selectRejectedBoundingBoxes, selectAcceptedBoundingBoxes, selectAllUntouchedBoundingBoxes,
} from '../store/slices/annotationSlice'
import { CreateBBoxAnnotationReq } from '../types/requests'
import {selectActiveTab, selectActiveView, selectFilterTags, selectSelectedProject} from '../store/slices/projectSlice'
import { RootState } from '../store'
import { selectSelectedModel } from '../store/slices/modelSlice'
import { AnnotationDataBoundingBox } from '../types/annotation'
import { useGetTagsQuery } from '../services/tagApi'
import {selectDatasetSources} from '../store/slices/datasetSlice'
import SnippetWithOverlay from '../components/SnippetWithOverlay'

const AnnotationsReview: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const filteredBoundingBoxes = useAppSelector(selectFilteredBoundingBoxesNew)

  const [createAnnotation] = useCreateAnnotationMutation()

  const project = useAppSelector(selectSelectedProject)
  const selectedModel = useAppSelector(selectSelectedModel)

  const selectedPredictions = useAppSelector((state: RootState) => state.prediction.selectedPredictions)
  const selectedFilterTags = useAppSelector(selectFilterTags)

  const rejectedBBoxes = useAppSelector(selectRejectedBoundingBoxes)
  const allUntouchedBBoxes = useAppSelector(selectAllUntouchedBoundingBoxes)

  const activeView = useAppSelector(selectActiveView)
  const activeTab = useAppSelector(selectActiveTab)
  const selectedContentId = useAppSelector(selectSelectedContentId)

  const annotatedPage = useAppSelector(selectAnnotatedPage)
  const unAnnotatedPage = useAppSelector(selectUnannotatedPage)

  const datasetSources = useAppSelector(selectDatasetSources)
  const editingContent = useAppSelector(selectEditingContent)

  const [snippetGlowIndex, setSnippetGlowIndex] = useState<number | undefined>(0)

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const [queryDatasets, setQueryDatasets] = useState<string[] | undefined>(undefined)
  const [selectedSnippetIndex, setSelectedSnippetIndex] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (datasetSources && datasetSources.length > 0) {
      setQueryDatasets(datasetSources)
    } else {
      setQueryDatasets(undefined)
    }
    dispatch(
      contentApi.util.invalidateTags([
        { type: 'Content', id: 'LIST' },
        { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
        { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
      ]),
    )
  }, [dispatch, datasetSources])

  // Memoized Values

  const annotatedContentArgs = useMemo(() => ({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
    limit: 100,
    page: annotatedPage - 1,
    metadata_filter: { datasource: queryDatasets },
    sort_key: 'updated_at',
    sort_val: -1
  }), [project?.id, project?.datasetid, annotatedPage, queryDatasets])

  const unannotatedContentArgs = useMemo(() => ({
    project_id: project?.id || '',
    dataset_id: undefined,
    limit: 100,
    page: unAnnotatedPage - 1,
    metadata_filter: { datasource: queryDatasets },
    sort_key: 'updated_at',
    sort_val: -1
  }), [project?.id, unAnnotatedPage, queryDatasets])

  const annotatedContentQuery = useMemo(() => ({
    skip: activeTab !== 'annotated',
  }), [activeTab])

  const unannotatedContentQuery = useMemo(() => ({
    skip: activeTab !== 'unannotated',
  }), [activeTab])

  const { data: annotatedContent } = useFetchAnnotatedContentQuery(annotatedContentArgs, annotatedContentQuery)
  const { data: unannotatedContent } = useFetchAnnotatedContentQuery(unannotatedContentArgs, unannotatedContentQuery)

  const filteredContent = useMemo(() => {
    const contentToProcess = activeTab === 'annotated' ? annotatedContent?.content || []: unannotatedContent?.content || []

    if (selectedFilterTags?.length > 0) {
      return contentToProcess?.filter((content) => {
        const contentTagIds = content.annotation?.[0]?.tagids || []
        return contentTagIds?.some(tagId => selectedFilterTags?.map(t => t.id).includes(tagId))
      })
    }
    return contentToProcess

  }, [activeTab, annotatedContent?.content, unannotatedContent?.content, selectedFilterTags])


  useEffect(() => {
    const bBoxes = editingContent?.annotation?.[0]?.metadata?.bounding_boxes?.map((bbox: AnnotationDataBoundingBox, index) => {
      if (project?.annotation_type === 'bounding_box') {
        return {
          name: bbox.name,
          xmin: bbox.xmin,
          ymin: bbox.ymin,
          xmax: bbox.xmax,
          ymax: bbox.ymax,
          confidence: bbox.confidence,
          class_index: index,
        } as AnnotationDataBoundingBox
      } else {
        return {
          name: tags?.tags?.find((t) => t.name === bbox.class_name)?.id,
          xmin: 0,
          ymin: 0,
          xmax: 0,
          ymax: 0,
          confidence: bbox.confidence,
          class_index: index,
        } as AnnotationDataBoundingBox
      }
    })
    dispatch(setAllBoundingBoxes(bBoxes ? [...bBoxes] : []))
    dispatch(setInitialBoundingBoxes(bBoxes ? [...bBoxes] : []))
    dispatch(setRejectedBoundingBoxes([]))
    dispatch(setAcceptedBoundingBoxes([]))
  }, [editingContent, tags, project?.annotation_type, dispatch])


  const handleNextImage = () => {
    const contentIdx = filteredContent?.findIndex((p) => p.id === selectedContentId)
    if (contentIdx !== undefined && contentIdx < ((filteredContent?.length || 1) - 1)) {
      dispatch(clearAllBoundingBoxes())
      dispatch(clearInitialBoundingBoxes())

      if (filteredContent?.[contentIdx + 1]) {
        dispatch(setEditingContent(filteredContent?.[contentIdx + 1]))
        dispatch(setSelectedContentId(filteredContent?.[contentIdx + 1].id))
      }
    }
  }

  const handlePrevImage = () => {
    const contentIdx = filteredContent?.findIndex((p) => p.id === selectedContentId)
    if (contentIdx !== undefined && contentIdx > 0) {
      dispatch(clearAllBoundingBoxes())
      dispatch(clearInitialBoundingBoxes())

      if (filteredContent?.[contentIdx - 1]) {
        dispatch(setEditingContent(filteredContent?.[contentIdx - 1]))
        dispatch(setSelectedContentId(filteredContent?.[contentIdx - 1].id))
      }
    }
  }


  const handleAcceptCurrentAnnotations = async () => {
    if (editingContent) {
      try {
        const annotationTagIds: string[] = Array.from(new Set(filteredBoundingBoxes.map((a) => a.name)))
        // Create annotation request CreateAnnotationReq
        const annoToCreate: CreateBBoxAnnotationReq = {
          content_id: selectedContentId || '',
          dataset_id: project?.datasetid || '',
          metadata: project?.annotation_type === 'bounding_box' ? { bounding_boxes: filteredBoundingBoxes } : undefined,
          project_id: project?.id || '',
          tag_id: annotationTagIds,
          // thumbnailSize: 640,
        }

        const createdAnno = await createAnnotation(annoToCreate)
        // Invalidate the relevant cache to refresh the content
        dispatch(
          contentApi.util.invalidateTags([
            { type: 'AnnotatedContent', id: createdAnno?.data?.contentid },
            { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
            { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
            { type: 'Content', id: createdAnno?.data?.contentid || '' },
          ]),
        )
        dispatch(projectsApi.util.invalidateTags([{ type: 'Project', id: project?.id }]))
      } catch (error) {
        console.error('Failed to create annotation:', error)
      }
      handleNextImage()
    } else {
      for (const prediction of selectedPredictions) {
        try {
          await createAnnotation({
            content_id: prediction.contentid,
            dataset_id: selectedModel?.datasetid || '',
            project_id: selectedModel?.projectid || '',
            tag_id: prediction.predictions.map((p) => p.tagid),
            metadata:
              project?.annotation_type === 'bounding_box'
                ? {
                  bounding_boxes: prediction.predictions.flatMap((p) =>
                    p.bounding_boxes ? Object.values(p.bounding_boxes) : [],
                  ),
                }
                : undefined,
          }).unwrap()
        } catch (error) {
          console.error('Failed to create annotation:', error)
        }
      }
      dispatch(
        contentApi.util.invalidateTags([
          { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
          { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
        ]),
      )
    }
  }


  return (
    <div className="predictions-review">
      <div className="predictions-content">

        <div className="annotation-tab-content">
          <div className="annotorious-container">
            <Row>
              <div className="annotation-controls">
                <Row className="justify-content-between">
                  <Col xs={3} >
                    <Button
                      variant='primary'
                      onClick={() => navigate(-1)}
                    >
                      ← Back to {activeView}
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={handlePrevImage} className="annotation-nav-btn" aria-label="Previous image">
                      <ChevronLeft size={24} />
                    </Button>
                  </Col>
                  <Col className="text-center">
                    <Button variant="success" className="mx-2 mt-1" onClick={handleAcceptCurrentAnnotations}>
                        Submit
                    </Button>
                  </Col>
                  <Col className="text-end">
                    <Button onClick={handleNextImage} className="annotation-nav-btn" aria-label="Next image">
                      <ChevronRight size={24} />
                    </Button>
                  </Col>
                  <Col xs={2} />
                </Row>
              </div>
            </Row>
            <Row>
              <Col xs={9}>
                <Card className="bg-dark d-flex align-items-center justify-content-between" >
                  {editingContent && (
                    <div>
                      <ImageAnnotationComponentV2
                        contentId={selectedContentId}
                        useBBoxes={project?.annotation_type === 'bounding_box'}
                        focusedBBoxIndex={selectedSnippetIndex}
                        setFocusedBBoxIndex={setSelectedSnippetIndex}
                        setSnippetGlowIndex={setSnippetGlowIndex}
                      />
                    </div>
                  )}
                </Card>
              </Col>

              {/* Prediction Result snippets */}
              <Col xs={3} style={{height: '90vh', overflow: 'auto'}}>
                {editingContent && (
                  <div className="h-full flex flex-col">
                    <Card className="bg-dark" style={{ height: '100%', overflow: 'hidden', border: 'none' }}>
                      <Card.Body
                        className="h-[calc(100vh-300px)] overflow-y-auto overflow-x-hidden bg-dark"
                        onWheel={(e) => e.stopPropagation()}
                      >
                        <Tabs defaultActiveKey="inspect" id="annotation-tabs" className="mb-3">
                          <Tab eventKey="inspect" title={`Inspect (${allUntouchedBBoxes.length})`} className="bg-dark">

                            <Row style={{maxHeight: '75vh', overflow: 'scroll'}}>
                              {allUntouchedBBoxes.map((box, index) => (
                                <Col key={index}
                                  style={{ backgroundColor: '#1a1a1a'}}
                                  className="my-3 px-2 py-5 justify-content-center align-items-center d-flex"
                                >
                                  <SnippetWithOverlay
                                    showAccept={false}
                                    key={index}
                                    contentId={selectedContentId}
                                    index={box.class_index}
                                    selectedSnippetIndex={selectedSnippetIndex}
                                    setSelectedSnippetIndex={() => setSelectedSnippetIndex(box.class_index)}
                                    glow={snippetGlowIndex === index}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Tab>

                          <Tab eventKey="rejected" title={`Rejected (${rejectedBBoxes.length})`} className="bg-dark">
                            <Row style={{maxHeight: '75vh', overflow: 'scroll'}}>
                              {rejectedBBoxes?.map((box, index) => (
                                <Col key={index}
                                  style={{ backgroundColor: '#1a1a1a'}}
                                  className="my-3 px-2 py-5 justify-content-center align-items-center d-flex"
                                >
                                  <SnippetWithOverlay
                                    showDelete={false}
                                    key={index}
                                    contentId={selectedContentId}
                                    index={box.class_index}
                                    selectedSnippetIndex={selectedSnippetIndex}
                                    setSelectedSnippetIndex={setSelectedSnippetIndex}
                                    glow={snippetGlowIndex === index}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </Tab>
                          {/*</div>*/}
                        </Tabs>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Col>

              {/*<Col xs={2} style={{ paddingLeft: '10px' }}>*/}
              {/*  {project?.annotation_type === 'bounding_box' && (*/}
              {/*    <BBoxMetadata*/}
              {/*      key={selectedContentId || ''}*/}
              {/*      contentId={selectedContentId || ''}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*  {project?.annotation_type === 'classification' && (*/}
              {/*    <ClassificationMetadata*/}
              {/*      key={selectedContentId || ''}*/}
              {/*      contentId={selectedContentId || ''}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</Col>*/}
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnotationsReview
