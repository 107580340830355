// src/components/wizards/FinalizeStep.tsx
import React from 'react'
import { Row, Col, Button, Alert } from 'react-bootstrap'
import { useProject } from './NewProjectContext'
import FileUploadStats from '../FileUploadStats'
import {
  useUploadProjectContentMutation,
  useUploadTiffMutation
} from '../../services/projectsApi'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectSelectedProject} from '../../store/slices/projectSlice'
import {datasetApi} from '../../services/datasetApi'
import {contentApi} from '../../services/contentApi'
import {annotationsApi} from '../../services/annotationApi'
import {tagApi} from '../../services/tagApi'
import {clearUploadStats, selectDatasetSources, updateDatasetStats} from '../../store/slices/datasetSlice'

interface UploadFinalizeStepProps {
  onHide: () => void
}

const UploadFinalizeStep: React.FC<UploadFinalizeStepProps> = ({ onHide }) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const selectedDataset = useAppSelector(selectDatasetSources)

  const {
    fileMap,
    tiffFileMap,
    selectedFiles,
    selectedTiffFiles,
    uploading,
    progress,
    error,
    setUploading,
    setProgress,
    setError,
    labelsFile,
    datasetName,
    clearFiles,
    datasetSources
  } = useProject()

  const [uploadProjectContent] = useUploadProjectContentMutation()
  const [uploadTiff] = useUploadTiffMutation()

  const uploadFiles = async () => {

    dispatch(clearUploadStats())
    if (project?.id && (datasetName || selectedDataset?.[0])) {
      const dsName = datasetName ? datasetName : selectedDataset[0]

      setUploading(true)
      setProgress(0)
      setError(null)
      const metadata = { datasource: dsName }
      try {
        // Upload TIFF files in batches
        const tiffFilesToUpload = selectedTiffFiles
          .map(meta => tiffFileMap.get(meta.id!))
          .filter(Boolean) as File[]

        const totalTiffFiles = tiffFilesToUpload.length
        const tiffBatchSize = totalTiffFiles < 100 ? 10 : 100
        const tiffBatches = Math.ceil(totalTiffFiles / tiffBatchSize)

        for (let i = 0; i < tiffBatches; i++) {
          const start = i * tiffBatchSize
          const end = Math.min((i + 1) * tiffBatchSize, totalTiffFiles)
          const batchFiles = tiffFilesToUpload.slice(start, end)

          await uploadTiff({
            id: project.id,
            files: batchFiles,
            clip_size: 1024,
            metadata
          })

          setProgress(Math.round(((i + 1) / tiffBatches) * 100))
        }

        // Upload regular files in batches
        const filesToUpload = selectedFiles
          .map(meta => fileMap.get(meta.id))
          .filter(Boolean) as File[]

        const totalFiles = filesToUpload.length
        const batchSize = totalFiles < 100 ? 10 : 100
        const batches = Math.ceil(totalFiles / batchSize)

        for (let i = 0; i < batches; i++) {
          const start = i * batchSize
          const end = Math.min((i + 1) * batchSize, totalFiles)
          const batchFiles = filesToUpload.slice(start, end)

          if (labelsFile) {
            batchFiles.push(labelsFile)
          }

          const resp = await uploadProjectContent({
            id: project.id,
            files: batchFiles,
            labels_file: labelsFile?.name,
            metadata,
          }).unwrap()
          dispatch(updateDatasetStats(resp))

          setProgress(Math.round(((i + 1) / batches) * 100))
          dispatch(annotationsApi.util.invalidateTags([{ type: 'annotation', id: 'LIST' }]))
          dispatch(datasetApi.util.invalidateTags([{type: 'Dataset', id: 'LIST'}]))
          dispatch(
            contentApi.util.invalidateTags([
              { type: 'Content', id: 'LIST' },
              { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
              { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
              { type: 'Metadata', id: 'LIST' }
            ]),
          )
          dispatch(tagApi.util.invalidateTags([{ type: 'Tag', id: 'LIST' }]))
        }
        onHide()
        clearFiles()
      } catch (err) {
        setError('An error occurred during upload. Please try again.')
      } finally {
        setUploading(false)
      }
    }

  }

  return (
    <Row>
      {!uploading && (
        <>
          <Col xs={3} />
          <Col xs={6}>
            <FileUploadStats
              numFilesSelected={fileMap.size + tiffFileMap.size}
              labelsFileName={labelsFile?.name || 'None'}
              datasetSource={datasetName || ''}
            />
          </Col>
          <Col xs={3} />
        </>
      )}

      {uploading && (
        <>
          <Col xs={uploading ? 6 : 3}>
            <FileUploadStats
              numFilesSelected={fileMap.size + tiffFileMap.size}
              labelsFileName={labelsFile?.name || 'None'}
              datasetSource={datasetName || ''}
            />
          </Col>

          <Col xs={6} className="d-flex justify-content-center align-items-center">
            <div className="mt-4">
              <div className="mb-2">
                <span>Uploading files...</span>
                <span className="font-weight-bold">{`${progress}%`}</span>
              </div>
              <div style={{ backgroundColor: '#444', borderRadius: '0.25rem', height: '20px', width: '100%' }}>
                <div
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{
                    width: `${progress}%`,
                    backgroundColor: '#28a745',
                    height: '100%',
                    borderRadius: '0.25rem',
                    transition: 'width 0.6s ease',
                  }}
                />
              </div>
            </div>
          </Col>
        </>
      )}

      {error && (
        <Alert variant="danger" className="mt-4">
          {error}
        </Alert>
      )}

      <Button
        onClick={uploadFiles}
        disabled={fileMap.size + tiffFileMap.size === 0 || uploading || !(datasetName || datasetSources.length > 0) }
        variant="success"
      >
        {uploading ? 'Uploading...' : 'Upload Files'}
      </Button>
    </Row>
  )
}

export default UploadFinalizeStep