// src/components/annotation/Canvas.tsx

import React, { RefObject, useCallback, useEffect } from 'react'
import { drawAnnotations } from './utils/drawing'
import { Tag } from '../../types/tag'
import { AnnotationDataBoundingBox } from '../../types/annotation'
import { MousePosition } from './hooks/useImageAnnotation'
import { useAppSelector } from '../../store/hooks'
import { selectAllBoundingBoxes, selectFilteredBoundingBoxesNew } from '../../store/slices/annotationSlice'

export interface CanvasProps {
  canvasRef: RefObject<HTMLCanvasElement>
  imageRef: RefObject<HTMLImageElement>
  imageUrl: string | undefined
  handleMouseEvent: (
    e: React.MouseEvent<HTMLCanvasElement>,
    eventType: 'down' | 'move' | 'up' | 'click' | 'context',
  ) => void
  handleWheel: (e: React.WheelEvent<HTMLCanvasElement>) => void
  zoomLevel: number
  panOffset: MousePosition
  newBoundingBox: AnnotationDataBoundingBox | null
  selectedBBox: AnnotationDataBoundingBox | null
  onBoxSelect: (box: AnnotationDataBoundingBox | null) => void
  tags: { tags: Tag[] } | undefined
  useBBoxes: boolean
  showBBoxes: boolean
  mousePosition: MousePosition
  contrast: number
  showHeatmap: boolean
  handleDoubleClick: (e: React.MouseEvent<HTMLCanvasElement>) => void
  showConfidence: boolean
  canvasWidth: number //set to 0 to use image size
  canvasHeight: number
  bBoxIndex?: number
}

const Canvas: React.FC<CanvasProps> = ({
  canvasRef,
  imageRef,
  imageUrl,
  handleMouseEvent,
  handleWheel,
  zoomLevel,
  panOffset,
  newBoundingBox,
  selectedBBox,
  onBoxSelect,
  tags,
  useBBoxes,
  mousePosition,
  contrast,
  showHeatmap,
  showBBoxes,
  handleDoubleClick,
  showConfidence,
  bBoxIndex
}) => {
  const filteredBoundingBoxes = useAppSelector(selectFilteredBoundingBoxesNew)
  const allBoundingBoxes = useAppSelector(selectAllBoundingBoxes)

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d', { willReadFrequently: true })
    if (ctx && canvasRef?.current && imageRef?.current && tags?.tags) {
      drawAnnotations(
        ctx,
        canvasRef.current,
        imageRef.current,
        zoomLevel,
        panOffset,
        allBoundingBoxes,
        filteredBoundingBoxes,
        showConfidence,
        tags?.tags,
        useBBoxes,
        showBBoxes,
        newBoundingBox,
        selectedBBox,
        mousePosition,
        contrast,
        showHeatmap,
        bBoxIndex,
      )
    }
  }, [
    canvasRef,
    imageRef,
    zoomLevel,
    panOffset,
    allBoundingBoxes,
    filteredBoundingBoxes,
    newBoundingBox,
    selectedBBox,
    tags,
    useBBoxes,
    mousePosition,
    contrast,
    showHeatmap,
    showBBoxes,
    showConfidence,
    bBoxIndex
  ])

  const getMousePos = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>): MousePosition => {
      if (!canvasRef.current) return { x: 0, y: 0 }

      const rect = canvasRef.current.getBoundingClientRect()
      const scaleX = canvasRef.current.width / rect.width
      const scaleY = canvasRef.current.height / rect.height

      const canvasX = (e.clientX - rect.left) * scaleX
      const canvasY = (e.clientY - rect.top) * scaleY

      const x = (canvasX - canvasRef.current.width / 2) / zoomLevel + canvasRef.current.width / 2 - panOffset.x
      const y = (canvasY - canvasRef.current.height / 2) / zoomLevel + canvasRef.current.height / 2 - panOffset.y

      return { x, y }
    },
    [canvasRef, zoomLevel, panOffset.x, panOffset.y],
  )

  const handleCanvasMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    handleMouseEvent(e, 'down')
    // Check if clicked on a box
    const { x, y } = getMousePos(e)
    const clickedBox = filteredBoundingBoxes.find(
      (box) => x >= box.xmin && x <= box.xmax && y >= box.ymin && y <= box.ymax,
    )

    onBoxSelect(clickedBox || null)
  }

  return (
    <>
      <canvas
        ref={canvasRef}
        width={1024}
        height={1024}
        onMouseDown={handleCanvasMouseDown}
        onMouseMove={(e) => {
          e.preventDefault() // Prevent selection during drag
          handleMouseEvent(e, 'move')
        }}
        onMouseUp={(e) => handleMouseEvent(e, 'up')}
        onClick={(e) => handleMouseEvent(e, 'click')}
        onDoubleClick={handleDoubleClick}
        onWheel={handleWheel}
        onContextMenu={(e) => handleMouseEvent(e, 'context')}
        style={{ objectFit: 'contain', userSelect: 'none' }} // Prevent text selection
      />
      <img
        ref={imageRef}
        src={imageUrl}
        alt="Annotate"
        style={{ display: 'none' }}
        onLoad={() => {
          if (canvasRef.current && imageRef.current) {
            const canvas = canvasRef.current
            // canvas.width = imageRef.current.naturalWidth
            // canvas.height = imageRef.current.naturalHeight
            canvas.width = 1024
            canvas.height = 1024
            const ctx = canvas.getContext('2d')
            if (ctx && tags?.tags !== undefined) {
              drawAnnotations(
                ctx,
                canvas,
                imageRef.current,
                zoomLevel,
                panOffset,
                allBoundingBoxes,
                filteredBoundingBoxes,
                showConfidence,
                tags.tags,
                useBBoxes,
                showBBoxes,
                newBoundingBox,
                selectedBBox,
                mousePosition,
                contrast,
                showHeatmap,
                bBoxIndex
              )
            }
          }
        }}
      />
    </>
  )
}

export default Canvas
