import React, { useState } from 'react'
import {Container, Row, Col, Accordion, Nav} from 'react-bootstrap'
import TopBar from '../components/layout/TopBar'
import MainContent from '../components/layout/MainContent'
import ToolsArea from '../components/layout/ToolsArea'
import { useGetProjectQuery } from '../services/projectsApi'
import { useFetchDatasetQuery } from '../services/datasetApi'
import { useParams } from 'react-router-dom'
import { FileMetadata } from '../types/file'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {
  ActiveView,
  DatasetSubNavItems,
  DatasetSubView,
  InferenceSubView,
  setActiveView,
  selectActiveView,
  InferenceSubNavItems,
  selectActiveDatasetSubView,
  selectActiveInferenceSubView, setActiveDatasetSubView, setActiveInferenceSubView
} from '../store/slices/projectSlice'
import { useFetchModelsQuery } from '../services/modelsApi'
import { Model } from '../types/model'
import ConfirmDialog from '../components/modals/ConfirmDialog'

const ProjectLayout: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const dispatch = useAppDispatch()
  const activeView = useAppSelector(selectActiveView)
  const activeDatasetSubView = useAppSelector(selectActiveDatasetSubView)
  const activeInferenceSubView = useAppSelector(selectActiveInferenceSubView)

  const { data: project } = useGetProjectQuery(projectId || '')
  const { data: dataset } = useFetchDatasetQuery(project?.datasetid || '', { skip: !project?.datasetid })
  const { data: modelsData } = useFetchModelsQuery({ project_id: projectId || '' })

  const [labelsFile, setLabelsFile] = useState<File | null>(null)
  const [labelsFileInfo, setLabelsFileInfo] = useState<FileMetadata | null>(null)
  const [showNoModelsDeployed, setShowNoModelsDeployed] = useState<boolean>(false)
  const [expandedKey, setExpandedKey] = useState<string | null>(null)

  // Add this handler function
  const handleAccordionToggle = (key: string) => {
    setExpandedKey(expandedKey === key ? null : key)
  }
  
  const handleLabelsFileSelect = (file: File | null) => {
    setLabelsFile(file)
    if (file) {
      const fileInfo: FileMetadata = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      } as FileMetadata
      setLabelsFileInfo(fileInfo)
    } else {
      setLabelsFile(null)
    }
  }

  const handleSelectActiveView = (view: ActiveView) => {
    dispatch(setActiveView(view))
  }

  const handleDatasetSubNavSelect = (item: DatasetSubView) => {
    dispatch(setActiveDatasetSubView(item))
    setExpandedKey(null)
  }

  const handleInferenceSubNavSelect = (item: InferenceSubView) => {
    if (['Real Time', 'Suggestive Labeling'].includes(item)) {
      const inService = modelsData?.models?.some((model: Model) => model.deployment.status === 'IN_SERVICE')
      if (!inService) {
        setShowNoModelsDeployed(true)
      }
      dispatch(setActiveInferenceSubView(item))
    } else {
      dispatch(setActiveInferenceSubView(item))
    }
    setExpandedKey(null)
  }

  return (
    <Container fluid className="project-details">

      {project &&
          <TopBar onViewChange={handleSelectActiveView} project={project} />
      }

      <Row className="project-details-content g-0">
        <Col md={3} className="project-sidebar">

          {activeView === 'dataset' && (
            <Accordion className="pb-2 dark-accordion"
              activeKey={expandedKey}
              onSelect={(key) => handleAccordionToggle(key as string)}
            >
              <Accordion.Item eventKey={activeDatasetSubView} className="bg-dark border-secondary">
                <Accordion.Header className="dark-accordion-header">
                  <span className="text-white">{activeDatasetSubView}</span>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <Nav className="flex-column" variant="pills">

                    {DatasetSubNavItems.map((item) => (

                      <Nav.Link
                        key={item}
                        active={activeDatasetSubView === item}
                        onClick={() => handleDatasetSubNavSelect(item)}
                        className="ps-3 text-white-50"
                        style={{ background: '$primary' }}
                      >
                        {item.replace('-', ' ')}
                      </Nav.Link>

                    ))}

                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          
          {activeView === 'inference' && (
            <Accordion className="pb-2 dark-accordion"
              activeKey={expandedKey}
              onSelect={(key) => handleAccordionToggle(key as string)}
            >
              <Accordion.Item eventKey={activeInferenceSubView} className="bg-dark border-secondary">
                <Accordion.Header className="dark-accordion-header">
                  <span className="text-white" >{activeInferenceSubView}</span>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <Nav className="flex-column" variant="pills">

                    {InferenceSubNavItems.map((item) => (

                      <Nav.Link
                        key={item}
                        active={activeInferenceSubView === item}
                        onClick={() => handleInferenceSubNavSelect(item)}
                        className="ps-3 text-white-50"
                      >
                        {item.replace('-',' ')}
                      </Nav.Link>

                    ))}

                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          <ToolsArea
            onLabelsFileSelect={handleLabelsFileSelect}
            labelsFile={labelsFile}
            labelsFileInfo={labelsFileInfo}
          />
        </Col>

        <Col md={9} className="project-main-content">
          {dataset && <MainContent />}
        </Col>

      </Row>

      <ConfirmDialog
        show={showNoModelsDeployed}
        onHide={() => setShowNoModelsDeployed(false)}
        onConfirm={() => {dispatch(setActiveView('models'))}}
        title="Deploy a Model First"
        message="You need to deploy a model before running inference."
        confirmText="Go to Models"
        cancelText="Cancel"
        variant="primary"
      />
    </Container>
  )
}

export default ProjectLayout