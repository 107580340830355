import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { ArrowUp, ArrowDown, CheckLg } from 'react-bootstrap-icons'
import { useAppDispatch, useAppSelector, useClassDistribution } from '../store/hooks'
import '../styles/_mini-class-distribution-card.scss'
import { useSelectTagsByDataset } from '../services/tagApi'
import { selectFilterTags, selectSelectedProject, toggleFilterTag } from '../store/slices/projectSlice'
import { Tag } from '../types/tag'
import {selectDatasetSources} from '../store/slices/datasetSlice'

interface MiniClassDistributionCardProps {
  title?: string
  showButtons?: boolean
}

const getBalanceIcon = (balance: string) => {
  if (balance.includes('balanced')) {
    return <CheckLg size={16} color={'green'} />
  } else if (balance.includes('under')) {
    return <ArrowDown size={16} color={'red'} />
  } else if (balance.includes('over')) {
    return <ArrowUp size={16} color={'orange'} />
  }
}

const MiniClassDistributionCard: React.FC<MiniClassDistributionCardProps> = ({
  title = 'Class Distribution',
  showButtons = true,
}) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)

  const datasetSources = useAppSelector(selectDatasetSources)
  const miniClassDistribution = useClassDistribution(
    project?.id || '',
    project?.datasetid || '',
    false,
    datasetSources
  )

  const tags = useSelectTagsByDataset(project?.datasetid || '')
  const filterTags = useAppSelector(selectFilterTags)

  const handleToggleFilterTag = (tagName: string) => {
    const selectedTag = tags?.find((t) => t.name === tagName)
    if (selectedTag) {
      dispatch(toggleFilterTag(selectedTag))
    }
  }

  const isTagSelected = (tagName: string): boolean => {
    return filterTags.some((tag: Tag) => tag.name === tagName)
  }

  return (
    <Card className="mini-class-distribution-card mt-3 shadow-sm">
      <Card.Title>Class Distribution</Card.Title>
      <Card.Body>
        {showButtons && (
          <Row className="title-row align-items-center">
            <Col>
              <Card.Title className="card-title mb-0">{title}</Card.Title>
            </Col>
            <Col xs="auto">
              <Button size="sm" variant="primary">
                Add Data
              </Button>
            </Col>
          </Row>
        )}
        {miniClassDistribution
          .map((item, index) => (
            <React.Fragment key={item.id}>
              {index === miniClassDistribution.length - 1 && <hr className="divider" />}
              <Row className={`class-item-row align-items-center mb-1 ${isTagSelected(item.name) ? 'selected' : ''}`}>
                <Col xs={2} className="color-circle-col">
                  <div className="color-circle" style={{ backgroundColor: item.color }}></div>
                </Col>
                <Col xs={6} className="class-name" onClick={() => handleToggleFilterTag(item.name)}>
                  {item.name}
                </Col>
                <Col xs={3} className="value">
                  {item.value}
                </Col>
                <Col xs={1} className="balance-icon">
                  {getBalanceIcon(item.balance)}
                </Col>
              </Row>
            </React.Fragment>
          ))}
      </Card.Body>
    </Card>
  )
}

export default MiniClassDistributionCard
