// src/components/annotation/ImageAnnotationComponentV2.tsx

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGetContentImageQuery } from '../../services/contentApi'
import { useGetTagsQuery } from '../../services/tagApi'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {
  clearRedoStack,
  clearUndoStack, selectAllBoundingBoxes,
} from '../../store/slices/annotationSlice'
import Canvas from './Canvas'
import { useImageAnnotation } from './hooks/useImageAnnotation'
import TagSelector from '../tools/TagSelector'
import {
  FaExpandArrowsAlt,
  FaExclamationTriangle,
  FaUndo,
  FaRedo,
  FaSun,
  FaBoxes,
  FaPercentage,
  FaTrash,
  FaEdit,
} from 'react-icons/fa'
import { BiReset } from 'react-icons/bi'
import { SiLinkfire } from 'react-icons/si'
import PillsBBox from '../PillsBBox'
import { selectSelectedProject } from '../../store/slices/projectSlice'
import { calculateZoomForBBox } from './utils/drawing'

interface ImageAnnotationComponentV2Props {
  contentId: string
  useBBoxes: boolean
  focusedBBoxIndex?: number
  setFocusedBBoxIndex: (index: number | undefined) => void
  setSnippetGlowIndex: (index: number | undefined) => void
}

const ImageAnnotationComponentV2: React.FC<ImageAnnotationComponentV2Props> = ({
  contentId,
  useBBoxes,
  focusedBBoxIndex,
  setFocusedBBoxIndex,
  setSnippetGlowIndex
}) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const allBoundingBoxes = useAppSelector(selectAllBoundingBoxes)
  const floatingEditorRef = useRef<HTMLDivElement>(null)

  const { data: imageUrl } = useGetContentImageQuery(
    {
      ContentID: contentId,
      project_id: project?.id || '',
      dataset_id: project?.datasetid || '',
    },
    { skip: !contentId },
  )

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const [showHeatmap, setShowHeatmap] = useState(false)
  const [showBBoxes, setShowBBoxes] = useState(true)
  const [showConfidence, setShowConfidence] = useState(false)

  const {
    canvasRef,
    imageRef,
    handleMouseEvent,
    handleWheel,
    zoomLevel,
    panOffset,
    newBoundingBox,
    setNewBoundingBox,
    handleTagSelect,
    mousePosition,
    showTagSelector,
    setShowTagSelector,
    tagSelectorPosition,
    recenterImage,
    undo,
    redo,
    removeAll,
    reset,
    contrast,
    setContrast,
    selectedBBox,
    handleBoxSelection,
    handleDoubleClick,
    popupState,
    setPopupState,
    handleBoxDelete,
    setZoomLevel,
    setPanOffset,
  } = useImageAnnotation(useBBoxes)

  const handleContrastSlider = useCallback(
    (newContrast: number) => {
      setContrast(newContrast)
    },
    [setContrast],
  )

  const handleHeatmapToggle = useCallback(() => {
    if (!showHeatmap) {
      setShowBBoxes(false)
    } else {
      setShowBBoxes(true)
    }
    setShowHeatmap((prev) => !prev)
  }, [showHeatmap])

  const handleConfidenceToggle = useCallback(() => {
    setShowBBoxes(true)
    setShowConfidence((prev) => !prev)
  }, [])

  const handleCloseTagSelector = useCallback(() => {
    setShowTagSelector(false)
    setNewBoundingBox(null)
  }, [setNewBoundingBox, setShowTagSelector])

  const handleWheelWithReset = useCallback((e: React.WheelEvent<HTMLCanvasElement>) => {
    handleWheel(e)
    setFocusedBBoxIndex?.(undefined)
  }, [handleWheel, setFocusedBBoxIndex])

  useEffect(() => {
    if (focusedBBoxIndex !== undefined && canvasRef.current && allBoundingBoxes[focusedBBoxIndex]) {
      const targetBBox = allBoundingBoxes[focusedBBoxIndex]
      handleBoxSelection(targetBBox)
      const { zoomLevel: newZoom, panOffset: newPan } = calculateZoomForBBox(targetBBox, canvasRef.current)
      console.log('newZoom: ', newZoom)
      setZoomLevel(newZoom)
      setPanOffset(newPan)
    }
    setFocusedBBoxIndex ? setFocusedBBoxIndex(undefined) : null
  }, [canvasRef, focusedBBoxIndex, allBoundingBoxes, setPanOffset, setZoomLevel, setFocusedBBoxIndex, handleBoxSelection])

  useEffect(() => {
    dispatch(clearUndoStack())
    dispatch(clearRedoStack())
    recenterImage()
  }, [recenterImage, contentId, dispatch])

  useEffect(() => {
    if (popupState.show && floatingEditorRef.current) {
      const editor = floatingEditorRef.current
      const rect = editor.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight

      let { x, y } = popupState.position

      // Adjust x-position if the editor goes beyond the right edge of the viewport
      if (x + rect.width > viewportWidth) {
        x = viewportWidth - rect.width
      }

      // Adjust y-position if the editor goes beyond the bottom edge of the viewport
      if (y + rect.height > viewportHeight) {
        y = viewportHeight - rect.height
      }

      // Ensure the editor doesn't go beyond the left or top edge
      x = Math.max(0, x)
      y = Math.max(0, y)

      editor.style.left = `${x}px`
      editor.style.top = `${y}px`
    }
  }, [popupState])

  useEffect(() => {
    if (!selectedBBox) {
      setPopupState({
        show: false,
        position: { x: 0, y: 0 },
        box: null,
      })
    } else {
      setSnippetGlowIndex(selectedBBox.class_index)
    }
  }, [selectedBBox, setFocusedBBoxIndex, setPopupState])

  return (
    <div className="image-annotation-container">
      {/* Controls at the top */}
      <div className="controls-top position-absolute top-0 start-50 translate-middle-x">
        <div className="btn-group" role="group" aria-label="Image controls">

          <button type="button" className="btn btn-secondary" onClick={recenterImage} title="Recenter">
            <FaExpandArrowsAlt size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={undo} title="Undo">
            <FaUndo size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={reset} title="Reset">
            <BiReset size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={removeAll} title="Remove All">
            <FaExclamationTriangle size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={redo} title="Redo">
            <FaRedo size={20} />
          </button>
          {/* Heatmap Toggle Button */}
          <button
            type="button"
            className="btn btn-secondary"
            // style={{background: showHeatmap ? 'red' : ''}}
            onClick={handleHeatmapToggle}
            title="Toggle Heatmap"
          >
            <SiLinkfire size={20} color={showHeatmap ? 'red' : ''} />
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            // style={{background: showBBoxes ? 'green' : ''}}
            onClick={() => setShowBBoxes((prev) => !prev)}
            title="Toggle Heatmap"
          >
            <FaBoxes size={20} color={showBBoxes ? 'green' : ''} />
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleConfidenceToggle}
            title="Toggle Confidence"
          >
            <FaPercentage size={20} color={showConfidence ? 'green' : ''} />
          </button>
        </div>
      </div>

      {/* Canvas Component */}
      <div className="canvas-wrapper" style={{ maxHeight: 'calc(92vh - 20px)' }}>
        <Canvas
          canvasRef={canvasRef}
          imageRef={imageRef}
          imageUrl={imageUrl}
          handleMouseEvent={handleMouseEvent}
          handleWheel={handleWheelWithReset}
          zoomLevel={zoomLevel}
          panOffset={panOffset}
          newBoundingBox={newBoundingBox}
          tags={tags}
          useBBoxes={useBBoxes}
          showBBoxes={showBBoxes}
          mousePosition={mousePosition}
          contrast={contrast}
          selectedBBox={selectedBBox}
          onBoxSelect={handleBoxSelection}
          showHeatmap={showHeatmap}
          handleDoubleClick={handleDoubleClick}
          showConfidence={showConfidence}
          canvasHeight={0}
          canvasWidth={0}
          bBoxIndex={focusedBBoxIndex}
        />
      </div>

      {/* Contrast Slider */}
      <div className="slider-controls" style={{ bottom: '0px' }}>
        <button type="button" className="btn btn-secondary" onClick={() => setContrast(0)} title="Contrast">
          <FaSun size={20} />
        </button>
        <input
          type="range"
          min="-256"
          max="256"
          value={contrast}
          onChange={(e) => handleContrastSlider(parseInt(e.target.value, 10))}
          className="form-range"
          id="contrast-slider"
        />
      </div>

      {/* Tag Selector */}
      {showTagSelector &&  (
        <TagSelector onTagSelect={handleTagSelect} position={tagSelectorPosition} onClose={handleCloseTagSelector} />
      )}

      {/* Floating Editor for tag editing and box deletion */}
      {popupState.show &&  (
        <div
          ref={floatingEditorRef}
          style={{
            position: 'fixed',
            justifyContent: 'space-around',
            zIndex: 1000,
            backgroundColor: '#1a1a1a',
            opacity: '75%',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            maxWidth: '150px',
            width: '100%',
          }}
        >
          <div className="d-flex justify-content-around align-items-center mt-2">
            {selectedBBox && (
              <>
                <PillsBBox bounding_boxes={[selectedBBox]} showTagCounts={false} showConfidence={true} />
              </>
            )}
          </div>
          <div className="d-flex justify-content-around align-items-center my-2">
            <button className="btn btn-danger mt-1 mx-2 w-40" onClick={() => handleBoxDelete(selectedBBox)} style={{ opacity: 1 }}>
              <FaTrash />
            </button>
            <button
              className="btn btn-secondary mt-1 mx-2 w-40"
              onClick={() => setShowTagSelector(true)}
              style={{ opacity: 1 }}
            >
              <FaEdit />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageAnnotationComponentV2
