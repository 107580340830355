import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import Wizard from './Wizard'
import {ProjectProvider} from './NewProjectContext'
import UploadDatasetNameStep from './UploadDatasetNameStep'
import UploadLabelsFileStep from './UploadLabelsFileStep'
import UploadSelectFilesStep from './UploadSelectFilesStep'
import UploadFinalizeStep from './UploadFinalizeStep'
import CreateDatasetNameStep from './CreateDatasetNameStep'
import DatasetSelectorStepTable from './DatasetSelectorStepTable'

interface UploadWizardProps {
  show: boolean
  onHide: () => void
  singleDataset: boolean
}

const UploadWizard: React.FC<UploadWizardProps> = ({ show, onHide, singleDataset }) => {

  const handleCancel = () => {
    onHide()
  }

  const steps = () => {
    const s = [
      {
        name: 'labels.json',
        component: UploadLabelsFileStep,
        title: 'Select your labels file (optional)',
      },

      singleDataset
        ? {
          name: 'Dataset Name',
          component: CreateDatasetNameStep,
          title: 'Dataset Name',
        }
        : {
          name: 'Dataset Name',
          component: DatasetSelectorStepTable,
          title: 'Dataset Name',
        },

      {
        name: 'Select Files',
        component: UploadSelectFilesStep,
        title: 'Select your files',
      },
      {
        name: 'Upload',
        component: UploadFinalizeStep,
        title: 'Press the button to upload your dataset',
      },
    ]
    return s
  }

  return (
    <Modal show={show} onHide={onHide} centered size="xl" className="create-project-modal">
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <Modal.Title>Upload Data</Modal.Title>
        <Button variant="primary" onClick={handleCancel}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <ProjectProvider>
          <Wizard
            steps={steps()}
            stepProps={{ onHide, singleSelect: true }}
          />
        </ProjectProvider>
      </Modal.Body>
    </Modal>
  )
}

export default UploadWizard
