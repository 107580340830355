// Path: /home/jason/src/AgileView/autovision-ui/src/components/views/InferenceGalleryView.tsx

import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import {InferenceResult} from '../../types/prediction'
import RealTimeImage from '../RealTimeImage'

interface ImageData {
  url: string
  filename: string
  width: number
  height: number
  aspectRatio: number
}

interface InferenceGalleryViewProps {
  results: InferenceResult[] | undefined
  dropFiles: File[] | null
  cardSize?: number
  isLoading?: boolean
}

const InferenceGalleryView: React.FC<InferenceGalleryViewProps> = ({
  results,
  dropFiles,
  cardSize = 512,
  isLoading = false
}) => {
  const [images, setImages] = useState<ImageData[]>([])

  useEffect(() => {
    const loadImage = (file: File): Promise<ImageData> => {
      return new Promise((resolve) => {
        const url = URL.createObjectURL(file)
        const img = new Image()

        img.onload = () => {
          resolve({
            url,
            filename: file.name,
            width: img.naturalWidth,
            height: img.naturalHeight,
            aspectRatio: img.naturalWidth / img.naturalHeight
          })
        }

        img.src = url
      })
    }

    const loadImages = async () => {
      if (!dropFiles) return
      const loadedImages = await Promise.all(dropFiles.map(loadImage))
      setImages(loadedImages)
    }

    loadImages()

    return () => {
      images.forEach((image) => URL.revokeObjectURL(image.url))
    }
  }, [dropFiles])

  return (
    <div className="annotation-tab-content">
      <div className="annotorious-container">
        <Row style={{ overflow: 'hidden' }}>
          <Col>
            {isLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : results?.length ? (
              <div className="grid-view" style={{ height: '75vh', overflow: 'auto' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {results.map((result: InferenceResult, index) => {
                    const image = images.find((img) => img.filename === result.filename)
                    if (!image) return null

                    return (
                      <RealTimeImage
                        key={index}
                        result={result}
                        imageData={{
                          url: image.url,
                          width: image.width,
                          height: image.height
                        }}
                        cardSize={cardSize}
                      />
                    )
                  })}
                </div>
              </div>
            ) : (
              <p>No inference results available.</p>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InferenceGalleryView