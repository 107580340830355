import React, { useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import { CloudUpload } from 'lucide-react'
import UploadWizard from './wizards/UploadWizard'

const DatasetUploadToolsCard: React.FC = () => {
  const [showUploadWizard, setShowUploadWizard] = useState(false)

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent click propagation
    setShowUploadWizard(true)
  }

  const handleClose = () => {
    setShowUploadWizard(false)
  }

  return (
    <>
      <Card
        className="gallery-tools mb-2"
        style={{ borderRadius: '10px', cursor: 'pointer' }}
        onClick={handleOpen}
      >
        <Card.Title>Upload Data</Card.Title>
        <Card.Body>
          <Row className="align-items-center justify-content-center">
            <CloudUpload
              className="upload-icon mx-auto mb-4 text-gray-400 text-primary"
              size={64}
              onClick={handleOpen}
            />
          </Row>
        </Card.Body>
      </Card>

      <UploadWizard show={showUploadWizard} onHide={handleClose} singleDataset={false} />
    </>
  )
}

export default DatasetUploadToolsCard
