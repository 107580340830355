// src/components/views/SuggestiveLabelsTabs.tsx

import React, {useEffect, useMemo, useRef, useState} from 'react'
import { Modal, Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { contentApi } from '../../../services/contentApi'
import { useGetModelQuery} from '../../../services/modelsApi'
import {
  useGetAnnotationStatisticsQuery,
} from '../../../services/annotationApi'

import {
  selectFilterTags,
  selectSelectedProject,

} from '../../../store/slices/projectSlice'

import PredictionsGalleryView from '../PredictionsGalleryView'
import { selectDatasetSources } from '../../../store/slices/datasetSlice'
import ModelAssistedLabeling from '../../tools/ModelAssistedLabeling'
import {Prediction, PredictionStatisticsBody, QueryPredictionRequest} from '../../../types/prediction'
import {useGetPredictionStatisticsQuery, useQueryPredictionsQuery} from '../../../services/predictionApi'
import {selectSelectedModel, setSelectedModel} from '../../../store/slices/modelSlice'
import ImageGalleryHeader from './ImageGalleryHeader'
import {Model} from '../../../types/model'
import {selectPredictionsPage, setPredictionsPage} from '../../../store/slices/annotationSlice'
import {useGetTagsQuery} from '../../../services/tagApi'
import {getContrastYIQ} from '../../../utils'

interface InferenceBatchSubViewProps {
  itemsPerPage: number
  includeModels?: boolean
  selectable?: boolean
}

const InferenceBatchSubView: React.FC<InferenceBatchSubViewProps> = ({ itemsPerPage = 12 }) => {
  const project = useAppSelector(selectSelectedProject)
  const dispatch = useAppDispatch()
  const selectedFilterTags = useAppSelector(selectFilterTags)
  const datasetSources = useAppSelector(selectDatasetSources)
  const selectedModel = useAppSelector(selectSelectedModel)
  const page = useAppSelector(selectPredictionsPage)

  // const [page, setPage] = useState(1)

  const [cardSize, setCardSize] = useState(400)
  const [showModelModal, setShowModelModal] = useState(false)
  const [queryDatasets, setQueryDatasets] = useState<string[] | undefined>(undefined)

  // API Hooks
  const { data: modelData, refetch: refetchModel } = useGetModelQuery(selectedModel?.id || '', { skip: !selectedModel })

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (modelData) {
      pollingIntervalRef.current = setInterval(() => {
        refetchModel().then((res) => {
          dispatch(setSelectedModel(res.data as Model))
        }).catch((err) => {
          console.log(err)
        })
      }, 1500) // Poll every 5 seconds
    } else if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
        pollingIntervalRef.current = null
      }
    }
  }, [dispatch, modelData, refetchModel])


  const queryParams: QueryPredictionRequest = useMemo(() => ({
    query: {
      filters: [
        { key: 'modelid', value: selectedModel?.id || '' },
        ...(datasetSources?.[0]
          ? [{ key: 'datasource', value: datasetSources[0], metadata_filter: {enable: true} }]
          : []
        )
      ],
      sort_key: 'contentid',
      sort_val: -1,
      page: page - 1,
      operator: 'and',
      limit: itemsPerPage,
    },
    filter_annotated_content: {
      enable: true,
      projectid: project?.id || '',
      datasetid: project?.datasetid || ''
    }
  }), [datasetSources, itemsPerPage, page, project?.datasetid, project?.id, selectedModel?.id])

  const {data: allPredictions, isLoading: isPredictionsLoading } = useQueryPredictionsQuery(queryParams)

  const { data: predictionStats, isLoading, error } = useGetPredictionStatisticsQuery({
    model_id: selectedModel?.id || '',
    uncertainty_threshold: 0.01,
  })

  // Memoized Values
  const filteredContent = useMemo(() => {
    const contentToProcess = allPredictions?.predictions || []

    let filteredForTagIds: Prediction[] | undefined
    if (selectedFilterTags?.length > 0) {
      filteredForTagIds = contentToProcess?.filter((prediction) => {
        const contentTagIds = prediction.predictions?.map((p) => p.tagid) || []
        return contentTagIds?.some(tagId => selectedFilterTags?.map(t => t.id).includes(tagId))
      })
    } else {
      filteredForTagIds = contentToProcess
    }

    return filteredForTagIds
  }, [allPredictions, selectedFilterTags])


  useEffect(() => {
    if (datasetSources && datasetSources.length > 0) {
      setQueryDatasets(datasetSources)
    } else {
      setQueryDatasets(undefined)
    }
    dispatch(
      contentApi.util.invalidateTags([
        { type: 'Content', id: 'LIST' },
        { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
        { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
      ]),
    )
  }, [dispatch, datasetSources])

  const { data: annoStats } = useGetAnnotationStatisticsQuery({
    projectId: project?.id || '',
    datasetId: project?.datasetid || '',
    metadata_filter: queryDatasets && queryDatasets?.length > 0 ? { datasource: queryDatasets } : undefined
  })


  // Event Handlers
  const handlePageChange = (pageNumber: number) => {
    const numPredictions = allPredictions?.predictions?.length || 0
    const newPage = pageNumber >= 0 && pageNumber < Math.trunc( numPredictions / itemsPerPage) + 1
      ? pageNumber
      : Math.trunc(numPredictions / itemsPerPage) + 1
    dispatch(setPredictionsPage(newPage))
  }


  const handleShowModelModal = (showModelModal: boolean) => {
    setShowModelModal(showModelModal)
  }


  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  return (
    <div className="image-view-container">
      <div className="annotation-tabs">
        <div>
          <Row className="p-1">
            {predictionStats?.Body && (
              <>
                <Col xs={2}>
                  <div>
                    <div
                      style={{
                        userSelect: 'none',
                        // backgroundColor: '#800085',
                        color: 'white',
                        padding: '0.25rem 0.5rem',
                        height: '1.2rem',
                        borderRadius: '9999px',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span>Total Predictions</span>
                      <span
                        style={{
                          padding: '0.25rem 0.5rem',
                          userSelect: 'none',
                          backgroundColor: 'white',
                          color: '#800085',
                          borderRadius: '9999px',
                          maxWidth: '2rem',
                          height: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginLeft: '0.25rem',
                          fontSize: '0.7rem',
                        }}
                      >
                        {predictionStats?.Body?.total_predictions}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col>
                  <Row>
                    {predictionStats?.Body?.predictions_per_class &&
                      [...predictionStats.Body.predictions_per_class]
                        .sort((s1, s2) => s1.count - s2.count)
                        .map((stat: PredictionStatisticsBody, index) => {
                          const tag = tags?.tags.find((t) => t.name === stat.classname)
                          const backgroundColor = `#${tag?.color.toString(16).padStart(6, '0').slice(0, 6)}`
                          const textColor = getContrastYIQ(backgroundColor)

                          return (
                            <div
                              key={index}
                              style={{
                                userSelect: 'none',
                                backgroundColor,
                                color: textColor,
                                padding: '0.25rem 0.5rem',
                                height: '1.2rem',
                                maxWidth: '8rem',
                                borderRadius: '9999px',
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span key={`tag-${index}`}>{tag?.name || 'unk'}</span>
                              <span
                                key={`span-${index}`}
                                style={{
                                  padding: '0.25rem 0.5rem',
                                  userSelect: 'none',
                                  backgroundColor: textColor,
                                  color: backgroundColor,
                                  borderRadius: '9999px',
                                  maxWidth: '2rem',
                                  height: '1rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginLeft: '0.25rem',
                                  fontSize: '0.7rem',
                                }}
                              >
                                {stat.count}
                              </span>
                            </div>
                          )
                        })}
                  </Row>
                </Col>
              </>
            )}
          </Row>

          <Row>
            <Col sm={3}>
              <OverlayTrigger
                placement="top-end"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Deploy your model to launch a new batch</Tooltip>}
              >
                <Button
                  disabled={selectedModel?.deployment.status !== 'IN_SERVICE'}
                  style={{
                    padding: '10px',
                    marginTop: '15px',
                    marginLeft: '30px',
                    border: 'none',
                    color: 'white',
                  }}
                  onClick={() => handleShowModelModal(true)}
                >
                  Label
                </Button>
              </OverlayTrigger>
            </Col>
            <Col>
              <ImageGalleryHeader
                currentPage={page}
                totalImages={annoStats?.statistics.total_unannotated_images || 0}
                itemsPerPage={itemsPerPage}
                cardSize={cardSize}
                onPageChange={handlePageChange}
                onCardSizeChange={setCardSize}
              />
            </Col>

            {!!selectedModel?.batch?.status && !selectedModel?.batch?.status?.toLowerCase().startsWith('complete') && (
              <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                <Col className="text-center">
                  <div
                    className="spinner-border text-primary"
                    style={{ width: '3rem', height: '3rem' }}
                    role="status"
                  />
                  <p className="mt-3 fs-5 fw-bold text-primary">Batch {selectedModel?.batch.status}</p>
                </Col>
              </Row>
            )}
          </Row>
          <Row>
            {selectedModel?.batch?.status?.toLowerCase().startsWith('complete') && (
              <PredictionsGalleryView
                filteredPredictions={filteredContent}
                arePredictionsLoading={isPredictionsLoading}
                page={page}
                cardSize={cardSize}
                itemsPerPage={100}
              />
            )}
          </Row>
        </div>
      </div>

      {/* Model Assisted Labeling Modal*/}
      <Modal show={showModelModal} onHide={() => setShowModelModal(false)} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: '#202020' }}>
          <Modal.Title style={{ backgroundColor: '#202020' }}>Available Models</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#202020' }}>
          <ModelAssistedLabeling onHide={() => setShowModelModal(false)} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default InferenceBatchSubView
