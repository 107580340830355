
import React from 'react'
import { useAppSelector } from '../../store/hooks'
import { selectActiveDatasetSubView } from '../../store/slices/projectSlice'
import DatasetOverviewSubView from './subviews/DatasetOverviewSubView'
import AnnotationsSubView from './subviews/AnnotationsSubView'

const DatasetView: React.FC = () => {
  const activeDatasetSubView = useAppSelector(selectActiveDatasetSubView)

  switch (activeDatasetSubView) {
    case 'Overview':
      return (<DatasetOverviewSubView />)
    case 'Manage':
      return (<AnnotationsSubView itemsPerPage={100} selectable={true} allowAnnotate={false} />)
    case 'Annotations':
      return (<AnnotationsSubView itemsPerPage={100} selectable={false} />)
    case 'Export':
      return (<></>)
    default:
      return null
  }
}

export default DatasetView
