// Path: /home/jason/src/AgileView/autovision-ui/src/components/views/InferenceRealTimeSubView.tsx

import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import {Card, Row, Col, Alert, Button} from 'react-bootstrap'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import {useAppDispatch, useAppSelector} from '../../../store/hooks'
import {selectSelectedModel, setSelectedModel} from '../../../store/slices/modelSlice'
import FileUploader from '../../FileUploader'
import {modelsApi, useGetModelQuery, useRunRealtimeInferenceMutation} from '../../../services/modelsApi'
import {Model} from '../../../types/model'
import {RunInferenceResponse} from '../../../types/prediction'
import {getContrastYIQ} from '../../../utils'
import {useGetTagsQuery} from '../../../services/tagApi'
import InferenceGalleryView from '../InferenceGalleryView'

const InferenceRealTimeSubView: React.FC = () => {
  const dispatch = useAppDispatch()
  const selectedModel = useAppSelector(selectSelectedModel)

  const { data: modelData, refetch: refetchModel } = useGetModelQuery(selectedModel?.id || '', { skip: !selectedModel })
  const [cardSize, setCardSize] = useState<number>(400)

  useEffect(() => {
    // dispatch(setSelectedModel(modelData || undefined))
    dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id: 'LIST' }]))
  }, [dispatch, modelData])

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (modelData) {
      pollingIntervalRef.current = setInterval(() => {
        refetchModel().then((res) => {
          dispatch(setSelectedModel(res.data as Model))
        }).catch((err) => {
          console.log(err)
        })
      }, 5000) // Poll every 5 seconds
    } else if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
        pollingIntervalRef.current = null
      }
    }
  }, [dispatch, modelData, refetchModel])


  const [dropFiles, setDropFiles] = useState<File[] | null>(null)
  const [inferenceResults, setInferenceResults] = useState<RunInferenceResponse | null>(null)
  const [inferenceErrorMsg, setInferenceError] = useState<string | undefined>(undefined)
  const [runInference, { isLoading: inferenceLoading, isError }] = useRunRealtimeInferenceMutation()
  const [isDeployed, setIsDeployed] = useState<boolean>(false)


  const { data: tags } = useGetTagsQuery({
    project_id: selectedModel?.projectid || '',
    dataset_id: selectedModel?.datasetid || '',
  })

  const handleInference = useCallback((acceptedFiles: File[]) => {
    console.log('handling inference')
    if (acceptedFiles) {
      console.log('# inference files: ', acceptedFiles.length)
      setInferenceResults(null)
      setDropFiles(acceptedFiles)
      const formData = new FormData()
      acceptedFiles.forEach((df) => {
        formData.append('files', df, df.name)
      })

      try {
        runInference({ id: selectedModel?.id || '', data: formData, heatmap: true })
        // .unwrap()
          .then((result) => {
            setInferenceResults(result.data || null)
          })
          .catch((err) => {
            console.log('Realtime error: ', err)
          })
      } catch (error: unknown) {
        let msg: string
        if (isFetchBaseQueryError(error)) {
          if ('data' in error && error.data) {
            msg = `Inference error: ${Object.values(error.data)[0]}`
          } else {
            msg = `Inference error: ${error.status}`
          }
        } else {
          msg = `Unexpected inference error: ${error}`
        }
        setInferenceError(msg)
      }
    }
  }, [selectedModel, runInference])


  useEffect(() => {
    setIsDeployed(modelData?.deployment.status === 'IN_SERVICE')
  }, [modelData])

  // Helper function to type-check FetchBaseQueryError
  function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
  }

  const inferenceStats = useMemo(() => {
    if (!inferenceResults) return null

    const stats = inferenceResults.reduce((acc, result) => {
      result.predictions.forEach(pred => {
        if (!acc.predictions[pred.class_name]) {
          acc.predictions[pred.class_name] = { classname: pred.class_name, count: 0 }
        }
        acc.predictions[pred.class_name].count++
        acc.total++
      })
      return acc
    }, {
      predictions: {} as Record<string, { classname: string; count: number }>,
      total: 0
    })

    return {
      predictions_per_class: Object.values(stats.predictions),
      total_predictions: stats.total
    }
  }, [inferenceResults])


  if (!modelData) {
    return <div>No model data available.</div>
  }

  return (
    <div className="model-details-content">
      <div className="model-details-metadata mb-4">
        <div className="model-metrics-card">
          {/* Deployment Button and Class Distribution */}
          <Row className="pb-4">
            <>
              <Col xs={2}>
                <Button disabled={!inferenceResults} onClick={() => {setInferenceResults(null)}}>Reset Inference</Button>
              </Col>
              <Col xs={2} hidden={!inferenceResults}>
                <div
                  style={{
                    userSelect: 'none',
                    // backgroundColor: '#800085',
                    color: 'white',
                    padding: '0.25rem 0.5rem',
                    height: '1.2rem',
                    borderRadius: '9999px',
                    fontSize: '0.75rem',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>Total Predictions</span>
                  <span

                    style={{
                      padding: '0.25rem 0.5rem',
                      userSelect: 'none',
                      backgroundColor: 'white',
                      color: '#800085',
                      borderRadius: '9999px',
                      maxWidth: '2rem',
                      height: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '0.25rem',
                      fontSize: '0.7rem',
                    }}
                  >
                    {inferenceStats?.total_predictions}
                  </span>
                </div>
              </Col>

              {inferenceStats && inferenceStats.predictions_per_class
                .map((stat, index) => {

                  const tag = tags?.tags.find((t) => t.name === stat.classname)
                  const backgroundColor = `#${tag?.color.toString(16).padStart(6, '0').slice(0, 6)}`
                  const textColor = getContrastYIQ(backgroundColor)

                  return (
                    <Col key={index} xs={1}>
                      <div>
                        <div
                          style={{
                            userSelect: 'none',
                            backgroundColor,
                            color: textColor,
                            padding: '0.25rem 0.5rem',
                            height: '1.2rem',
                            borderRadius: '9999px',
                            fontSize: '0.75rem',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span key={`tag-${index}`}>{tag?.name || 'unk'}</span>
                          <span
                            key={`span-${index}`}
                            style={{
                              padding: '0.25rem 0.5rem',
                              userSelect: 'none',
                              backgroundColor: textColor,
                              color: backgroundColor,
                              borderRadius: '9999px',
                              maxWidth: '2rem',
                              height: '1rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginLeft: '0.25rem',
                              fontSize: '0.7rem',
                            }}
                          >
                            {stat.count}
                          </span>
                        </div>
                      </div>
                    </Col>
                  )}
                )
              }
            </>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card className="mb-4">
                <Card.Body>
                  {inferenceResults && (
                    // <InferenceResults results={inferenceResults} dropFiles={dropFiles} />
                    <InferenceGalleryView
                      results={inferenceResults}
                      dropFiles={dropFiles}
                      cardSize={cardSize}
                    />
                  )}
                  {isDeployed && !inferenceResults && (
                    <>
                      <div className="file-upload w-full max-w-md mx-auto">
                        <div className="bg-gray-900 rounded-lg p-6">
                          <FileUploader onFileSelect={handleInference} uploading={inferenceLoading} numFiles={dropFiles?.length || 0} />
                        </div>
                      </div>
                    </>
                  )}

                  {isError && (
                    <Alert variant="danger" className="mt-3">
                      Error occurred. {inferenceErrorMsg}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default InferenceRealTimeSubView
