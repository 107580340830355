import React, {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {
  selectActiveInferenceSubView,
  selectSelectedProject,
  setActiveView
} from '../../store/slices/projectSlice'
import InferenceRealTimeSubView from './subviews/InferenceRealTimeSubView'
import InferenceMapSubView from './subviews/InferenceMapSubView'
import InferenceBatchSubView from './subviews/InferenceBatchSubView'
import {useFetchModelsQuery} from '../../services/modelsApi'
import ConfirmDialog from '../modals/ConfirmDialog'

const InferenceView: React.FC = () => {
  const dispatch = useAppDispatch()

  const project = useAppSelector(selectSelectedProject)
  const activeInferenceSubView = useAppSelector(selectActiveInferenceSubView)

  const { data: modelsData } = useFetchModelsQuery({ project_id: project?.id || '' })


  useEffect(() => {
    if (modelsData?.models) {

      const inService = modelsData?.models?.some((model) => model.deployment.status === 'IN_SERVICE' || model.batch.status.startsWith('COMPLETE'))

      if (!inService) {
        setShowNoModelsDeployed(true)
      }
    }
  }, [modelsData?.models])

  const [showNoModelsDeployed, setShowNoModelsDeployed] = useState(false)

  switch (activeInferenceSubView) {
    case 'Real Time':
      return (
        <>
          <InferenceRealTimeSubView />

          <ConfirmDialog
            show={showNoModelsDeployed}
            onHide={() => setShowNoModelsDeployed(false)}
            onConfirm={() => {dispatch(setActiveView('models'))}}
            title="Deploy a Model First"
            message="You need to deploy a model before running inference."
            confirmText="Go to Models"
            cancelText="Cancel"
            variant="primary"
          />
        </>
      )

    case 'Map (Coming Soon)':
      return (
        <>
          <InferenceMapSubView />

          <ConfirmDialog
            show={showNoModelsDeployed}
            onHide={() => setShowNoModelsDeployed(false)}
            onConfirm={() => {dispatch(setActiveView('models'))}}
            title="Deploy a Model First"
            message="You need to deploy a model before running inference."
            confirmText="Go to Models"
            cancelText="Cancel"
            variant="primary"
          />
        </>
      )

    case 'Suggestive Labeling':
      return (
        <>
          <InferenceBatchSubView itemsPerPage={100} selectable={true} includeModels={true} />

          <ConfirmDialog
            show={showNoModelsDeployed}
            onHide={() => setShowNoModelsDeployed(false)}
            onConfirm={() => {dispatch(setActiveView('models'))}}
            title="Deploy a Model First"
            message="You need to deploy a model before running inference."
            confirmText="Go to Models"
            cancelText="Cancel"
            variant="primary"
          />
        </>
      )
    default:
      return null
  }
}

export default InferenceView
