// src/components/PredictionsView.tsx

import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Prediction } from '../../types/prediction'
import {selectSelectedModel} from '../../store/slices/modelSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  clearAllBoundingBoxes,
} from '../../store/slices/annotationSlice'
import { setEditingPrediction } from '../../store/slices/predictionSlice'
import {
  addSelectedPrediction,
  removeSelectedPrediction,
} from '../../store/slices/predictionSlice'
import { RootState } from '../../store'
import PredictionImage from '../PredictionImage'
import { useNavigate } from 'react-router-dom'

interface PredictionsGalleryViewProps {
  filteredPredictions: Prediction[]
  arePredictionsLoading: boolean
  page: number
  threshold?: { min: number; max: number }
  cardSize: number,
  itemsPerPage: number,
}

const PredictionsGalleryView: React.FC<PredictionsGalleryViewProps> = ({
  filteredPredictions,
  arePredictionsLoading,
  threshold = {min: 50, max: 100},
  cardSize,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // const [filteredPredictions, setFilteredPredictions] = useState<Prediction[]>([])
  const selectedModel = useAppSelector(selectSelectedModel)

  const selectedPredictions = useAppSelector((state: RootState) => state.prediction.selectedPredictions)

  const handleGridPredictionClick = (prediction: Prediction) => {
    if (prediction) {
      dispatch(setEditingPrediction(prediction))
      dispatch(clearAllBoundingBoxes())
      navigate('/predictionsreview')
    }
  }

  const handleTogglePrediction = (prediction: Prediction) => {
    if (selectedPredictions.some((p) => p.id === prediction.id)) {
      dispatch(removeSelectedPrediction(prediction.id))
    } else {
      dispatch(addSelectedPrediction(prediction))
    }
  }

  return (
    <div className="annotation-tab-content">
      <div className="annotorious-container">
        {selectedModel?.batch?.status?.toUpperCase().startsWith('COMPLETE') && (
          <Row style={{ overflow: 'hidden' }}>
            <Col>
              {
                arePredictionsLoading ? (
                  <Spinner animation="border" variant="primary" />
                )
                  : filteredPredictions?.length > 0 ? (
                    <div className="grid-view" style={{height: '75vh', overflow: 'auto'}}>
                      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {filteredPredictions.map((prediction: Prediction) => (
                          <PredictionImage
                            key={prediction.id}
                            prediction={prediction}
                            onEdit={handleGridPredictionClick}
                            onToggle={handleTogglePrediction}
                            cardSize={cardSize}
                            isSelected={selectedPredictions?.some((p) => p.id === prediction.id)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p>No new suggestive labels available.</p>
                  )}
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default PredictionsGalleryView
