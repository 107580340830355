import {useState, useEffect, useRef} from 'react'
import { useGetContentImageQuery, useViewContentQuery} from '../services/contentApi'


export const useContentImage = ({contentId, projectId, datasetId}: {
  contentId: string,
  projectId: string,
  datasetId?: string,
}) => {
  const [base64Image, setBase64Image] = useState<string | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const imageRef = useRef(null)

  const {
    data: imageUrl,
    error: imageError,
    isLoading: imageLoading,
  } = useGetContentImageQuery(
    {
      ContentID: contentId,
      project_id: projectId,
      dataset_id: datasetId,
    },
    { skip: !contentId || !isVisible },
  )

  const { data: contentData, isLoading: contentLoading } = useViewContentQuery(
    {
      ContentID: contentId || '',
      project_id: projectId || '',
      dataset_id: datasetId || '',
    },
    {
      skip: !contentId || !isVisible,
    },
  )
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      { threshold: 0.5 }
    )

    if (imageRef.current) {
      observer.observe(imageRef.current)
    }

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    const fetchImage = async () => {
      if (imageUrl && isVisible) {
        try {
          const response = await fetch(imageUrl)
          const blob = await response.blob()
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64data = reader.result as string
            setBase64Image(base64data)
          }
          reader.readAsDataURL(blob)
        } catch (error) {
          console.error('Error converting image to base64:', error)
        }
      }
    }

    fetchImage()
  }, [imageUrl, isVisible])

  return { base64Image, imageError, imageLoading, imageRef, contentData }
}
