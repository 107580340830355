// Path: /home/jason/src/AgileView/autovision-ui/src/components/wizards/CPTypeStep.tsx
import React from 'react'
import { Image, Row, Col } from 'react-bootstrap'
import {useProject} from './NewProjectContext'

const CPTypeStep: React.FC = () => {
  const {projectType, setProjectType} = useProject()

  const imageStyle = {
    width: 'auto',
    height: '320px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    opacity: '0.5',
  }

  const selectedImageStyle = {
    borderColor: '#007bff',
    borderWidth: '10px',
    boxShadow: '0 0 50px rgba(0, 123, 255, 0.7)',
    opacity: '1'
  }

  const unselectedImageStyle = {
    opacity: '.10'
  }

  return (
    <Row className="mb-3">
      <Col xs={6} className="text-center">
        <Image
          src="/bbox-image.jpg"
          alt="Bounding Box"
          style={{
            ...imageStyle,
            ...(projectType === 'bounding_box' ? selectedImageStyle : {}),
            ...(projectType === 'classification' ? unselectedImageStyle : {})
          }}
          onClick={() => setProjectType('bounding_box')}
        />
        <p>Bounding Box</p>
      </Col>

      <Col xs={6} className="text-center">
        <Image
          src="/class-image.jpg"
          alt="Classification"
          style={{
            ...imageStyle,
            ...(projectType === 'classification' ? selectedImageStyle : {}),
            ...(projectType === 'bounding_box' ? unselectedImageStyle : {})
          }}
          onClick={() => setProjectType('classification')}
        />
        <p>Classification</p>
      </Col>
    </Row>

  )
}

export default CPTypeStep