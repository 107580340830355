// src/services/exportApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'

import { Export, ExportType } from '../types/export'
import { ListExportsResp, OkResp, QueryExportResp } from '../types/responses'
import { DATASET_EXPORT_URL, DATASET_QUERY_EXPORT_URL } from '../constants'
import baseQueryWithReauth from './baseQuery'
import { Filter } from '../types/filter'

export const exportApi = createApi({
  reducerPath: 'exportApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Export'],
  endpoints: (builder) => ({
    getExports: builder.query<
    ListExportsResp,
    {
      limit?: number
      page?: number
      sort_key?: string
      sort_val?: number
    }
    >({
      query: (params) => ({
        url: DATASET_EXPORT_URL,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        result
          ? [...result.exports.map(({ id }) => ({ type: 'Export' as const, id })), { type: 'Export', id: 'LIST' }]
          : [{ type: 'Export', id: 'LIST' }],
    }),

    createExport: builder.mutation<
    Export,
    {
      name: string
      export_type: ExportType
      id: string
    }
    >({
      query: (body) => ({
        url: DATASET_EXPORT_URL,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Export', id: 'LIST' }],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          await queryFulfilled
        } catch (err) {
          // Handle the error appropriately
        }
      },
    }),

    queryExports: builder.mutation<
    QueryExportResp,
    {
      filters?: Filter
      limit?: number
      page?: number
      sort_key?: string
      sort_val?: number
      operator?: 'and' | 'or'
    }
    >({
      query: (body) => ({
        url: DATASET_QUERY_EXPORT_URL,
        method: 'POST',
        body,
      }),
    }),

    getExport: builder.query<Export, { id: string; websocket?: boolean }>({
      query: ({ id, websocket }) => ({
        url: `${DATASET_EXPORT_URL}/${id}`,
        method: 'GET',
        params: { websocket },
      }),
      providesTags: (result, error, arg) => [{ type: 'Export', id: arg.id }],
    }),

    deleteExport: builder.mutation<OkResp, string>({
      query: (id) => ({
        url: `${DATASET_EXPORT_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Export', id }],
    }),

    getExportContent: builder.query<
    { URL: string }, // Adjusted response type
    {
      id: string
      uri: string
      download?: boolean
      expire?: number
    }
    >({
      query: ({ id, ...params }) => ({
        url: `${DATASET_EXPORT_URL}/${id}/content`,
        method: 'GET',
        params,
        responseHandler: async (response) => {
          if (params.download) {
            // Handle the redirect or file download
            const blob = await response.blob()
            // Implement file download logic here
            return blob
          } else {
            return response.json()
          }
        },
      }),
    }),

    getExportMetadata: builder.query<Record<string, unknown>, string>({
      query: (id) => ({
        url: `${DATASET_EXPORT_URL}/${id}/metadata`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          const text = await blob.text()
          return JSON.parse(text)
        },
      }),
    }),
  }),
})

export const {
  useGetExportsQuery,
  useCreateExportMutation,
  useQueryExportsMutation,
  useGetExportQuery,
  useDeleteExportMutation,
  useGetExportContentQuery,
  useGetExportMetadataQuery,
} = exportApi
