import { createApi } from '@reduxjs/toolkit/query/react'
import { Model, ModelCreateParams, ModelUpdateParams, QueryModelReq } from '../types/model'
import { MODELS_URL } from '../constants'
import baseQueryWithReauth from './baseQuery'
import { ListModelResp, QueryModelResp } from '../types/responses'
import {RunInferenceResponse} from '../types/prediction'

export const modelsApi = createApi({
  reducerPath: 'modelsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Model'],

  endpoints: (builder) => ({
    fetchModels: builder.query<
      ListModelResp,
      { project_id: string; limit?: number; page?: number; sort_key?: string; sort_val?: number }
    >({
      query: (params) => ({
        url: `${MODELS_URL}`,
        params,
      }),
      providesTags: (result) =>
        result && result.models
          ? [...result.models.map(({ id }) => ({ type: 'Model' as const, id })), { type: 'Model', id: 'LIST' }]
          : [{ type: 'Model', id: 'LIST' }],
    }),

    createModel: builder.mutation<Model, ModelCreateParams>({
      query: (modelData) => ({
        url: `${MODELS_URL}`,
        method: 'POST',
        body: modelData,
      }),
      invalidatesTags: [{ type: 'Model', id: 'LIST' }],
    }),

    getModel: builder.query<Model, string>({
      query: (id) => `${MODELS_URL}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Model', id }],
      // Add these options to disable caching
      keepUnusedDataFor: 0,
      forceRefetch: ({ currentArg, previousArg }) => currentArg === previousArg,
    }),

    updateModel: builder.mutation<
      Model,
      {
        id: string
        modelData: ModelUpdateParams
      }
    >({
      query: ({ id, modelData }) => ({
        url: `${MODELS_URL}/${id}`,
        method: 'PATCH',
        body: modelData,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Model', id }],
    }),

    deleteModel: builder.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({
        url: `${MODELS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Model', id: 'LIST' }],
    }),

    deployModel: builder.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({
        url: `${MODELS_URL}/${id}/deploy`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Model', id }],
    }),

    deleteModelDeployment: builder.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({
        url: `${MODELS_URL}/${id}/deploy`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Model', id }],
    }),

    runBatchInference: builder.mutation<
      { message: string },
      { id: string; thumbnail_size?: number; metadata_filter: { datasource: string[] } | undefined }
    >({
      query: ({ id, thumbnail_size, metadata_filter }) => ({
        url: `${MODELS_URL}/${id}/inference/batch`,
        method: 'POST',
        body: { thumbnail_size, metadata_filter },
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id }]))
        } catch {
          // Handle error
        }
      },
    }),

    runRealtimeInference: builder.mutation<
      RunInferenceResponse,
      { id: string; data: FormData | Blob; threshold?: number; heatmap?: boolean }
    >({
      query: ({ id, data, threshold, heatmap }) => ({
        url: `${MODELS_URL}/${id}/inference/realtime`,
        method: 'POST',
        body: data,
        params: { threshold: threshold || 0.5, heatmap: heatmap },
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id }]))
        } catch {
          // Handle error
        }
      },
      // invalidatesTags: (result, error, { id }) => [{ type: 'Model', id }],
    }),

    trainModel: builder.mutation<
      Model,
      {
        id: string
        metadata_filter: { datasource: string[] } | undefined
        validation_metadata_filter: { datasource: string[] } | undefined
      }
    >({
      query: ({ id, metadata_filter, validation_metadata_filter }) => ({
        url: `${MODELS_URL}/${id}/train`,
        method: 'POST',
        body: { metadata_filter, validation_metadata_filter },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Model', id }],
    }),

    queryModels: builder.mutation<QueryModelResp, QueryModelReq>({
      query: (queryData) => ({
        url: `${MODELS_URL}/query`,
        method: 'POST',
        body: queryData,
      }),
    }),
  }),
})

export const {
  useFetchModelsQuery,
  useCreateModelMutation,
  useGetModelQuery,
  useUpdateModelMutation,
  useDeleteModelMutation,
  useDeployModelMutation,
  useDeleteModelDeploymentMutation,
  useRunBatchInferenceMutation,
  useRunRealtimeInferenceMutation,
  useTrainModelMutation,
  useQueryModelsMutation,
} = modelsApi

export const {
  util: { invalidateTags },
} = modelsApi
