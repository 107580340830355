import React from 'react'
import { Row, Col, Button, Card } from 'react-bootstrap'
import { useProject } from './NewProjectContext'
import { useCreateProjectMutation } from '../../services/projectsApi'
import {toTitleCase} from '../../utils'


interface CPCreateProjectStepProps {
  onHide: () => void
}

const CPCreateProjectStep: React.FC<CPCreateProjectStepProps> = ({ onHide }) => {
  const {
    projectName,
    projectType,
    setProjectId,
    setName,
    setDescription,
  } = useProject()

  const [createProject, { isLoading: isCreating }] = useCreateProjectMutation()

  const handleCreateProject = () => {
    if (projectName && projectType !== undefined) {
      createProject({
        name: projectName,
        description: `Created: ${new Date().toLocaleString()}`,
        annotation_type: projectType,
        license: '',
      })
        .unwrap()
        .then((newProject) => {
          setProjectId(newProject.id)
          setName(projectName)
          setDescription(newProject.description)
          onHide()
        })
        .catch((e) => {
          alert(`Error creating project: ${e.data.message}`)
        })
    }
  }

  return (
    <Row className="justify-content-center">
      <Col xs={6}>
        <Card className="mb-4 bg-dark text-light">
          <Card.Header className="d-flex justify-content-between align-items-center p-3">
            <h5 className="mb-0">Project Details</h5>
          </Card.Header>
          <Card.Body>
            <dl className="row mb-0 card-body">
              <dt className="col-sm-4">Project Name:</dt>
              <dd className="col-sm-8">{projectName}</dd>

              <dt className="col-sm-4">Project Type:</dt>
              <dd className="col-sm-8">{toTitleCase(projectType || '')}</dd>

              <dt className="col-sm-4">Creation Date:</dt>
              <dd className="col-sm-8">{new Date().toLocaleDateString()}</dd>
            </dl>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={6} className="d-flex justify-content-center">
        <Button
          onClick={handleCreateProject}
          disabled={!projectName || projectType === undefined || isCreating}
          variant="primary"
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            width: '200px',
            height: '200px',
            fontSize: '1.5rem',
            whiteSpace: 'normal',
            textAlign: 'center',
            lineHeight: '1.2'
          }}
        >
          {isCreating ? 'Creating Project...' : 'Create Project'}
        </Button>
      </Col>
    </Row>
  )
}

export default CPCreateProjectStep