// src/components/wizards/NewProjectContext.tsx
import React, { createContext, useContext, useState, useMemo, useCallback } from 'react'
import {ProjectType} from '../../store/slices/projectSlice'
import {FileMetadata} from '../../types/file'

interface ProjectContextType {
  // Project data
  projectId: string | undefined
  projectName: string | undefined;
  description: string | undefined;
  projectType: ProjectType | undefined;
  setProjectId: (id: string) => void;

  // Labels file
  labelsFile: File | undefined;
  labelsFileInfo: FileMetadata | undefined;
  setLabelsFile: (file: File | undefined) => void;

  // File management
  fileMap: Map<string, File>;
  tiffFileMap: Map<string, File>;
  addFiles: (files: File[]) => void;
  clearFiles: () => void;
  getFile: (id: string) => File | undefined;
  getTiffFile: (id: string) => File | undefined;
  selectedFiles: FileMetadata[];
  selectedTiffFiles: FileMetadata[];

  // Project data management
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setProjectType: (projectType: ProjectType) => void;
  clearProjectData: () => void;

  // Dataset management
  datasetName: string | undefined;
  setDatasetName: (name: string | undefined) => void;
  datasetSources: string[];
  setDatasetSources: (sources: string[]) => void;

  // Upload state
  uploading: boolean;
  progress: number;
  error: string | null;
  setUploading: (uploading: boolean) => void;
  setProgress: (progress: number) => void;
  setError: (error: string | null) => void;
}

const defaultProjectType: ProjectType = 'bounding_box' // or whatever your default is

const NewProjectContext = createContext<ProjectContextType | null>(null)

export const useProject = () => {
  const context = useContext(NewProjectContext)
  if (!context) {
    throw new Error('useProject must be used within a ProjectProvider')
  }
  return context
}

export const ProjectProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  // Project data state
  const [projectId, setProjectId] = useState<string | undefined>(undefined)
  const [projectName, setProjectName] = useState<string | undefined>(undefined)
  const [description, setDescription] = useState<string | undefined>(undefined)
  const [projectType, setProjectType] = useState<ProjectType | undefined>(undefined)

  // Labels file state
  const [labelsFile, setLabelsFile] = useState<File | undefined>(undefined)
  const [labelsFileInfo, setLabelsFileInfo] = useState<FileMetadata | undefined>(undefined)

  // File management state
  const [fileMap, setFileMap] = useState<Map<string, File>>(new Map())
  const [tiffFileMap, setTiffFileMap] = useState<Map<string, File>>(new Map())
  const [selectedFiles, setSelectedFiles] = useState<FileMetadata[]>([])
  const [selectedTiffFiles, setSelectedTiffFiles] = useState<FileMetadata[]>([])

  // Dataset state
  const [datasetName, setDatasetName] = useState<string | undefined>(undefined)
  const [datasetSources, setDatasetSources] = useState<string[]>([])

  // Upload state
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState<string | null>(null)
  
  const handleSetLabelsFile = useCallback((file: File | undefined) => {
    setLabelsFile(file)
    if (file) {
      setLabelsFileInfo({
        id: `${file.name}-${file.lastModified}`,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      })
    } else {
      setLabelsFileInfo(undefined)
    }
  }, [])

  const addFiles = useCallback((files: File[]) => {
    const newRegularFiles: FileMetadata[] = []
    const newTiffFiles: FileMetadata[] = []

    setFileMap(prevMap => {
      const newMap = new Map(prevMap)
      files.forEach(file => {
        if (file.type !== 'image/tiff') {
          const id = `${file.name}-${file.lastModified}`
          newMap.set(id, file)
          newRegularFiles.push({
            id,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
          })
        }
      })
      return newMap
    })

    setTiffFileMap(prevMap => {
      const newMap = new Map(prevMap)
      files.forEach(file => {
        if (file.type === 'image/tiff') {
          const id = `${file.name}-${file.lastModified}`
          newMap.set(id, file)
          newTiffFiles.push({
            id,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
          })
        }
      })
      return newMap
    })

    setSelectedFiles(prev => [...prev, ...newRegularFiles])
    setSelectedTiffFiles(prev => [...prev, ...newTiffFiles])
  }, [])

  const clearFiles = useCallback(() => {
    setFileMap(new Map())
    setTiffFileMap(new Map())
    setSelectedFiles([])
    setSelectedTiffFiles([])
  }, [])

  const clearProjectData = useCallback(() => {
    setProjectName(undefined)
    setDescription(undefined)
    setProjectType(defaultProjectType)
    setLabelsFile(undefined)
    setLabelsFileInfo(undefined)
    clearFiles()
  }, [clearFiles])

  const value = useMemo(() => ({
    // Project data
    projectId,
    projectName,
    description,
    projectType,
    setProjectType,
    setProjectId,
    setName: setProjectName,
    setDescription,

    // Labels file
    labelsFile,
    labelsFileInfo,
    setLabelsFile: handleSetLabelsFile,

    // File management
    fileMap,
    tiffFileMap,
    addFiles,
    clearFiles,
    getFile: (id: string) => fileMap.get(id),
    getTiffFile: (id: string) => tiffFileMap.get(id),
    selectedFiles,
    selectedTiffFiles,

    clearProjectData,

    // Dataset management
    datasetName,
    setDatasetName,
    datasetSources,
    setDatasetSources,

    // Upload state
    uploading,
    progress,
    error,
    setUploading,
    setProgress,
    setError,
  }), [
    projectId, projectName, description, projectType,
    labelsFile, labelsFileInfo,
    fileMap, tiffFileMap,
    selectedFiles, selectedTiffFiles,
    handleSetLabelsFile, addFiles,
    clearFiles, clearProjectData,
    datasetName, datasetSources,
    uploading, progress, error,
  ])

  return (
    <NewProjectContext.Provider value={value}>
      {children}
    </NewProjectContext.Provider>
  )
}