import React, { useMemo } from 'react'
import {useGetTagsQuery} from '../../services/tagApi'
import {useAppSelector} from '../../store/hooks'
import {selectSelectedProject} from '../../store/slices/projectSlice'
import {AnnotationDataBoundingBox} from '../../types/annotation'

interface SuggestiveLabelImageCardProps {
  base64Data: string | undefined;
  bounding_boxes: AnnotationDataBoundingBox[];
  cardWidth?: number;
  cardHeight?: number;
  contentWidth?: number;
  contentHeight?: number;
}

const SuggestiveLabelImageCard: React.FC<SuggestiveLabelImageCardProps> = ({
  base64Data,
  bounding_boxes,
  cardWidth=150,
  cardHeight=150,
  contentWidth=1024,
  contentHeight=1024,
}) => {
  const project = useAppSelector(selectSelectedProject)

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const getTagColor = (box: AnnotationDataBoundingBox) => {
    const tag = tags?.tags.find((tag) => tag.id === box.name || tag.name === box.class_name)
    if (tag) {
      return `#${tag.color.toString(16).padStart(6, '0').slice(0, 6)}`
    } else {
      return '#800085'
    }
  }

  if (!base64Data) {
    return null
  }

  return (
    <div
      style={{
        position: 'relative',
        width: cardWidth,
        height: cardHeight,
        overflow: 'hidden',
      }}
    >
      {/* Image Layer */}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          transition: 'transform 0.3s ease-in-out',
          transformOrigin: '0 0',
        }}
      >
        <img
          src={base64Data}
          alt="Annotated"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>

      {/* Bounding Box Overlay Layer */}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          transition: 'transform 0.3s ease-in-out',
          transformOrigin: '0 0',
          pointerEvents: 'none',
        }}
      >
        {bounding_boxes.map((box, index) => {
          // Calculate unscaled border width
          const borderWidth = 1

          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: `${(box?.xmin || 0) * cardWidth / contentWidth}px`,
                top: `${(box?.ymin || 0) * cardHeight / contentHeight}px`,
                width: `${((box?.xmax || 0) - (box?.xmin || 0)) * cardWidth / contentWidth}px`,
                height: `${((box?.ymax || 0) - (box?.ymin || 0)) * cardHeight / contentHeight}px`,
                border: `${borderWidth}px solid ${getTagColor(box)}`,
                boxSizing: 'border-box',
                pointerEvents: 'none',
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SuggestiveLabelImageCard