// src/components/layout/InteractiveArea.tsx

import React from 'react'
import DatasetTools from '../tools/DatasetTools'
import ModelListCard from '../tools/ModelListCard'
import { FileMetadata } from '../../types/file'
import { selectActiveView } from '../../store/slices/projectSlice'

import { useAppSelector } from '../../store/hooks'
import InferenceTools from '../tools/InferenceTools'
import {Col} from 'react-bootstrap'
import SelectedDatasetsCard from '../SelectedDatasetsCard'

interface ToolsAreaProps {
  onLabelsFileSelect: (file: File | null) => void
  labelsFile: File | null
  labelsFileInfo: FileMetadata | null
}

const ToolsArea: React.FC<ToolsAreaProps> = () => {
  const activeView = useAppSelector(selectActiveView)

  switch (activeView) {

    case 'dataset':
      return <>
        <DatasetTools />
      </>

    case 'models':
      return <>
        <Col>
          <SelectedDatasetsCard />
          <ModelListCard />
        </Col>
      </>

    case 'inference':
      return <>
        <InferenceTools />
      </>
    
    default:
      return null
  }
}

export default ToolsArea
