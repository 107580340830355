// src/store/slices/projectSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Tag } from '../../types/tag'
import { Project } from '../../types/project'
import {RootState} from '../index'
import {FileMetadata} from '../../types/file'

export type ActiveView = 'project' | 'models' | 'dataset' | 'inference'

export type ActiveTab = 'annotated' | 'unannotated'

export type DatasetSubView = 'Overview' | 'Annotations' | 'Manage' | 'Export'
export const DatasetSubNavItems = ['Overview', 'Manage', 'Annotations', 'Export'] as DatasetSubView[]

export type InferenceSubView = 'Real Time' | 'Map (Coming Soon)' | 'Suggestive Labeling' | 'Custom Inference'
export const InferenceSubNavItems = ['Real Time', 'Map (Coming Soon)', 'Suggestive Labeling', 'Custom Inference'] as InferenceSubView[]

export type ProjectType = 'bounding_box' | 'classification'

export type NewProjectData = {
  name: string | undefined
  description: string | undefined
  type: ProjectType
  labelsFile: File | undefined
  labelsFileInfo: FileMetadata | undefined
}

export interface ProjectState {
  activeTab: ActiveTab
  selectedModelTab: string | undefined
  activeView: ActiveView
  activeDatasetSubView: DatasetSubView
  activeInferenceSubView: InferenceSubView
  filterTags: Tag[]
  selectedProject: Project | null
  selectedAnnotationIds: string[]
  aiResponses: string[]
  newProjectData: NewProjectData | null
}

const initialState: ProjectState = {
  activeTab: 'unannotated',
  selectedModelTab: undefined,
  activeView: 'project',
  activeDatasetSubView: 'Overview',
  activeInferenceSubView: 'Real Time',
  filterTags: [],
  selectedProject: null,
  selectedAnnotationIds: [],
  aiResponses: [],
  newProjectData: null
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setNewProjectData: (state, action: PayloadAction<NewProjectData>) => {
      state.newProjectData = action.payload
    },
    
    addSelectedAnnotationId: (state, action: PayloadAction<string>) => {
      const newSet = Array.from(new Set([...state.selectedAnnotationIds, action.payload]))
      state.selectedAnnotationIds = newSet || []
    },

    removeSelectedAnnotationId: (state, action: PayloadAction<string>) => {
      state.selectedAnnotationIds = state.selectedAnnotationIds.filter((id: string) => id !== action.payload)
    },

    toggleSelectedAnnotationId: (state, action: PayloadAction<string>) => {
      if (state.selectedAnnotationIds.includes(action.payload)) {
        state.selectedAnnotationIds = state.selectedAnnotationIds.filter((id: string) => id !== action.payload)
      } else {
        const newSet = Array.from(new Set([...state.selectedAnnotationIds, action.payload]))
        state.selectedAnnotationIds = newSet || []
      }
    },

    setActiveTab: (state, action: PayloadAction<ActiveTab>) => {
      state.activeTab = action.payload
    },

    setSelectedModelTab: (state, action: PayloadAction<string| undefined>) => {
      state.selectedModelTab = action.payload
    },

    clearSelectedModelTab: (state) => {
      state.selectedModelTab = undefined
    },

    setActiveView: (state, action: PayloadAction<ActiveView>) => {
      state.activeView = action.payload
    },

    setActiveDatasetSubView: (state, action: PayloadAction<DatasetSubView>) => {
      state.activeDatasetSubView = action.payload
    },

    setActiveInferenceSubView: (state, action: PayloadAction<InferenceSubView>) => {
      state.activeInferenceSubView = action.payload
    },

    setFilterTags: (state, action: PayloadAction<Tag[]>) => {
      state.filterTags = action.payload
    },

    clearFilterTags: (state) => {
      state.filterTags = []
    },

    addFilterTag: (state, action: PayloadAction<Tag>) => {
      const filterExists = state.filterTags.find((t) => t.id === action.payload.id)
      if (!filterExists) {
        state.filterTags = [...state.filterTags, action.payload]
      }
    },

    removeFilterTag: (state, action: PayloadAction<Tag>) => {
      state.filterTags = state.filterTags.filter((t) => t.id !== action.payload.id)
    },

    toggleFilterTag: (state, action: PayloadAction<Tag>) => {
      const filterExists = state.filterTags.find((t) => t.id === action.payload.id)
      if (!filterExists) {
        state.filterTags = [...state.filterTags, action.payload]
      } else {
        state.filterTags = state.filterTags.filter((t) => t.id !== action.payload.id)
      }
    },

    setSelectedProject: (state, action: PayloadAction<Project>) => {
      state.selectedProject = action.payload
    },

    clearSelectedProject: (state) => {
      state.selectedProject = null
    },


    // New reducers for managing AI responses
    addAIResponse: (state, action: PayloadAction<string>) => {
      console.log('pushing:: ', action.payload)
      state.aiResponses.push(action.payload) // Append new response
    },
    setAIResponse: (state, action: PayloadAction<string[]>) => {
      state.aiResponses = action.payload // Append new response
    },
    clearAIResponses: (state) => {
      state.aiResponses = [] // Clear all responses
    },
  },
})

export const {
  addSelectedAnnotationId,
  removeSelectedAnnotationId,
  toggleSelectedAnnotationId,
  setActiveTab,
  setSelectedModelTab,
  clearSelectedModelTab,
  setActiveView,
  setActiveDatasetSubView,
  setActiveInferenceSubView,
  setFilterTags,
  addFilterTag,
  removeFilterTag,
  clearFilterTags,
  toggleFilterTag,
  setSelectedProject,
  clearSelectedProject,
  setAIResponse,
  addAIResponse,
  clearAIResponses,
  setNewProjectData,
} = projectSlice.actions

export default projectSlice.reducer

// Selector
export const selectSelectedAnnotationIds = (state: RootState) => state.project.selectedAnnotationIds
export const selectActiveTab = (state: { project: ProjectState }) => state.project.activeTab
export const selectSelectedModelTab = (state: { project: ProjectState }) => state.project.selectedModelTab
export const selectActiveView = (state: { project: ProjectState }) => state.project.activeView
export const selectActiveDatasetSubView = (state: { project: ProjectState }) => state.project.activeDatasetSubView
export const selectActiveInferenceSubView = (state: { project: ProjectState }) => state.project.activeInferenceSubView
export const selectFilterTags = (state: { project: ProjectState }) => state.project.filterTags
export const selectSelectedProject = (state: { project: ProjectState }) => state.project.selectedProject
export const selectAiResponses = (state: RootState) => state.project.aiResponses
export const selectNewProjectData = (state: RootState) => state.project.newProjectData
