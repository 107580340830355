// src/components/wizards/UploadSelectFilesStep.tsx
import React, { useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useProject } from './NewProjectContext'
import FileUploader from '../FileUploader'

const UploadFilesStep: React.FC = () => {
  const {
    addFiles,
    fileMap,
    tiffFileMap,
    uploading = false // This should be added to ProjectContext if not already there
  } = useProject()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    addFiles(acceptedFiles)
  }, [addFiles])

  return (
    <Row>
      {!uploading && (
        <Col xs={12} className="d-flex align-items-center justify-content-center">
          <FileUploader
            onFileSelect={onDrop}
            uploading={uploading}
            numFiles={fileMap.size + tiffFileMap.size}
          />
        </Col>
      )}
    </Row>
  )
}

export default UploadFilesStep// Path: /home/jason/src/AgileView/autovision-ui/src/components/wizards/UploadSelectFilesStep.tsx