// src/components/PredictionImage.tsx

import React, { useEffect, useState } from 'react'
import {AnnotationPredictionBoundingBox, Prediction, PredictionMetadata} from '../types/prediction'
import { Button, Card } from 'react-bootstrap'
import PillsBBox from './PillsBBox'
import PillsClassification from './PillsClassification'
import { useContentImage } from '../hooks/useContentImage'
import { useAppSelector } from '../store/hooks'
import { selectSelectedProject } from '../store/slices/projectSlice'
import { AnnotationDataBoundingBox } from '../types/annotation'
import SuggestiveLabelImageCard from './annotation/SuggestiveLabelImageCard'
import {normalizeBoundingBox} from '../utils'
import PillsConfidence from './PillsConfidence'

interface PredictionImageProps {
  prediction: Prediction
  onEdit: (prediction: Prediction) => void
  onToggle: (prediction: Prediction) => void
  isSelected: boolean
  cardSize: number
}

const PredictionImage: React.FC<PredictionImageProps> = ({ prediction, onEdit, onToggle, isSelected, cardSize }) => {
  const project = useAppSelector(selectSelectedProject)
  const [isHovered, setIsHovered] = useState(false)
  const [imageBoundingBoxes, setImageBoundingBoxes] = useState<AnnotationDataBoundingBox[]>([])

  useEffect(() => {
    const bboxes = prediction?.predictions.map((prediction: PredictionMetadata) => {
      return normalizeBoundingBox(({
        class_name: prediction.class_name,
        xmin: prediction.bounding_boxes?.xmin || 0,
        xmax: prediction.bounding_boxes?.xmax || 0,
        ymin: prediction.bounding_boxes?.ymin || 0,
        ymax: prediction.bounding_boxes?.ymax || 0,
        name: prediction.tagid
      } as AnnotationDataBoundingBox))
    })
    setImageBoundingBoxes(bboxes)
  }, [prediction])

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent the click from propagating to the parent div
    onEdit(prediction)
  }

  const handleImageClick = () => {
    onToggle(prediction)
  }
  const { base64Image, imageRef, contentData } = useContentImage({
    contentId: prediction.contentid,
    projectId: project?.id || '',
    datasetId: project?.datasetid
  })

  const getMinPrediction = (): string => {
    const min = Math.min(...prediction.predictions.map((p) => p.confidence))
    return (min * 100).toFixed(0)
  }

  const getMaxPrediction = (): string => {
    const max = Math.max(...prediction.predictions.map((p) => p.confidence))
    return (max * 100).toFixed(0)
  }

  return (
    <div
      className="image-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleImageClick}
      ref={imageRef}
    >
      <div style={{ padding: '5px' }} onClick={() => handleImageClick}>
        <Card
          bg="bg-light"
          text="light"
          style={{
            height: `${cardSize}px`,
            width: `${cardSize}px`,
            cursor: 'pointer',
            position: 'relative'
          }}
        >
          <SuggestiveLabelImageCard
            base64Data={base64Image || ''}
            bounding_boxes={imageBoundingBoxes}
            cardWidth={cardSize}
            cardHeight={cardSize}
            contentWidth={contentData?.width}
            contentHeight={contentData?.height}
          />

          {isHovered && (
            <div className="hover-overlay">
              <Button
                variant="primary"
                size="sm"
                onClick={handleEdit}
                className="edit-button"
                style={{
                  borderRadius: `${cardSize / 4}px`,
                  width: `${cardSize / 2}px`,
                  height: `${cardSize / 2}px`,
                  fontSize: `${cardSize / 10}px`,
                }}
              >
                  Edit
              </Button>
            </div>
          )}
          <input
            type="checkbox"
            className="checkbox-top-right"
            checked={isSelected}
            onChange={() => onToggle(prediction)}
          />

          {project?.annotation_type === 'classification' && (
            <div className="info-overlay">
              <PillsClassification
                tagIds={prediction.predictions.map((p) => p.tagid)}
                tagConfidence={prediction.predictions.map((p) => p.confidence)}
                className="prediction-text"
              />
            </div>
          )}

          {project?.annotation_type === 'bounding_box' && (
            <div style={{position: 'absolute', bottom: '10px', left: '10px', zIndex: 1}}>
              <PillsBBox
                bounding_boxes={prediction?.predictions?.map((p, index) => {
                  return {
                    ...(p.bounding_boxes as AnnotationPredictionBoundingBox),
                    name: p.class_name,
                    confidence: p.confidence,
                    class_index: index,
                  }
                })}
              />
            </div>
          )}
          <div style={{position: 'absolute', top: '10px', left: '10px', zIndex: 1}}>
            <PillsConfidence
              confidences={prediction?.predictions?.map((p) => p.confidence)}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default PredictionImage
