import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, Col, Row} from 'react-bootstrap'

import {useAppSelector} from '../../store/hooks'
import {selectActiveDatasetSubView, selectSelectedModelTab, selectSelectedProject} from '../../store/slices/projectSlice'
import MiniClassDistributionCard from '../MiniClassDistributionCard'
import SelectedDatasetsCard from '../SelectedDatasetsCard'
import PredictionStatisticsTable from '../PredictionStatisticsTable'
import {useFetchModelsQuery} from '../../services/modelsApi'
import {Model} from '../../types/model'
import BulkTagManagerModal from '../modals/BulkTagManagerModal'
import DatasetUploadToolsCard from '../DatasetUploadToolsCard'

const DatasetTools: React.FC = () => {
  const activeDatasetSubView = useAppSelector(selectActiveDatasetSubView)
  const project = useAppSelector(selectSelectedProject)
  const selectedModelTab = useAppSelector(selectSelectedModelTab)
  const [selectedModel, setSelectedModel] = useState<Model | undefined>(undefined)
  const [showModal, setShowModal] = useState<boolean>(false)
  
  const { data: modelsData } = useFetchModelsQuery({ project_id: project?.id || '' })

  useEffect(() => {
    const filteredModel = modelsData?.models?.find((model) => model.id === selectedModelTab)
    setSelectedModel(filteredModel)
  }, [modelsData?.models, selectedModelTab])

  switch (activeDatasetSubView) {
    case 'Overview':
      return (
        <Col>
          <SelectedDatasetsCard />
          <DatasetUploadToolsCard />
        </Col>
      )
    case 'Annotations':
      return (
        <>
          <SelectedDatasetsCard />
          <MiniClassDistributionCard showButtons={false} />
          {selectedModelTab && selectedModel && (
            <PredictionStatisticsTable model={selectedModel} />
          )}
        </>
      )
    case 'Manage':
      return (
        <>
          <Card className="gallery-tools mb-3" style={{ borderRadius: '10px'}}>
            <CardBody>
              <Row>
                <Button onClick={() => setShowModal(true)}>Tag Manager</Button>
              </Row>
              <BulkTagManagerModal show={showModal} onHide={() => setShowModal(false)} />
            </CardBody>
          </Card>
          <SelectedDatasetsCard />
          <DatasetUploadToolsCard />
          <MiniClassDistributionCard showButtons={false} />
        </>
      )
    case 'Export':
      return (
        <>Dataset Export Sub View</>
      )
    default:
      return null
  }
}

export default DatasetTools
