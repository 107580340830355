// src/components/PredictionsView.tsx

import React, {useCallback, useEffect, useState} from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  setSelectedContentId,
  setEditingContent,
  clearAllBoundingBoxes,
} from '../../store/slices/annotationSlice'
import {selectFilterTags, selectSelectedProject} from '../../store/slices/projectSlice'
import { useNavigate } from 'react-router-dom'
import {Content} from '../../types/content'
import ContentImage from '../tools/ContentImage'
import {selectDatasetSources} from '../../store/slices/datasetSlice'
import {contentApi} from '../../services/contentApi'

interface AnnotationsGalleryViewProps {
  content: Content[]
  isContentLoading: boolean
  cardSize: number,
}

const AnnotationsGalleryView: React.FC<AnnotationsGalleryViewProps> = ({
  content,
  isContentLoading,
  cardSize,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [filteredContent, setFilteredContent] = useState<Content[]>([])

  const selectedFilterTags = useAppSelector(selectFilterTags)

  useEffect(() => {
    if (content?.length > 0) {
      // const sorted = [...content].sort((a, b) => a.id.localeCompare(b.id))
      if (selectedFilterTags?.length > 0) {
        const filteredBBoxes = content.filter((bbox) =>
          selectedFilterTags?.map((t) => t.id).includes(bbox.id),
        )
        setFilteredContent(filteredBBoxes)
      } else {
        setFilteredContent(content)
      }
    }
  }, [content, selectedFilterTags])

  const handleImageClick = useCallback((content: Content) => {
    dispatch(setSelectedContentId(content.id))
    dispatch(setEditingContent(content))
    dispatch(clearAllBoundingBoxes())
    navigate('/annotationsreview')
  }, [dispatch, navigate])

  return (
    <div className="annotation-tab-content">
      <div className="annotorious-container">
        <Row style={{ overflow: 'hidden' }}>
          <Col>
            {
              isContentLoading ? (
                <Spinner animation="border" variant="primary" />
              )
                : filteredContent?.length > 0 ? (
                  <div className="grid-view" style={{height: '75vh', overflow: 'auto'}}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                      {filteredContent.map((content: Content) => (
                        <ContentImage
                          key={content.id}
                          content={content}
                          cardSize={cardSize}
                          onImageClick={handleImageClick}
                          selectable={true}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>No content available.</p>
                )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AnnotationsGalleryView
