// src/components/wizards/UploadLabelsFileStep.tsx
import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FileText } from 'lucide-react'
import {useProject} from './NewProjectContext'

const SelectLabelsStep: React.FC = () => {
  const { labelsFileInfo, setLabelsFile } = useProject()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && file.name.endsWith('.json')) {
      setLabelsFile(file)
    } else {
      setLabelsFile(undefined)
      console.error('Invalid file type. Please select a JSON file.')
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <input
        ref={fileInputRef}
        type="file"
        accept=".json"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Button
        variant={labelsFileInfo ? 'success' : 'danger'}
        onClick={handleButtonClick}
        className="d-flex align-items-center justify-content-center labels-json-btn me-4"
        size="sm"
        title="labels.json"
      >
        <FileText size={16} />
      </Button>
      <span className="labels-json-text">
        {labelsFileInfo ? `${labelsFileInfo.name} selected` : 'Click icon to select file'}
      </span>
    </div>
  )
}

export default SelectLabelsStep