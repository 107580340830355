import React from 'react'
import { Image, Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Plus } from 'react-bootstrap-icons'
import {Model} from '../../types/model'
import {selectSelectedModel, setSelectedModel} from '../../store/slices/modelSlice'
import {useFetchModelsQuery} from '../../services/modelsApi'
import {selectSelectedProject} from '../../store/slices/projectSlice'
import {useAppSelector} from '../../store/hooks'


interface NewModelModalProps {
  show: boolean
  onClose: () => void
  baseModels: { id: string; name: string, image: string }[]
  filterText: string
  setFilterText: (text: string) => void
  selectedBaseModel: { id: string; name: string } | null
  onBaseModelSelect: (model: { id: string; name: string }) => void
  onGenerateNewModel: () => void
}

const NewModelModal: React.FC<NewModelModalProps> = ({
  show,
  onClose,
  baseModels,
  filterText,
  setFilterText,
  selectedBaseModel,
  onBaseModelSelect,
  onGenerateNewModel,
}) => {

  const project = useAppSelector(selectSelectedProject)
  const selectedModel = useAppSelector( selectSelectedModel)

  const {
    data: modelsData,
  } = useFetchModelsQuery({ project_id: project?.id || '' })
  return (
    <Modal show={show} onHide={onClose} size="lg" className="model-modal">
      <Modal.Header className="bg-dark text-white" closeButton>
        <Modal.Title>New Model</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">
        <Row className="mb-3">
          <Col>
            {/* Added filter input box */}
            <div className="d-flex justify-content-start mb-3">
              <input
                type="text"
                placeholder="Filter models..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="model-filter-input"
              />
            </div>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button onClick={onClose}>
              <Plus size='32px' color="white"/>
            </Button>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col sm="5">
            <Modal.Title className="pb-3 d-flex justify-content-center align-items-center">
              Base Models
            </Modal.Title>
            <hr />
            {baseModels
              ?.filter((model) =>
                model.name.toLowerCase().includes(filterText.toLowerCase())
              ).map((model) => (
                <Row
                  key={model.id}
                  className={`model-row ${selectedBaseModel?.id === model.id ? 'selected' : ''}`}
                  /*onClick={() => setSelectedBaseModel({ id: model.id, name: model.name })}*/
                  onClick={() => onBaseModelSelect(model)}
                >
                  <Col className="pb-4 d-flex justify-content-center align-items-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${baseModels[0].id}`}>{baseModels[0].name}</Tooltip>
                      }
                    >
                      <Image src={baseModels[0].image} />
                    </OverlayTrigger>
                  </Col>
                </Row>
              ))}
          </Col>
          <Col sm="2"></Col>
          <Col sm="5">
            <Modal.Title className="pb-3 d-flex justify-content-center align-items-center">
              Pre-trained Models
            </Modal.Title>
            <hr />
            {modelsData?.models
              ?.filter((model: Model) =>
                model.name.toLowerCase().includes(filterText.toLowerCase())
              )
              .map((model: Model) => (
                <Row
                  key={model.id}
                  className={`model-row ${selectedModel?.id === model.id ? 'selected' : ''}`}
                  onClick={() => setSelectedModel(model)}
                >
                  <Col className="pb-4 d-flex justify-content-center align-items-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-${model.id}`}>{model.name}</Tooltip>}
                    >
                      <Image src="/DALLE-AI-Model-Logo-1.jpg" height={64} />
                    </OverlayTrigger>
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
        {/*/!* Base Model Selection *!/*/}
        {/*{baseModels.length > 0 ? (*/}
        {/*  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>*/}
        {/*    {baseModels*/}
        {/*      .filter((model) => model.name.toLowerCase().includes(filterText.toLowerCase()))*/}
        {/*      .map((model) => (*/}
        {/*        <div*/}
        {/*          key={model.id}*/}
        {/*          style={{*/}
        {/*            padding: '10px',*/}
        {/*            border: selectedBaseModel?.id === model.id ? '2px solid #007bff' : '1px solid #ccc',*/}
        {/*            marginBottom: '10px',*/}
        {/*            borderRadius: '5px',*/}
        {/*            cursor: 'pointer',*/}
        {/*          }}*/}
        {/*          onClick={() => onBaseModelSelect(model)}*/}
        {/*        >*/}
        {/*          {model.name}*/}
        {/*        </div>*/}
        {/*      ))}*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <p>No base models available.</p>*/}
        {/*)}*/}

        {/*  </Modal.Body>*/}
        {/*  <Modal.Footer>*/}
        {/*    <Button variant="secondary" onClick={onClose}>*/}
        {/*      Cancel*/}
        {/*    </Button>*/}
        {/*    <Button variant="primary" onClick={onGenerateNewModel} disabled={!selectedBaseModel}>*/}
        {/*      Generate*/}
        {/*    </Button>*/}
        {/*  </Modal.Footer>*/}
        {/*</Modal>*/}
      </Modal.Body>
      <Modal.Footer className="bg-dark text-white">
        <Button variant="primary" onClick={onGenerateNewModel} disabled={!selectedBaseModel}>
          Launch Model
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewModelModal