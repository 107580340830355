// src/components/TagPills/TagPills.tsx

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getContrastYIQ } from '../utils'

interface BBoxPillsProps {
  confidences: number[]
}

const PillsConfidence: React.FC<BBoxPillsProps> = ({ confidences = [] }) => {

  const [minMaxConfidence, setMinMaxConfidence] = useState<[number, number]>([0, 0])

  useEffect(() => {
    const min = Math.min(...confidences)
    const max = Math.max(...confidences)
    setMinMaxConfidence([min, max])
  }, [confidences])

  return (
    <Row>
      <Col>
        <div
          style={{
            userSelect: 'none',
            backgroundColor: '#800085',
            color: getContrastYIQ('#800085'),
            padding: '0.25rem 0.5rem',
            height: '1.2rem',
            borderRadius: '9999px',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '5rem',
          }}
        >
          <span>Min</span>
          <span
            style={{
              userSelect: 'none',
              backgroundColor: getContrastYIQ('#800085'),
              color: '#800085',
              borderRadius: '50%',
              width: '2rem',
              height: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '0.25rem',
              fontSize: '0.7rem',
            }}
          >
            {(minMaxConfidence[0] * 100).toFixed(0)}%
          </span>
        </div>
      </Col>
      <Col>
        <div
          style={{
            userSelect: 'none',
            backgroundColor: '#800085',
            color: getContrastYIQ('#800085'),
            padding: '0.25rem 0.5rem',
            height: '1.2rem',
            borderRadius: '9999px',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '5rem',
          }}
        >
          <span>Max</span>
          {/*{showTagCounts && tagCounts?.[tagId] && (*/}
          <span
            style={{
              userSelect: 'none',
              backgroundColor: getContrastYIQ('#800085'),
              color: '#800085',
              borderRadius: '50%',
              width: '2rem',
              height: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '0.25rem',
              fontSize: '0.7rem',
            }}
          >
            {(minMaxConfidence[1] * 100).toFixed(0)}%
          </span>
          {/*)}*/}
        </div>
      </Col>
    </Row>
  )
}

export default PillsConfidence
