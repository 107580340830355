// src/store/hooks.ts

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './index'

import { useMemo } from 'react'
import { useGetAnnotationStatisticsQuery } from '../services/annotationApi'
import { useSelectTagsByDataset } from '../services/tagApi'

export const FALLBACK_COLORS = ['#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042']

export interface ClassDistributionItem {
  id: string
  name: string
  value: number
  color: string
  percentage: number
}

export const useClassDistribution = (
  projectId: string,
  datasetId: string,
  useAll: boolean,
  datasource?: string[],
) => {

  const { data: annoStats } = useGetAnnotationStatisticsQuery(
    {
      projectId,
      datasetId,
      metadata_filter: !useAll && datasource && datasource?.length > 0 ? { datasource: datasource } : undefined
    })

  const tags = useSelectTagsByDataset(datasetId)

  const tagIdToInfoMap = useMemo(() => {
    return tags.reduce((acc, tag) => {
      acc[tag.id] = { name: tag.name, color: `#${tag.color.toString(16).padStart(6, '0').slice(0, 6)}` }
      return acc
    }, {} as Record<string, { name: string; color: string }>)
  }, [tags])

  const classDistribution = useMemo(() => {
    if (!annoStats?.statistics?.annotations_per_class) return []

    const distribution = Object.entries(annoStats.statistics.annotations_per_class)
      .map(([key, value]) => ({
        id: key,
        name: tagIdToInfoMap[key]?.name || 'Unknown',
        value: value.count,
        color: tagIdToInfoMap[key]?.color || FALLBACK_COLORS[0],
        balance: annoStats.statistics.annotations_per_class[key]?.balance || 'undetermined',
      }))
      .filter((val) => val.id !== 'null')
      .sort((a, b) => b.value - a.value)

    const maxValue = Math.max(...distribution.map((item) => item.value))

    return distribution.map((item) => ({
      ...item,
      percentage: (item.value / maxValue) * 100,
    }))
  }, [annoStats, tagIdToInfoMap])

  return classDistribution
}

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

import { useState, useEffect } from 'react'
export function useObjectUrl(blob: Blob | undefined) {
  const [objectUrl, setObjectUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!blob) {
      return
    }

    const url = URL.createObjectURL(blob)
    setObjectUrl(url)

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [blob])

  return objectUrl
}
