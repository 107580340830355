// Path: src/components/DatasetSelector.tsx

import React, { useEffect, useState } from 'react'
import { Table, Form, Button, Row, Col, Alert } from 'react-bootstrap'
import {
  selectDatasetSources,
  setDatasetSources,
  selectNewDatasetSource,
  setNewDatasetSource,
} from '../store/slices/datasetSlice'
import { useGetContentMetadataQuery } from '../services/contentApi'
import { ContentMetadataResponse } from '../types/content'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectSelectedProject } from '../store/slices/projectSlice'
import DatasetRow from './tools/DatasetStatsRow'

interface DatasetSelectorProps {
  singleSelect?: boolean
  canCreate: boolean
  handleAcceptSelections: (sources: string[]) => void
  showAcceptButton?: boolean
}

type DatasetPurpose = 'Training' | 'Validation' | 'All'

const DatasetSelector: React.FC<DatasetSelectorProps> = ({
  singleSelect = false,
  canCreate = true,
  showAcceptButton = true,
  handleAcceptSelections,
}) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const datasetSources = useAppSelector(selectDatasetSources)

  const [searchTerm, setSearchTerm] = useState('')
  const [allChecked, setAllChecked] = useState(false)
  const [purposeFilter, setPurposeFilter] = useState<DatasetPurpose>('All')
  const [filteredDatasets, setFilteredDatasets] = useState<string[]>([])

  const [showPurposeAlert, setShowPurposeAlert] = useState<boolean>(false)

  const newDatasetName = useAppSelector(selectNewDatasetSource)

  const { data: metadata } = useGetContentMetadataQuery({
    project_id: project?.id || '',
  }) as { data: ContentMetadataResponse | undefined }

  useEffect(() => {
    const allDatasets = Array.from(
      newDatasetName
        ? new Set([...(metadata?.metadata?.datasource || []), newDatasetName,])
        : new Set([...(metadata?.metadata?.datasource || [])])
    )


    const filteredForName = searchTerm ?
      allDatasets.filter((ds) => {
        return ds.toLowerCase().includes(searchTerm.toLowerCase())
      }) :
      allDatasets

    const filteredForPurpose = purposeFilter === 'All' ?
      filteredForName :
      filteredForName?.filter((source) => {
        return purposeFilter.toLowerCase().startsWith(source.split(':')[1])
      })

    setFilteredDatasets(filteredForPurpose || [])
  }, [metadata?.metadata?.datasource, purposeFilter, searchTerm, newDatasetName])

  
  const handleCreateNew = () => {
    if (canCreate && searchTerm.trim() && !purposeFilter) {
      dispatch(setDatasetSources([searchTerm.trim() + ':t']))
      dispatch(setNewDatasetSource(searchTerm.trim() + ':t'))
      setSearchTerm('')
    } else if (purposeFilter === 'Validation') {
      dispatch(setDatasetSources([searchTerm.trim() + ':v']))
      dispatch(setNewDatasetSource(searchTerm.trim() + ':v'))
      setSearchTerm('')
    } else {
      setShowPurposeAlert(true)
    }
  }

  
  const handleToggleSelection = async (datasetSource: string) => {
    if (!datasetSources.includes(datasetSource)) {
      if (singleSelect) {
        dispatch(setDatasetSources([datasetSource]))
      } else {
        dispatch(setDatasetSources([...datasetSources, datasetSource]))
      }
    } else if (datasetSources.includes(datasetSource)) {
      if (singleSelect) {
        dispatch(setDatasetSources([]))
      } else {
        dispatch(setDatasetSources(datasetSources.filter((s) => s !== datasetSource)))
      }
    } else {
      dispatch(setDatasetSources([datasetSource]))
    }
  }

  const handleAccept = () => {
    handleAcceptSelections(datasetSources)
  }


  const toggleAll = () => {
    const allChecked = metadata?.metadata?.datasource &&
            metadata?.metadata?.datasource.length === datasetSources.length

    if (singleSelect) {
      dispatch(setDatasetSources([]))
    }
    else if (!allChecked) {
      dispatch(setDatasetSources(metadata?.metadata?.datasource || []))
    } else {
      dispatch(setDatasetSources([]))
    }
  }


  return (
    <>
      <Row className="mb-3">
        <Col xs={8}>
          <Form.Control
            type="text"
            placeholder="Search datasets..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                handleCreateNew()
              }
            }}
            className="bg-dark text-white"
          />
        </Col>
        <Col xs={4}>
          <Form.Select
            value={purposeFilter}
            onChange={(e) => setPurposeFilter(e.target.value as DatasetPurpose)}
            onClick={(e) => e.stopPropagation()}
            className="bg-dark text-white"
          >
            <option value="All">All</option>
            <option value="Training">Training</option>
            <option value="Validation">Validation</option>
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col>
          {filteredDatasets.length > 0 ? (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      disabled={singleSelect}
                      type="checkbox"
                      checked={allChecked}
                      onChange={toggleAll}
                    />
                  </th>
                  <th>Dataset Name</th>
                  <th>Classes</th>
                  <th>Images</th>
                  <th>Annotations</th>
                  <th>Type</th>
                </tr>
              </thead>
              {filteredDatasets.map((dataset) => (
                <tbody key={dataset}>
                  <DatasetRow
                    onClick={() => handleToggleSelection(dataset)}
                    datasetSource={dataset}
                    checked={datasetSources.includes(dataset)}
                  />
                </tbody>
              ))}
            </Table>
          ) : (
            searchTerm.trim() && canCreate && (
              <div className="text-center mt-4">
                <p>No matching datasets found.</p>
                <Button variant="primary"
                  onClick={handleCreateNew}
                >
                  Create &quot;{searchTerm.trim()}&quot;
                </Button>
              </div>
            )
          )}
          {showPurposeAlert && (
            <Alert variant="error" onClose={() => setShowPurposeAlert(false)} dismissible>
              <Alert.Heading>Error!</Alert.Heading>
              <p>Must select Training or Validation when creating a new dataset</p>
            </Alert>
          )}
        </Col>
      </Row>

      {showAcceptButton && (
        <Row>
          <Col className="text-end mt-3">
            <Button onClick={handleAccept}>
                Done
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default DatasetSelector