// src/store/slices/modelSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { Model } from '../../types/model'

export interface ModelState {
  selectedModel: Model | undefined
  isLoading: boolean
  error: string | undefined
}

const initialState: ModelState = {
  selectedModel: undefined,
  isLoading: false,
  error: undefined,
}

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setSelectedModel: (state, action: PayloadAction<Model | undefined>) => {
      state.selectedModel = action.payload
      state.isLoading = false
    },
    clearSelectedModel: (state) => {
      state.selectedModel = undefined
      state.isLoading = false
      state.error = undefined
    },
    setModelError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export const {
  setSelectedModel,
  clearSelectedModel,
  setModelError,
} = modelSlice.actions

export const selectSelectedModel = (state: RootState) => state.model.selectedModel
export const selectModelIsLoading = (state: RootState) => state.model.isLoading
export const selectModelError = (state: RootState) => state.model.error

export default modelSlice.reducer
