import React, { useEffect, useRef, useState } from 'react'
import {Card, Button, Nav, Row, Col, Badge, Modal, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Plus } from 'lucide-react'
import {
  useFetchModelsQuery,
  useCreateModelMutation,
} from '../../services/modelsApi'
import {
  setSelectedModel,
  selectSelectedModel
} from '../../store/slices/modelSlice'
import { rateBBoxModel, rateClassificationModel } from '../../utils'
import StarRating from '../StarRating'
import { Model, ModelCreateParams } from '../../types/model'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {selectActiveView, selectSelectedProject, selectActiveInferenceSubView} from '../../store/slices/projectSlice'
import ButtonDeployModel from '../ButtonDeployModel'
import NewModelModal from '../modals/NewModelModal'
import {Exception} from 'sass'
import ConfirmDialog from '../modals/ConfirmDialog'
import PillsDataset from '../PillsDataset'

interface ModelListCardProps {
  allowCreate?: boolean
}

const ModelListCard: React.FC<ModelListCardProps> = ({allowCreate = true}) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)

  const selectedModel = useAppSelector(selectSelectedModel)

  const activeView = useAppSelector(selectActiveView)

  const activeInferenceSubView = useAppSelector(selectActiveInferenceSubView)

  const [showNewModelModal, setShowNewModelModal] = useState<boolean>(false)
  const [selectedBaseModel, setSelectedBaseModel] = useState<{ id: string; name: string } | null>(null)
  const [modalFilterText, setModalFilterText] = useState<string>('') // Added state for modal filter text
  const [canCreateNewModel, setCanCreateNewModel] = useState<boolean>(true)
  const [showNewModelError, setShowNewModelError] = useState<boolean>(false)
  const [newModelErrorMsg, setNewModelErrorMsg] = useState<string>('')

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)

  const [createModel] = useCreateModelMutation()

  const {
    data: modelsData,
    isLoading,
    error,
    refetch: refetchModels,
  } = useFetchModelsQuery({ project_id: project?.id || '' })

  useEffect(() => {
    if (modelsData?.models) {
      pollingIntervalRef.current = setInterval(() => {
        refetchModels()
      }, 5000) // Poll every 5 seconds
    } else if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }

    // For Real Time or Suggestive Labeling views, ensure selected model is IN_SERVICE
    if (activeView === 'inference' && 
        (activeInferenceSubView === 'Real Time' || activeInferenceSubView === 'Suggestive Labeling')) {
      const currentModelInService = selectedModel && 
        modelsData?.models?.find(m => m.id === selectedModel.id)?.deployment?.status === 'IN_SERVICE'

      if (!currentModelInService) {
        // Find first IN_SERVICE model
        const firstInServiceModel = modelsData?.models?.find(m => m.deployment?.status === 'IN_SERVICE')
        if (firstInServiceModel) {
          dispatch(setSelectedModel(firstInServiceModel))
        }
      }
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
        pollingIntervalRef.current = null
      }
    }
  }, [activeInferenceSubView, activeView, dispatch, modelsData, refetchModels, selectedModel])

  useEffect(() => {
    if (modelsData && modelsData.models?.length > 0 && !selectedModel?.id) {
      dispatch(setSelectedModel(modelsData.models[0]))
    }

    if (modelsData?.models?.some((model: Model) => model.state.toLowerCase() === 'initialized' || model.state.toLowerCase() === 'training')) {
      setCanCreateNewModel(false)
    } else {
      setCanCreateNewModel(true)
    }
  }, [dispatch, modelsData, modelsData?.models, selectedModel?.id])

  const generateModelName = () => {
    const uid = Math.random().toString(36).substring(2, 4)
    const date = new Date().toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })
    return `${uid} - ${date}`
  }

  const handleGenerateNewModel = async () => {
    if (project && selectedBaseModel) {
      try {
        const newModelName = `${generateModelName()} (${selectedBaseModel.name})`
        const newModel = await createModel({
          project_id: project.id,
          name: newModelName,
          content_sources: [],
        } as ModelCreateParams)
          .unwrap()
        dispatch(setSelectedModel(newModel))
        setShowNewModelModal(false)
      } catch (err: any) {
        setShowNewModelError(true)
        setNewModelErrorMsg(err?.data?.message || 'Unknown error')
        console.error('Failed to create new model:', err)
      }
    }
  }

  const bBoxRating = (model: Model) => {
    return rateBBoxModel(model)
  }

  const classificationRating = (model: Model) => {
    return rateClassificationModel(model)
  }

  const handleSelectModel = (model: Model) => {
    dispatch(setSelectedModel(model))
  }

  const baseModels = [
    { id: 'baseModel1', name: 'AG Base', image: '/agileview-eye.png' },
  ]

  if (isLoading) return <div>Loading models...</div>
  if (error) return <div>Error loading models</div>

  return (
    <>
      <ConfirmDialog
        show={showNewModelError}
        onHide={() => setShowNewModelError(false)}
        onConfirm={() => setShowNewModelError(false)}
        title="Error Creating Model"
        message={newModelErrorMsg}
        confirmText="Okay"
        variant="primary"
      />
      <Card className="model-tools" style={{ borderRadius: '10px'}}>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h3>Models</h3>
          </div>

          { allowCreate && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id='tooltip-create-new-model'>{canCreateNewModel ? 'Create new model' : 'Must finish initializing model first'}</Tooltip>
              }
            >
              <Button className="generate-model-btn w-100 mb-3" onClick={() => canCreateNewModel && setShowNewModelModal(true)}>
                <Plus size={16} /> New Model
              </Button>
            </OverlayTrigger>
          )}

          <Nav variant="pills" className="flex-column model-list">
            {modelsData?.models?.filter((model:Model) => {
              return activeView !== 'inference' || (activeView === 'inference' && model.state !== 'INITIALIZED')
            }).map((model: Model) => (
              <Nav.Item key={model.id} className={'model-item'}>
                <Nav.Link
                  eventKey={model.id}
                  active={model.id === selectedModel?.id}
                  onClick={() => handleSelectModel(model)}
                >
                  <div className="model-header">
                    {/* Banner for the Model Name */}
                    <div className="model-banner">
                      <span>{model.name}</span>
                    </div>

                    {/* Stars in the Top Right */}
                    {model.state === 'TRAINED' && (
                      <div className="star-rating-container">
                        {project?.annotation_type === 'classification' && (
                          <div className="star-rating">
                            <StarRating rating={classificationRating(model).classificationRating} />
                          </div>
                        )}
                        {project?.annotation_type === 'bounding_box' && (
                          <div className="star-rating">
                            <StarRating rating={bBoxRating(model).bBoxRating} />
                          </div>
                        )}

                      </div>
                    )}
                  </div>

                  {/* Model date directly under the banner and mAP to the right */}
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="model-date">{new Date(model.created_at).toLocaleString()}</div>
                  </div>

                  <Row className="d-flex justify-content-between align-items-center">
                    <Col>
                      <div className="model-state-container d-flex align-items-center">
                        {/* Show nothing if model is in INITIALIZED state */}
                        {model.state === 'INITIALIZED' && (
                          <Badge pill className="badge-initialized">
                              Initialized
                          </Badge>
                        )}

                        {model.state === 'TRAINING' && (
                          <Badge pill className="badge-training">
                            Training
                          </Badge>
                        )}

                        { model.state === 'TRAINED' && (
                          model.deployment?.status === 'UNKNOWN' ||
                          model.deployment?.status === 'INITIALIZED' ||
                          model.deployment?.status === ''
                        ) && (
                          <Badge pill className="badge-training">
                            Trained
                          </Badge>
                        )}

                        {(model.state === 'ERR' ||
                          model.deployment?.status === 'ERR') && (
                          <Badge pill className="badge-danger">ERROR</Badge>
                        )}

                        {/* Show "Deploying" if deployment is CREATING */}
                        {model.state === 'TRAINED' && model.deployment?.status === 'CREATING' && (
                          <Badge pill className="badge-deploying">
                            Deploying
                          </Badge>
                        )}

                        {/* Show "Deployed" if deployment is IN_SERVICE */}
                        {model.state === 'TRAINED' && model.deployment?.status === 'IN_SERVICE' && (
                          <Badge pill className="badge-deployed">
                            Deployed
                          </Badge>
                        )}

                        {/* Show "Trained" if model is trained and not in deployment process */}
                        {model.state === 'TRAINED' && model.deployment?.status === 'DELETED' && (
                          <Badge pill className="badge-training">
                            Trained
                          </Badge>
                        )}
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <ButtonDeployModel model={model} />
                    </Col>
                  </Row>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Body>
      </Card>


      <NewModelModal
        show={showNewModelModal}
        onClose={() => setShowNewModelModal(false)}
        baseModels={baseModels}
        filterText={modalFilterText}
        setFilterText={setModalFilterText}
        selectedBaseModel={selectedBaseModel}
        onBaseModelSelect={setSelectedBaseModel}
        onGenerateNewModel={handleGenerateNewModel}
      />

      <Modal
        className="model-modal"
        size="lg"
        show={false}
        onHide={() => setShowNewModelModal(false)}
      >
        <Modal.Header className="bg-dark text-white" closeButton>
          <Modal.Title>New Model</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white">
          <Row className="mb-3">
            <Col>
              {/* Added filter input box */}
              <div className="d-flex justify-content-start mb-3">
                <input
                  type="text"
                  placeholder="Filter models..."
                  value={modalFilterText}
                  onChange={(e) => setModalFilterText(e.target.value)}
                  className="model-filter-input"
                />
              </div>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Button onClick={() => setShowNewModelModal(false)}>
                <Plus size='32px' color="white"/>
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm="5">
              <Modal.Title className="pb-3 d-flex justify-content-center align-items-center">
                Base Models
              </Modal.Title>
              <hr />
              {baseModels
                ?.filter((model) =>
                  model.name.toLowerCase().includes(modalFilterText.toLowerCase())
                ).map((model) => (
                  <Row
                    key={model.id}
                    className={`model-row ${selectedBaseModel?.id === baseModels[0].id ? 'selected' : ''}`}
                    onClick={() => setSelectedBaseModel({ id: model.id, name: model.name })}
                  >
                    <Col className="pb-4 d-flex justify-content-center align-items-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${baseModels[0].id}`}>{baseModels[0].name}</Tooltip>
                        }
                      >
                        <Image src={baseModels[0].image} />
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ))}
            </Col>
            <Col sm="2"></Col>
            <Col sm="5">
              <Modal.Title className="pb-3 d-flex justify-content-center align-items-center">
                Pre-trained Models
              </Modal.Title>
              <hr />
              {modelsData?.models
                ?.filter((model: Model) =>
                  model.name.toLowerCase().includes(modalFilterText.toLowerCase())
                )
                .map((model: Model) => (
                  <Row
                    key={model.id}
                    className={`model-row ${selectedModel?.id === model.id ? 'selected' : ''}`}
                    onClick={() => setSelectedModel(model)}
                  >
                    <Col className="pb-4 d-flex justify-content-center align-items-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${model.id}`}>{model.name}</Tooltip>}
                      >
                        <Image src="/DALLE-AI-Model-Logo-1.jpg" height={64} />
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ))}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="bg-dark text-white">
          <Button variant="primary" onClick={handleGenerateNewModel}>
            Launch Model
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModelListCard
