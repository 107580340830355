import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { enableMapSet } from 'immer'
import { AnnotationDataBoundingBox, AnnotationPostResponse } from '../../types/annotation'
import { Content } from '../../types/content'
import { normalizeBoundingBox } from '../../utils'

// Enable support for Map and Set in Immer
enableMapSet()

export enum ViewType {
  Grid = 'grid',
  List = 'list',
  Slideshow = 'slideshow'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

const ZOOM_LIMITS = {
  MIN: 1,
  MAX: 5
} as const


export interface UndoStackItem {
  rejected: number[],
  accepted: number[],
  all: AnnotationDataBoundingBox[]
}


export interface AnnotationState {
  sortKey: string
  sortOrder: SortOrder
  view: ViewType
  zoomLevel: number
  annotatedContentIds: string[]
  selectedContentId: string

  undoStack: UndoStackItem[]
  redoStack: UndoStackItem[]

  allBoundingBoxes: AnnotationDataBoundingBox[]
  createdBoundingBoxes: AnnotationDataBoundingBox[]
  acceptedBoundingBoxIndexes: number[]
  rejectedBoundingBoxIndexes: number[]
  selectedBoundingBoxIndex: number | undefined

  initialBoundingBoxes: AnnotationDataBoundingBox[]
  editingTagIds: string[]
  annotatedPage: number
  unannotatedPage: number
  predictionsPage: number
  editingContent: Content | undefined
}

const initialState: AnnotationState = {
  sortKey: 'created_at',
  sortOrder: SortOrder.Desc,
  view: ViewType.Grid,
  zoomLevel: 2,
  annotatedContentIds: [],
  selectedContentId: '',

  undoStack: [],
  redoStack: [],

  allBoundingBoxes: [],
  createdBoundingBoxes: [],
  acceptedBoundingBoxIndexes:[],
  rejectedBoundingBoxIndexes: [],
  selectedBoundingBoxIndex: undefined,

  initialBoundingBoxes: [],
  editingTagIds: [],
  annotatedPage: 1,
  unannotatedPage: 1,
  predictionsPage: 1,
  editingContent: undefined,
}

const updateUniqueTagIds = (tagIds: string[], newTagId: string): string[] => {
  const updatedTagIds = new Set(tagIds)
  updatedTagIds.add(newTagId)
  return Array.from(updatedTagIds)
}

const annotationSlice = createSlice({
  name: 'annotation',
  initialState,
  reducers: {

    setSelectedBoundingBoxIndex: (state, action: PayloadAction<number | undefined>) => {
      state.selectedBoundingBoxIndex = action.payload
    },

    setEditingContent: (state, action: PayloadAction<Content>) => {
      const { b64_image: omitted, ...rest } = action.payload
      state.editingContent = rest
    },
    clearEditingContent: (state) => {
      state.editingContent = undefined
    },


    clearUndoStack: (state) => {
      state.undoStack = []
    },
    pushUndoStackItem:  (state, action: PayloadAction<UndoStackItem>) => {
      state.undoStack = [ action.payload, ...state.undoStack]
    },
    popUndoStackItem: (state) => {
      state.undoStack = state.undoStack.slice(1)
    },


    clearRedoStack: (state) => {
      state.redoStack = []
    },
    pushRedoStackItem:  (state, action: PayloadAction<UndoStackItem>) => {
      state.redoStack = [ action.payload, ...state.redoStack]
    },
    popRedoStackItem: (state) => {
      state.redoStack = state.redoStack.slice(1)
    },


    addAcceptedBoundingBoxByIndex: (state, action: PayloadAction<number>) => {
      state.acceptedBoundingBoxIndexes = Array.from(new Set([...state.acceptedBoundingBoxIndexes, action.payload]))
      state.rejectedBoundingBoxIndexes = state.rejectedBoundingBoxIndexes.filter((index) => index !== action.payload)
    },

    setAcceptedBoundingBoxes: (state, action: PayloadAction<number[]>) => {
      state.acceptedBoundingBoxIndexes = Array.from(new Set(action.payload))
    },

    removeAcceptedBoundingBoxByIndex: (state, action: PayloadAction<number>) => {
      state.acceptedBoundingBoxIndexes = state.acceptedBoundingBoxIndexes.filter((index) => index !== action.payload)
    },


    addRejectedBoundingBoxByIndex: (state, action: PayloadAction<number>) => {
      state.rejectedBoundingBoxIndexes = Array.from(new Set([...state.rejectedBoundingBoxIndexes, action.payload]))
      state.acceptedBoundingBoxIndexes = state.acceptedBoundingBoxIndexes.filter((index) => index !== action.payload)
    },
    setRejectedBoundingBoxes: (state, action: PayloadAction<number[]>) => {
      state.rejectedBoundingBoxIndexes = Array.from(new Set(action.payload))
    },
    removeRejectedBoundingBoxByIndex: (state, action: PayloadAction<number>) => {
      state.rejectedBoundingBoxIndexes = state.rejectedBoundingBoxIndexes.filter((index) => index !== action.payload)
    },


    setAnnotatedPage: (state, action: PayloadAction<number>) => {
      state.annotatedPage = action.payload
    },

    setPredictionsPage: (state, action: PayloadAction<number>) => {
      state.predictionsPage = action.payload
    },

    setUnannotatedPage: (state, action: PayloadAction<number>) => {
      state.unannotatedPage = action.payload
    },

    setSelectedContentId: (state, action: PayloadAction<string>) => {
      state.selectedContentId = action.payload
    },

    clearSelectedContentId: (state) => {
      state.selectedContentId = ''
    },

    setSortKey: (state, action: PayloadAction<string>) => {
      state.sortKey = action.payload
    },
    toggleSortOrder: (state) => {
      state.sortOrder = state.sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc
    },

    zoomIn: (state) => {
      state.zoomLevel = Math.min(state.zoomLevel + 1, ZOOM_LIMITS.MAX) // Max zoom level
    },

    zoomOut: (state) => {
      state.zoomLevel = Math.max(state.zoomLevel - 1, 1) // Min zoom level
    },

    addAnnotatedContentIds: (state, action: PayloadAction<string[]>) => {
      const newIds = action.payload
      state.annotatedContentIds = Array.from(new Set([...state.annotatedContentIds, ...newIds]))
    },

    clearAnnotatedContentIds: (state) => {
      state.annotatedContentIds = []
    },

    updateAnnotation: (state, action: PayloadAction<AnnotationPostResponse>) => {
      // state.annotations.push(action.payload)
      console.log(`Updating annotation ${action.payload.id}`)
    },

    setAllBoundingBoxes: (state, action: PayloadAction<AnnotationDataBoundingBox[]>) => {
      state.allBoundingBoxes = [...action.payload]
    },

    clearAllBoundingBoxes: (state) => {
      state.allBoundingBoxes = []
      state.acceptedBoundingBoxIndexes = []
      state.rejectedBoundingBoxIndexes = []
    },

    setInitialBoundingBoxes: (state, action: PayloadAction<AnnotationDataBoundingBox[]>) => {
      state.initialBoundingBoxes = action.payload.map(normalizeBoundingBox)
    },

    clearInitialBoundingBoxes: (state) => {
      state.initialBoundingBoxes = []
    },

    resetAllBoundingBoxes: (state) => {
      state.allBoundingBoxes = [...state.initialBoundingBoxes]
    },

    addBoundingBox: (state, action: PayloadAction<AnnotationDataBoundingBox>) => {
      if (state.allBoundingBoxes) {
        state.allBoundingBoxes = [
          ...state.allBoundingBoxes,
          { ...action.payload },
        ]
      } else {
        state.allBoundingBoxes = [{ ...action.payload }]
      }
      state.editingTagIds = updateUniqueTagIds(state.editingTagIds, action.payload.name)
    },

    updateBoundingBox: (state, action: PayloadAction<{ updatedBBox: AnnotationDataBoundingBox }>) => {
      const { updatedBBox } = action.payload
      const index = updatedBBox.class_index
      if (index !== undefined && index !== -1) {
        state.allBoundingBoxes[index] = updatedBBox
      }
    },

    // Create: Add a new tag ID
    addEditingTagId: (state, action: PayloadAction<string>) => {
      if (!state.editingTagIds.includes(action.payload)) {
        state.editingTagIds.push(action.payload)
      }
    },

    // Read: Selector for editingTagIds is defined outside the slice

    // Update: Set all editing tag IDs
    setEditingTagIds: (state, action: PayloadAction<string[]>) => {
      state.editingTagIds = action.payload
    },

    // Delete: Remove a specific tag ID
    removeEditingTagId: (state, action: PayloadAction<string>) => {
      state.editingTagIds = state.editingTagIds.filter((id) => id !== action.payload)
    },

    // Clear all editing tag IDs
    clearEditingTagIds: (state) => {
      state.editingTagIds = []
    },
  },

})

export const {
  setSortKey,
  toggleSortOrder,
  zoomIn,
  zoomOut,
  addAnnotatedContentIds,
  clearAnnotatedContentIds,
  updateAnnotation,

  pushUndoStackItem,
  popUndoStackItem,
  clearUndoStack,

  pushRedoStackItem,
  popRedoStackItem,
  clearRedoStack,

  setSelectedBoundingBoxIndex,

  setAllBoundingBoxes,
  clearAllBoundingBoxes,
  resetAllBoundingBoxes,

  setAcceptedBoundingBoxes,
  addAcceptedBoundingBoxByIndex,
  removeAcceptedBoundingBoxByIndex,

  setRejectedBoundingBoxes,
  addRejectedBoundingBoxByIndex,
  removeRejectedBoundingBoxByIndex,

  setInitialBoundingBoxes,
  clearInitialBoundingBoxes,

  updateBoundingBox,
  addBoundingBox,
  addEditingTagId,
  setEditingTagIds,
  removeEditingTagId,
  clearEditingTagIds,
  setSelectedContentId,
  clearSelectedContentId,
  setAnnotatedPage,
  setUnannotatedPage,
  setPredictionsPage,
  setEditingContent,
  clearEditingContent,
} = annotationSlice.actions

export const selectSortKey = (state: RootState) => state.annotation.sortKey
export const selectSortOrder = (state: RootState) => state.annotation.sortOrder
export const selectView = (state: RootState) => state.annotation.view
export const selectZoomLevel = (state: RootState) => state.annotation.zoomLevel
export const selectAnnotatedContentIds = (state: RootState) => state.annotation.annotatedContentIds
export const selectRejectedBoundingBoxIndexes = (state: RootState) => state.annotation.rejectedBoundingBoxIndexes
export const selectAcceptedBoundingBoxIndexes = (state: RootState) => state.annotation.acceptedBoundingBoxIndexes
export const selectInitialBoundingBoxes = (state: RootState) => state.annotation.initialBoundingBoxes
export const selectSelectedContentId = (state: RootState) => state.annotation.selectedContentId
export const selectEditingTagIds = (state: RootState) => state.annotation.editingTagIds
export const selectAnnotatedPage = (state: RootState) => state.annotation.annotatedPage
export const selectPredictionsPage = (state: RootState) => state.annotation.predictionsPage
export const selectUnannotatedPage = (state: RootState) => state.annotation.unannotatedPage
export const selectEditingContent = (state: RootState) => state.annotation.editingContent
export const selectTopUndoStackItem = (state: RootState) => state.annotation.undoStack?.[0]
export const selectTopRedoStackItem = (state: RootState) => state.annotation.redoStack?.[0]
export const selectedBoundingBoxIndex = (state: RootState) => state.annotation.selectedBoundingBoxIndex

export const selectFilteredBoundingBoxesNew =
    (state: RootState) => {
      return state.annotation.allBoundingBoxes.filter((box) => !state.annotation.rejectedBoundingBoxIndexes.includes(box.class_index))
    }

export const selectAllBoundingBoxes = (state: RootState) => state.annotation.allBoundingBoxes

export const selectAllUntouchedBoundingBoxes =
    (state: RootState) => {
      return state.annotation.allBoundingBoxes.filter((box) =>
        !state.annotation.rejectedBoundingBoxIndexes.includes(box.class_index)
          && !state.annotation.acceptedBoundingBoxIndexes.includes(box.class_index))
    }

export const selectRejectedBoundingBoxes =
    (state: RootState) => {
      return state.annotation.allBoundingBoxes.filter((box) => state.annotation.rejectedBoundingBoxIndexes.includes(box.class_index))
    }

export const selectAcceptedBoundingBoxes =
    (state: RootState) => {
      return state.annotation.allBoundingBoxes.filter((box) => state.annotation.acceptedBoundingBoxIndexes.includes(box.class_index))
    }

export default annotationSlice.reducer
