// Path: /home/jason/src/AgileView/autovision-ui/src/components/tools/InferenceTools.tsx

import React from 'react'

import {useAppSelector} from '../../store/hooks'
import { selectActiveInferenceSubView} from '../../store/slices/projectSlice'
import ModelListCard from './ModelListCard'
import SelectedDatasetsCard from '../SelectedDatasetsCard'

const InferenceTools: React.FC = () => {
  const activeInferenceSubView = useAppSelector(selectActiveInferenceSubView)

  switch (activeInferenceSubView) {
    case 'Real Time':
      return (
        <ModelListCard allowCreate={false}/>
      )
    case 'Map (Coming Soon)':
      return (
        <ModelListCard allowCreate={false} />
      )
    case 'Suggestive Labeling':
      return (
        <>
          <SelectedDatasetsCard singleSelect={false} />
          <ModelListCard allowCreate={false}/>
        </>
      )
    default:
      return null
  }
}

export default InferenceTools
