// src/pages/Projects.tsx

import React, { useState } from 'react'
import { Button, Container, Form, Row } from 'react-bootstrap'
import { useFetchUserProfileQuery } from '../services/authApi'
import ProjectCard from '../components/ProjectCard'
import { Project } from '../types/project'
import { useFetchProjectsQuery } from '../services/projectsApi'
import CPWizard from '../components/wizards/CPWizard'

const Projects: React.FC = () => {
  const { data: user } = useFetchUserProfileQuery()
  const [searchTerm, setSearchTerm] = useState('')
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { data: projectsData } = useFetchProjectsQuery({})

  const filteredProjects = projectsData?.projects?.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <Container fluid className="project-details">
      <Row style={{height:'8vh'}}>
        <h1>Hi {user?.first_name}</h1>
        <p className={'subtitle'}>HERE ARE YOUR PROJECTS</p>
      </Row>
      <Row style={{height:'8vh'}}>
        <div className="search-create-container">
          <Form.Control
            type="text"
            placeholder="Search Projects"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button variant="primary" onClick={() => setShowCreateModal(true)}>
          Create New Project
          </Button>
        </div>
      </Row>
      <Row style={{maxHeight: '65vh', overflow: 'scroll'}}>
        {filteredProjects &&
          filteredProjects.map((project: Project, index: number) =>
            <ProjectCard key={index} project={project} />
          )}
      </Row>
      <CPWizard show={showCreateModal} onHide={() => setShowCreateModal(false)} />
    </Container>
  )
}

export default Projects
