import React, { useState, useEffect } from 'react'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import L, {FeatureGroup, LatLngBounds, LatLngTuple} from 'leaflet'
import 'leaflet-draw'
// import BoundingBoxModal from '../modals/BoundingBoxModal'
// import ProjectSidebar from './sidebars/ProjectSidebar'
// import AiSidebar from './sidebars/AiSidebar'
// import ToolSidebar from './sidebars/ToolSidebar'
// import PageSidebar from './sidebars/PageSidebar'
// import RunInferenceModal from './modals/RunInferenceModal'
// import { fetchProjects, fetchModels, createModel, deleteModel, runInference } from '../services/ApiService'
// import {selectSelectedLayers, selectSelectedProject, updateProjectLayer} from '../slices/appSlice'
// import {useAppDispatch, useAppSelector} from '../slices/hooks'
// import {Model, Project} from '../types/types'
import {clearSelectedModel} from '../../../store/slices/modelSlice'
import {modelsApi} from '../../../services/modelsApi'
import {Project} from '../../../types/project'
import {Model} from '../../../types/model'
import {useAppDispatch, useAppSelector} from '../../../store/hooks'
import {selectSelectedProject} from '../../../store/slices/projectSlice'

const MapComponent: React.FC = () => {
  const dispatch = useAppDispatch()

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [inferenceModalIsOpen, setInferenceModalIsOpen] = useState<boolean>(false)
  const [bboxBounds, setBboxBounds] = useState<LatLngBounds | null>(null)
  const [drawnItems, setDrawnItems] = useState<FeatureGroup<any> | null>(null)
  const [projects, setProjects] = useState<Project[]>([])
  const [models, setModels] = useState<Model[]>([])

  const [map, setMap] = useState<any>(null)

  const [activeSidebar, setActiveSidebar] = useState('projects')
  // const selectedLayers = useAppSelector(selectSelectedLayers)

  const selectedProject = useAppSelector(selectSelectedProject)

  useEffect(() => {
    // fetchProjects()
    //   .then(data => setProjects(data))
    //   .catch(error => console.error('Error fetching projects:', error))
    //
    // fetchModels()
    //   .then(data => setModels(data))
    //   .catch(error => console.error('Error fetching models:', error))

    const northSouthBounds: LatLngTuple[] = [
      [-85, -Infinity],
      [85, Infinity]
    ]

    const mapInstance = L.map('map', {
      maxBounds: northSouthBounds,
      maxBoundsViscosity: 1.0,
      minZoom: 3,
      maxZoom: 50,
      zoomControl: false
    }).setView([39.8283, -98.5795], 3)

    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(mapInstance)

    const drawnItemsLayer = new L.FeatureGroup()
    setDrawnItems(drawnItemsLayer)
    mapInstance.addLayer(drawnItemsLayer)

    setMap(mapInstance)

    // mapInstance.on(L.Draw.Event.CREATED, function (event:any) {
    //   const layer = event.layer
    //
    //   if (layer) {
    //     drawnItemsLayer.clearLayers()
    //     drawnItemsLayer.addLayer(layer)
    //
    //     if (layer instanceof L.Rectangle) {
    //       const bounds = layer.getBounds()
    //       setBboxBounds(bounds)
    //       setModalIsOpen(true)
    //     }
    //   }
    // })

    return () => {
      mapInstance.remove()
    }
  }, [])
  //
  // const closeModal = () => {
  //   setModalIsOpen(false)
  //   if (drawnItems) {
  //     drawnItems.clearLayers()
  //   }
  //   setBboxBounds(null)
  // }

  // const handleDrawRectangle = () => {
  //   if (map) {
  //     const drawRectangle = new L.Draw.Rectangle(map, {
  //       shapeOptions: {
  //         color: '#ff0000',
  //         weight: 2,
  //         opacity: 0.8
  //       }
  //     })
  //     drawRectangle.enable()
  //   }
  // }


  // const handleSidebarSwitch = (sidebar: string) => {
  //   setActiveSidebar(sidebar)
  // }

  // const handleAddModel = async (newModel: Model) => {
  //   try {
  //     const createdModel = await createModel(newModel)
  //     setModels([...models, createdModel])
  //   } catch(error) {
  //
  //     console.error('Error creating model:', error)
  //   }
  // }

  // const handleDeleteModel = async (modelId: string | undefined) => {
  //   if (modelId) {
  //     await deleteModel(modelId)
  //     dispatch(clearSelectedModel())
  //     dispatch(modelsApi.util.invalidateTags([{ type: 'Model', id: 'LIST' }]))
  //   }
  // }
  //
  // const handleRunInference = async (modelId: string, layerIds: string[]) => {
  //   console.log('handleRunInference function called with:', modelId, layerIds)
  //   try {
  //     const response = await runInference(modelId, layerIds)
  //     console.log('Inference result:', response)
  //     dispatch(updateProjectLayer(response))
  //
  //     setInferenceModalIsOpen(false)
  //   } catch (error) {
  //     console.error('Error during inference:', error)
  //   }
  // }
  //
  // const handleInferenceClick = () => {
  //   if (selectedLayers?.length > 0) {
  //     setInferenceModalIsOpen(true)
  //   } else {
  //     console.warn('No layers selected. Inference modal cannot be opened.')
  //     alert('Please select at least one layer before running inference.')
  //   }
  // }

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100vw' }}>
      {/*<PageSidebar onSidebarSwitch={handleSidebarSwitch} />*/}
      {/*{activeSidebar === 'projects' && (*/}
      {/*  <ProjectSidebar*/}
      {/*    map={map}*/}
      {/*    projects={projects}*/}
      {/*    setProjects={setProjects}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{activeSidebar === 'ai' && (*/}
      {/*  <AiSidebar*/}
      {/*    models={models}*/}
      {/*    onAddModel={handleAddModel}*/}
      {/*    onDeleteModel={handleDeleteModel}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{selectedProject && (*/}
      {/*  <ToolSidebar*/}
      {/*    onDrawRectangle={handleDrawRectangle}*/}
      {/*    onRunInference={handleInferenceClick} // Check before opening inference modal*/}
      {/*  />*/}
      {/*)}*/}
      <div style={{ height: '100%', width: '100%' }}>
        <div id="map" style={{ height: '100%', width: '100%' }} />
      </div>
      {/*<BoundingBoxModal*/}
      {/*  isOpen={modalIsOpen}*/}
      {/*  onRequestClose={closeModal}*/}
      {/*  bboxBounds={bboxBounds}*/}
      {/*/>*/}
      {/*<RunInferenceModal*/}
      {/*  showModal={inferenceModalIsOpen}*/}
      {/*  models={models}*/}
      {/*  handleRunInference={handleRunInference}*/}
      {/*  handleCloseModal={() => setInferenceModalIsOpen(false)}*/}
      {/*/>*/}
    </div>
  )
}

export default MapComponent// Path: /home/jason/src/AgileView/autovision-ui/src/components/views/subviews/MapComponent.tsx