import React from 'react'

interface GaugeMetricProps {
  metricName: string
  metricValue: number
  minValue?: number
  maxValue?: number
  small?: boolean
}

// Metric thresholds for good/bad logic
const metricThresholds = {
  mAP: {
    better: 'higher',
    thresholds: {
      bad: 50, // Below 50% is bad
      average: 70, // Between 50% and 75% is average
      good: 100, // Above 75% is good
    },
  },
  'Cross Entropy': {
    better: 'lower',
    thresholds: {
      good: 0.5, // Below 0.5 is good
      average: 1.0, // Between 0.5 and 1.0 is average, anything higher is bad
    },
  },
  'F1 Score': {
    better: 'higher',
    thresholds: {
      bad: 50,
      average: 70, // Between 0.5 and 1.0 is average, anything higher is bad
      good: 100, // Above .7 is good
    },
  },
  'Precision': {
    better: 'higher',
    thresholds: {
      bad: 50,
      average: 85, // Between 0.5 and 1.0 is average, anything higher is bad
      good: 100, // Above .7 is good
    },
  },
  'Recall': {
    better: 'higher',
    thresholds: {
      bad: 50,
      average: 85, // Between 0.5 and 1.0 is average, anything higher is bad
      good: 100, // Below 0.5 is good
    },
  },
  'Smooth L1': {
    better: 'lower',
    thresholds: {
      good: 0.1, // Below 0.1 is good
      average: 0.5, // Between 0.2 and 0.5 is average, anything higher is bad
    },
  },
  'Validation Accuracy': {
    better: 'higher',
    thresholds: {
      bad: 50, // Below 50% is bad
      average: 75, // Between 50% and 75% is average
      good: 100, // Above 75% is good
    },
  },
  'Train Accuracy': {
    better: 'higher',
    thresholds: {
      bad: 50, // Below 50% is bad
      average: 75, // Between 50% and 75% is average
      good: 100, // Above 75% is good
    },
  },
}

// Metric name mapping
const metricNameMap: { [key: string]: string } = {
  'calculated:f1': 'F1 Score',
  'train:cross_entropy': 'Cross Entropy',
  'train:smooth_l1': 'Smooth L1',
  'validation:mAP50-95': 'mAP',
  'validation:precision': 'Precision',
  'validation:recall': 'Recall',
  'validation:accuracy': 'Validation Accuracy',
  'train:accuracy': 'Train Accuracy',
}

// Function to convert metric keys to human-readable names
const convertMetricName = (key: string): string => {
  return metricNameMap[key] || key
}

// Function to dynamically generate segments based on thresholds
const getColorSegments = (metricName: string) => {
  const readableMetricName = convertMetricName(metricName) as keyof typeof metricThresholds
  const thresholds = metricThresholds[readableMetricName]?.thresholds

  if (!thresholds) return []

  if (metricThresholds[readableMetricName].better === 'higher') {
    // Higher is better (e.g., mAP)
    return [
      { color: '#FF4500', range: 'bad' in thresholds ? thresholds.bad / 100 : 0 }, // Red for bad (below 50%)
      {
        color: '#FFD700',
        range: 'bad' in thresholds ? (thresholds.average - thresholds.bad) / 100 : thresholds.average / 100,
      }, // Yellow for average
      {
        color: '#32CD32',
        range: thresholds.good ? (thresholds.good - thresholds.average) / 100 : 1 - thresholds.average / 100,
      }, // Green for good
    ]
  } else {
    // Lower is better (e.g., Cross Entropy, Smooth L1)
    const maxRange = thresholds.average // Assume the max value is capped at the 'average' threshold
    return [
      { color: '#32CD32', range: thresholds.good / maxRange }, // Green for good (below 0.5)
      { color: '#FFD700', range: (thresholds.average - thresholds.good) / maxRange }, // Yellow for average (0.5 - 1.0)
      { color: '#FF4500', range: 1 - thresholds.average / maxRange }, // Red for bad (anything higher than average)
    ]
  }
}



const GaugeMetric: React.FC<GaugeMetricProps> = ({
  metricName,
  metricValue,
  minValue = 0,
  maxValue = 100,
  small = false,
}) => {
  const percentValue = (metricValue - minValue) / (maxValue - minValue)

  // Get the dynamic color segments based on the metric type
  const segments = getColorSegments(metricName)

  // Adjust sizes based on the `small` prop
  const sizeMultiplier = small ? 0.5 : 1
  const svgWidth = 200 * sizeMultiplier
  const svgHeight = 120 * sizeMultiplier
  const radius = 90 * sizeMultiplier
  const needleLength = 80 * sizeMultiplier
  const centerX = 100 * sizeMultiplier // Horizontal center
  const centerY = 100 * sizeMultiplier // Shifted down slightly for extra top space
  const centerCircleRadius = 5 * sizeMultiplier

  const getSegmentPath = (startAngle: number, endAngle: number) => {
    const startX = centerX + radius * Math.cos(Math.PI * (1 - startAngle))
    const startY = centerY - radius * Math.sin(Math.PI * (1 - startAngle))
    const endX = centerX + radius * Math.cos(Math.PI * (1 - endAngle))
    const endY = centerY - radius * Math.sin(Math.PI * (1 - endAngle))
    return `M ${startX} ${startY} A ${radius} ${radius} 0 0 1 ${endX} ${endY}`
  }

  let prevAngle = 0

  return (
    <div className="gauge-metric">
      <h5>{convertMetricName(metricName)}</h5>
      <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight + 20 * sizeMultiplier}`}>

        {/* Render color segments based on thresholds */}
        {segments.map((segment, idx) => {
          const startAngle = prevAngle
          const endAngle = prevAngle + segment.range
          prevAngle = endAngle
          return (
            <path
              key={idx}
              d={getSegmentPath(startAngle, endAngle)}
              fill="none"
              stroke={segment.color}
              strokeWidth={10 * sizeMultiplier}
            />
          )
        })}

        {/* Needle */}
        <line
          x1={centerX}
          y1={centerY}
          x2={centerX + needleLength * Math.cos(Math.PI * (1 - percentValue))}
          y2={centerY - needleLength * Math.sin(Math.PI * (1 - percentValue))}
          className="needle"
        />

        {/* Center circle */}
        <circle cx={centerX} cy={centerY} r={centerCircleRadius} className="center-circle"/>
      </svg>
      <div className="metric-value">{metricValue.toFixed(2)}%</div>
    </div>
  )
}

export default GaugeMetric
