import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { Model } from '../types/model'
import { rateBBoxModel } from '../utils'
import StarRating from './StarRating'
import GaugeMetric from './GaugeMetric'
import ClassDistributionCard from './ClassDistributionCard'
import { useAppSelector} from '../store/hooks'
import {selectSelectedModel} from '../store/slices/modelSlice'
import TrainingValidationSetsCard from './TrainingValidationSetsCard'

const ModelMetricsCard: React.FC = () => {
  const model = useAppSelector(selectSelectedModel)

  const { metrics, train_started_at, train_ended_at, deployment } = model || {}

  // Calculate Train Time using native JavaScript methods
  const trainStart = train_started_at ? new Date(train_started_at) : null
  const trainEnd = train_ended_at ? new Date(train_ended_at) : null

  const { bBoxRating } = rateBBoxModel(model || ({} as Model))

  let trainTime = 'N/A'
  if (trainEnd && trainStart) {
    if (train_ended_at === '0001-01-01T00:00:00Z') {
      trainTime = 'In Progress'
    } else {
      const durationMs = trainEnd.getTime() - trainStart.getTime()
      const hours = Math.floor(durationMs / (1000 * 60 * 60))
      const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))
      trainTime = `${hours}h ${minutes}m`
    }
  }

  const allowedMetrics = [
    'validation:mAP50-95',
    'validation:precision',
    'validation:recall',
  ]

  let recall = 0
  let precision = 0
  // Filter validation metrics (based on allowed keys)
  const validationMetrics = Object.entries(metrics || {})
    .filter(([key]) => allowedMetrics.includes(key) && key.startsWith('validation:'))
    .map(([key, value]) => {
      const percentageValue = typeof value === 'number' ? (value * 10000) / 100 : 0
      if (key.includes('precision')) {
        precision = typeof value === 'number' ? (value * 10000) / 100 : 0
      }
      if (key.includes('recall')) {
        recall = typeof value === 'number' ? (value * 10000) / 100 : 0
      }
      return { key, value: percentageValue }
    })

  const f1Score = 2 * (precision * recall) / (precision + recall) || 0

  // Combine train and validation metrics for easier rendering
  const combinedMetrics = [/*...trainMetrics,*/ ...validationMetrics, {key: 'calculated:f1', value: f1Score}]

  if (!model) {
    return <div>No model data available.</div>
  }

  return (
    <div className="model-details-content">
      <div className="model-details-metadata mb-4">
        <div className="model-metrics-card"  style={{maxHeight: '65vh', overflowY: 'auto', overflowX: 'hidden'}}>
          {/* Metrics row at the top */}

          <Row className="mb-4 metrics-row">
            <Col xs={12} sm={6} md={3}>
              <Card className="mb-2 metric-card model-metrics-card">
                <Card.Title>Rating</Card.Title>
                {/* Star Rating */}
                <div className="star-rating-container">
                  <div className="star-rating">
                    <StarRating rating={bBoxRating} />
                  </div>
                </div>
                <Card.Body>
                  <Row>
                    {/* Train Time */}
                    <Col>
                      <strong>Train Time:</strong>
                    </Col>
                    <Col>
                      {train_ended_at === '0001-01-01T00:00:00Z' ? 'In Progress' : `${trainTime}`}
                    </Col>
                  </Row>

                  {/* Train Status */}
                  <Row>
                    <Col>
                      <strong>Train Status:</strong>
                    </Col>
                    <Col>
                      {model?.state}
                    </Col>
                  </Row>
                  {/* Deployment Status */}
                  <div className="deployment-status d-flex hidden-xs">
                    <strong>Deployment Status:</strong> {deployment?.status || 'Not Deployed'}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Row>
                {combinedMetrics.map(({ key, value }) => {
                  return (
                    <Col key={key} xs={12} sm={6} md={3} className="d-flex justify-content-md-around align-items-center">
                      <GaugeMetric metricName={key} metricValue={value} />
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>

          {/* Data sources and Class Distribution */}
          <Row className="mb-3">
            <Col xs={6}>
              <TrainingValidationSetsCard />
            </Col>
            <Col xs={6}>
              <ClassDistributionCard model={model} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ModelMetricsCard
