// src/store/slices/authSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { LoginResponse } from '../../types/responses'

export interface AuthState {
  accessToken: string
  refreshToken: string
  isAuthenticated: boolean
}

const initialState: AuthState = {
  accessToken: localStorage.getItem('access_token') || '',
  refreshToken: localStorage.getItem('refresh_token') || '',
  isAuthenticated: !!localStorage.getItem('access_token'),
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<LoginResponse>) => {
      const { access_token: accessToken, refresh_token: refreshToken } = action.payload
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      state.isAuthenticated = true
      localStorage.setItem('access_token', accessToken)
      localStorage.setItem('refresh_token', refreshToken)
    },

    logout: (state) => {
      state.isAuthenticated = false
      state.accessToken = ''
      state.refreshToken = ''
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('user')
    },
  },
})

export const { setCredentials, logout } = authSlice.actions

export default authSlice.reducer

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated
export const refreshTokenSelector = (state: RootState) => state.auth.refreshToken
