// src/services/datasetApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import { DatasetSplit } from '../types/dataset'
import baseQueryWithReauth from './baseQuery'
import { FetchDatasetResp } from '../types/responses'
import { DATASETS_URL } from '../constants'

export const datasetApi = createApi({
  reducerPath: 'datasetApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Dataset'],

  endpoints: (builder) => ({

    fetchDataset: builder.query<FetchDatasetResp, string>({
      query: (datasetId) => `${DATASETS_URL}/${datasetId}`,
      providesTags: (result, error, id) => [{ type: 'Dataset', id }],
    }),

    updateDataset: builder.mutation<FetchDatasetResp, { datasetId: string; datasetData: DatasetSplit }>({
      query: ({ datasetId, datasetData }) => ({
        url: `${DATASETS_URL}/${datasetId}`,
        method: 'PATCH',
        body: { split: datasetData },
      }),
      invalidatesTags: (result, error, { datasetId }) => [{ type: 'Dataset', id: datasetId }],
    }),
  }),
})

export const {
  useFetchDatasetQuery,
  useUpdateDatasetMutation,
} =
  datasetApi
