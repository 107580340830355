// src/App.tsx
import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useAppSelector } from './store/hooks'
import Login from './pages/Login'
import Projects from './pages/Projects'
import './App.scss'
import TermCondition from './pages/TermCondition'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Layout from './pages/Layout'
import Profile from './pages/Profile'
import ProjectLayout from './pages/ProjectLayout'
import ErrorBoundary from './components/ErrorBoundary'
import PredictionReview from './pages/PredictionReview'
import AnnotationsReview from './pages/AnnotationsReview'

const App: React.FC = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)

  return (
    <Router>
      <Layout>
        <Routes>

          <Route
            path="/"
            element={
              <ErrorBoundary>
                <Navigate to={
                  isAuthenticated
                    ? '/projects'
                    : '/login'} />
              </ErrorBoundary>
            }
          />

          <Route
            path="/login"
            element={
              <ErrorBoundary>
                {
                  isAuthenticated
                    ? <Navigate to="/projects" />
                    : <Login />
                }
              </ErrorBoundary>
            }
          />

          <Route
            path="/projects"
            element={
              <ErrorBoundary>
                {
                  isAuthenticated
                    ? <Projects />
                    : <Navigate to="/login" />
                }
              </ErrorBoundary>
            }
          />

          <Route
            path="/ProjectDetails/:projectId"
            element={
              <ErrorBoundary>
                {
                  isAuthenticated
                    ? <ProjectLayout />
                    : <Navigate to="/login" />
                }
              </ErrorBoundary>
            }
          />

          <Route
            path="/predictionsreview"
            element={
              <ErrorBoundary>
                {
                  isAuthenticated
                    ? <PredictionReview />
                    : <Navigate to="/login" />
                }
              </ErrorBoundary>
            }
          />

          <Route
            path="/annotationsreview"
            element={
              <ErrorBoundary>
                {
                  isAuthenticated
                    ? <AnnotationsReview />
                    : <Navigate to="/login" />
                }
              </ErrorBoundary>
            }
          />

          <Route
            path="/profile"
            element={
              <ErrorBoundary>
                {
                  isAuthenticated
                    ? <Profile />
                    : <Navigate to="/login" />
                }
              </ErrorBoundary>
            }
          />

          <Route
            path="/termsConditions"
            element={
              <ErrorBoundary>
                <TermCondition />
              </ErrorBoundary>
            }
          />

          <Route
            path="/privacyPolicy"
            element={
              <ErrorBoundary>
                <PrivacyPolicy />
              </ErrorBoundary>
            }
          />

        </Routes>
      </Layout>
    </Router>
  )
}

export default App
