// SelectDatasetModal.tsx
import React from 'react'
import { Modal } from 'react-bootstrap'
import DatasetSelector from '../DatasetSelector'

interface SelectDatasetModalProps {
  show: boolean
  singleSelect?: boolean
  canCreate: boolean
  onHide: () => void
  handleAcceptSelections: (sources: string[]) => void
}

const SelectDatasetModal: React.FC<SelectDatasetModalProps> = ({
  show = false,
  singleSelect = false,
  canCreate = true,
  onHide,
  handleAcceptSelections
}) => {
  return (
    <Modal size='lg' show={show} onHide={onHide}>
      <Modal.Header className="bg-dark text-white" closeButton closeVariant="white">
        <Modal.Title>Select / Create a Dataset</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">
        <DatasetSelector
          singleSelect={singleSelect}
          canCreate={canCreate}
          handleAcceptSelections={handleAcceptSelections}
        />
      </Modal.Body>
    </Modal>
  )
}

export default SelectDatasetModal