import React, { useState} from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import PillsDataset from './PillsDataset'
import SelectDatasetModal from './modals/SelectDatasetModal'
import {
  selectSelectedTrainingSource,
  setTrainingSource
} from '../store/slices/datasetSlice'
const SelectedTrainingSourceCard: React.FC = () => {
  const dispatch = useAppDispatch()

  const [showModal, setShowModal] = useState(false)

  const selectedTrainingSources = useAppSelector(selectSelectedTrainingSource)

  const handleClose = () => setShowModal(false)

  const handleAcceptSelections = (sources: string[]) => {
    dispatch(setTrainingSource(sources))
    setShowModal(false)
  }

  return (
    <>
      <Card
        className="gallery-tools mb-2"
        style={{ borderRadius: '10px', cursor: 'pointer' }}
        onClick={() => setShowModal(true)}
      >
        <Card.Title>
          Click here to select your training sources
        </Card.Title>
        <Card.Body>
          <Row>
            <Col className='d-flex align-items-center justify-content-center'>

              {selectedTrainingSources && selectedTrainingSources.length > 0 && (
                <PillsDataset
                  datasetNames={
                    selectedTrainingSources && selectedTrainingSources.length > 0 ? selectedTrainingSources : ['All']
                  }
                  disabled={true}
                  tiny={false}
                />
              )}
              {!selectedTrainingSources?.length && (
                <PillsDataset datasetNames={['All']} disabled={true} tiny={false} />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <SelectDatasetModal canCreate={false} handleAcceptSelections={handleAcceptSelections} show={showModal} onHide={handleClose} />
    </>
  )
}

export default SelectedTrainingSourceCard

