import React, { useState} from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { useAppDispatch } from '../store/hooks'
import PillsDataset from './PillsDataset'
import SelectDatasetModal from './modals/SelectDatasetModal'
import {
  setValidationSource
} from '../store/slices/datasetSlice'

const SelectedValidationSourceCard: React.FC = () => {
  const dispatch = useAppDispatch()

  const validationSources = useSelector((state: RootState) => state.dataset.validationSource)


  const [showModal, setShowModal] = useState(false)

  const handleAcceptSelections = (sources: string[]) => {
    dispatch(setValidationSource(sources))
    setShowModal(false)
  }

  const handleClose = () => setShowModal(false)

  return (
    <>
      <Card
        className="gallery-tools mb-2"
        style={{ borderRadius: '10px', cursor: 'pointer' }}
        onClick={() => setShowModal(true)}
      >
        <Card.Title>
          Click here to select your validation sources
        </Card.Title>
        <Card.Body>
          <Row>
            <Col className='d-flex align-items-center justify-content-center'>
              {validationSources && validationSources.length > 0 && (
                <PillsDataset
                  datasetNames={
                    validationSources && validationSources.length > 0 ? validationSources : ['All']
                  }
                  disabled={true}
                  tiny={false}
                />
              )}
              {!validationSources?.length && (
                <PillsDataset datasetNames={['All']} disabled={true} tiny={false} />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <SelectDatasetModal canCreate={false} handleAcceptSelections={handleAcceptSelections} show={showModal} onHide={handleClose} />
    </>
  )
}

export default SelectedValidationSourceCard

